<ng-container *ngIf="!isUnderAnonymityTreshold()">
  <div *ngIf="!isNotFreezed() || !(trend || comparison?.values?.length)" class="z-fx z-fx-around-center stats">
    <div class="z-fx z-fx-around-center z-fx-gap-8">
      <div class="z-fx z-fx-around-center">
        <mat-icon class="icon">contact</mat-icon>
        <span numberTransition class="number" [data]="responses" [duration]="transitionDuration"></span>
      </div>
    </div>
  </div>

  <ng-container *ngIf="distributions?.[emotionsIndex]?.length && responses > 0">
    <div class="charts" [style.height.px]="0.9 * (chartCount / (chartCount + 1)) * availableHeight - 16">
      <ng-container *ngIf="!trend || !isNotFreezed(); else trendVersion">
        <ng-container *ngIf="!comparison || !isNotFreezed(); else comparisonVersion">
          <ng-container *ngIf="stats[emotionsIndex]?.count > 0; else nothing">
            <div
              class="bubbleChart"
              [style.height.px]="!exportChart ? 0.9 * (1 / (chartCount + 1)) * availableHeight - 16 : 562"
              bubbleChart
              [data]="distributions[emotionsIndex]"
              [scale]="chartSettings.scale"
              [filterInput]="filterInput ? filterInput[emotionsIndex] : null"
              [domain]="domain[emotionsIndex]"
              [transitionDuration]="transitionDuration"
              [update]="update"
              [filtering]="filtering && isNotFreezed()"
              [anonymityLock]="anonymityLock"
              [title]="''"
              [totalAnswers]="totalAnswers[emotionsIndex]"
              [crossfilter]="crossfilter"
              [touchDevice]="touchDevice"
              [isSharedReport]="isSharedReport"
              [hideRespondentCounts]="!exportChart"
            ></div>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-template #comparisonVersion>
        <ng-container *ngFor="let compItem of comparisonTable$ | async; trackBy: trackByKey">
          <div
            class="bubbleChart"
            [style.height.px]="!exportChart ? 0.9 * (1 / (chartCount + 1)) * availableHeight - 16 / chartCount : 562"
            bubbleChart
            [data]="compItem.distributions"
            [scale]="chartSettings.scale"
            [filterInput]="filterInput ? filterInput[emotionsIndex] : null"
            [domain]="domain[emotionsIndex]"
            [transitionDuration]="transitionDuration"
            [update]="update"
            [filtering]="filtering && isNotFreezed()"
            [anonymityLock]="anonymityLock"
            [title]="compItem.label"
            [totalAnswers]="compItem.responses"
            [crossfilter]="crossfilter"
            [touchDevice]="touchDevice"
            [isSharedReport]="isSharedReport"
            [color]="compItem.color"
          ></div>
        </ng-container>
      </ng-template>
      <ng-template #trendVersion>
        <div
          class="trendCategoricalChart"
          [style.height.px]="
            !exportChart ? 0.9 * (chartCount / (chartCount + 1)) * availableHeight - 16 : chartCount * 562
          "
          trendCategoricalChart
          [data]="distributions"
          [stats]="stats"
          [scale]="chartSettings.scale"
          [comparison]="comparison"
          [filterInput]="filterInput"
          [domain]="domain"
          [details]="details"
          [zvalues]="zChecked"
          [transitionDuration]="transition"
          [showNumbers]="chartSettings.showNumbers"
          [update]="Math.max(update?.valueOf(), sizeUpdate?.valueOf())"
          [filtering]="filtering && isNotFreezed()"
          [anonymityLock]="anonymityLock"
          [totalAnswers]="totalAnswers"
          [touchDevice]="touchDevice"
          [trendHoverInput]="trendHoverInput"
          [selectionExists]="selectionExists"
          [filtersDemo]="filtersDemo"
          (hover)="hover.emit($event)"
        ></div>
      </ng-template>
    </div>
  </ng-container>
</ng-container>

<div class="table-area" [class.no-data]="isUnderAnonymityTreshold()">
  <div *ngIf="!isUnderAnonymityTreshold()" class="search-box">
    <zef-searchbox (input)="searchUpdate$.next($event)">
      <span i18n>Find...</span>
    </zef-searchbox>
    <ng-container *ngIf="sortKey$ | async as sortkey">
      <ng-container *ngIf="sortDirection$ | async as sortdir">
        <div class="z-fx z-fx-between-center sort-tools">
          <div class="z-fx z-fx-center-center sorter">
            <zef-select [value]="sortkey" type="inline-p2" (change)="onSortColumn($event)">
              <zef-select-option value="survey">
                <div *selectOptionLabel class="z-fx z-fx-gap-8 z-fx-start-center">
                  <mat-icon>time</mat-icon>
                  <strong class="zef-text-p2" i18n>Date</strong>
                </div>
              </zef-select-option>
              <zef-select-option [value]="details[textIndex]?.key">
                <div *selectOptionLabel class="z-fx z-fx-gap-8 z-fx-start-center">
                  <mat-icon>az</mat-icon>
                  <strong class="zef-text-p2" i18n>Alphabetical</strong>
                </div>
              </zef-select-option>
              <ng-container *ngIf="comparison?.values?.length">
                <mat-divider [style.margin]="'8px 0'"></mat-divider>
                <zef-select-option [value]="comparison.key">
                  <div *selectOptionLabel class="z-fx z-fx-gap-8 z-fx-start-center">
                    <mat-icon>az</mat-icon>
                    <strong class="zef-text-p2" i18n>Comparison</strong>
                  </div>
                </zef-select-option>
              </ng-container>
            </zef-select>

            <button mat-icon-button [@rotate]="sortdir !== 'asc'" (click)="onSortDir()">
              <mat-icon class="zef-color-primary">sort_up</mat-icon>
            </button>
          </div>
          <zef-select [value]="langFilter || ''" (change)="filterByLanguage($event)">
            <zef-select-option value="">
              <div *selectOptionLabel class="z-fx z-fx-gap-4 z-fx-start-center">
                <mat-icon>world</mat-icon>
                <strong class="zef-text-p2" i18n>All</strong>
              </div>
            </zef-select-option>

            <ng-container *ngFor="let lang of languages">
              <zef-select-option [value]="lang">
                <div *selectOptionLabel class="z-fx z-fx-gap-4 z-fx-start-center">
                  <figure class="zef-flag-small zef-lang-{{ lang }}"></figure>
                  <strong class="zef-text-p2">
                    {{ details?.[textIndex]?.['labelsValueGroup']?.[lang] }}
                  </strong>
                </div>
              </zef-select-option>
            </ng-container>
          </zef-select>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <ng-container *ngIf="chartTable$ | async as chartTable">
    <ng-container
      *ngIf="!this.exportChart && availableHeight > 0 && !isUnderAnonymityTreshold() && chartTable?.length > 0"
    >
      <ng-scrollbar
        class="table"
        [style.height.px]="0.1 * availableHeight + 0.9 * (1 / (chartCount + 1)) * availableHeight - 84"
      >
        <cdk-virtual-scroll-viewport
          *ngIf="!vsHide && (chartTable$ | async).length > 0"
          [style.height.px]="0.1 * availableHeight + 0.9 * (1 / (chartCount + 1)) * availableHeight - 84"
          scrollViewport
          autosize
        >
          <div
            *cdkVirtualFor="let item of chartTable$ | async; trackBy: trackByRow"
            class="z-fx z-fx-sc text-row"
            [style.cursor]="isNotFreezed() ? 'pointer' : 'default'"
            (click)="filterData(item[textIndex + 1].value, textIndex)"
          >
            <ng-container *ngIf="item[textIndex + 1] as textItem">
              <div
                *ngIf="isNotFreezed() && timeIndex >= 0"
                class="time"
                (click)="$event.stopPropagation(); filterData(item[timeIndex + 1].value, timeIndex)"
              >
                <span class="zef-color-ink">
                  {{ item[timeIndex + 1].text }}
                </span>
              </div>
              <div class="text">
                <span>{{ textItem.text }}</span>
                <div class="chips z-fx z-fx-start-center z-fx-wrap">
                  <ng-container
                    *ngFor="let emotion of parseItems(item[emotionsIndex + 1], emotionsIndex); let i = index"
                  >
                    <span
                      class="zef-chip-small zef-bgcolor-ink chip"
                      (click)="$event.stopPropagation(); filterData(item[emotionsIndex + 1].value, emotionsIndex, i)"
                    >
                      {{ emotion }}
                    </span>
                  </ng-container>
                  <ng-container *ngIf="isNotFreezed() && comparison && comparison.index != null">
                    <ng-container
                      *ngFor="
                        let comparisonItem of parseItems(item[comparison.index + 1], comparison.index);
                        let i = index
                      "
                    >
                      <mat-divider
                        *ngIf="item[emotionsIndex + 1]?.value?.[0]?.length && comparisonItem"
                        [vertical]="true"
                        class="zef-divider-16"
                      ></mat-divider>
                      <span
                        class="chip"
                        [style.color]="getColor(item[comparison.index + 1], i)"
                        (click)="
                          $event.stopPropagation(); filterData(item[comparison.index + 1].value, comparison.index)
                        "
                      >
                        {{ comparisonItem }}
                      </span>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </cdk-virtual-scroll-viewport>
      </ng-scrollbar>
    </ng-container>
    <ng-container *ngIf="!anonymityTreshold && chartTable.length === 0">
      <ng-container *ngTemplateOutlet="nothing"></ng-container>
    </ng-container>
    <ng-container *ngIf="isUnderAnonymityTreshold()">
      <ng-container *ngTemplateOutlet="anonymous"></ng-container>
    </ng-container>
  </ng-container>
</div>
<ng-container *ngIf="!anonymityLock; else anonymityLockTemplate"></ng-container>

<ng-template #nothing>
  <div class="z-fx-col nothing">
    <div class="z-fx-col z-fx-auto z-fx-start-center">
      <div class="z-fx-col z-fx-end-center image">
        <img src="assets/decos/art-nothing.png" height="48px" width="48px" class="art-nothing" alt="nothing" />
      </div>
      <div class="description" i18n>Looks like there is no data to show.</div>
    </div>
  </div>
</ng-template>
<ng-template #anonymous>
  <div class="z-fx-col z-fx-auto anonymous">
    <div class="z-fx z-fx-center-center answerers">
      <mat-icon class="respondent-icon">contact</mat-icon>
      <span class="number" i18n>under {{ anonymityTreshold }}</span>
    </div>
    <div class="z-fx-col z-fx-auto z-fx-start-center z-fx-gap-16">
      <div class="z-fx-col z-fx-end-center image">
        <mat-icon class="zef-icon-larger">anonymous_on</mat-icon>
      </div>
      <div class="description zef-text-center">
        <ng-container i18n>
          Charts are hidden if there are less than {{ anonymityTreshold }} respondents in Anonymous survey.
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #anonymityLockTemplate>
  <div
    class="anonymity-lock"
    matTooltip="Chart is locked to protect anonymity of the respondent"
    matTooltipClass="zef-tooltip-ink-lighter"
    i18n-matTooltip
    matTooltipPosition="above"
  >
    <mat-icon class="zef-icon-small lock-icon">lock</mat-icon>
  </div>
</ng-template>
