import * as d3 from 'd3';

import { combineLatest, BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  OnChanges,
  Output,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  QueryList,
} from '@angular/core';

import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';

import { DataConverter } from '@report/shared/services/data-converter.service';
import { Crossfilter } from '@report/shared/services/crossfilter.service';

import { ChartDomain, ChartDistribution, ChartSettings } from '@shared/models/report.model';

import { Colors } from '@report/shared/enums/colors.enum';
import { Charts } from '@shared/enums/charts.enum';
import { rotateAnimation } from '@shared/animations/rotate.anim';

/**
 * This is a Bar Chart Table.
 */
@Component({
  selector: 'bar-chart-table',
  templateUrl: './bar-chart-table.component.html',
  styleUrls: ['./bar-chart-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [rotateAnimation],
})
export class BarChartTable implements OnInit, OnChanges {
  @Input() data: ChartDistribution[][] = [];
  @Input() dataIndex: number = null;
  @Input() dataYIndex: number = 1;
  @Input() domain: ChartDomain = {} as ChartDomain;
  @Input() stats: any;
  @Input() scale: any;
  @Input() filterInput: any;
  @Input() transitionDuration: number = 0;
  @Input() showNumbers: boolean = false;
  @Input() update: Date = new Date();
  @Input() filtering: boolean = false;
  @Input() anonymityLock: boolean = false;
  @Input() anonymityTreshold: number = null;
  @Input() extraRespondentFieldAnonymity: boolean = false;
  @Input() hideToolbar: boolean = false;
  @Input() chartType: string = '';
  @Input() title: string = '';
  @Input() totalAnswers: number = 0;
  @Input() selectionExists: boolean = false;
  @Input() filtersDemo: boolean = false;
  @Input() touchDevice: boolean = false;
  @Input() filterRows: (string | number)[] | null = null;
  @Input() crossfilter: Crossfilter | null = null;

  @Input() chartSettings: ChartSettings = {} as ChartSettings;
  @Output() settingsChange: EventEmitter<any> = new EventEmitter<any>();

  readonly colors = Colors;
  readonly chartTypes = Charts;

  // BehaviorSubjects
  public searchUpdate$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public sortKey$: BehaviorSubject<string> = new BehaviorSubject<string>('survey');
  public sortDirection$: BehaviorSubject<string> = new BehaviorSubject<string>('asc');
  public chartData$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public chartTable$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  // update debouncer
  public updateCharts$: Subject<boolean> = new Subject<boolean>();

  // Variables used in HTML template
  public hoveredElement: number | null = null;
  public sizeUpdate: Date = new Date();
  public max: number = 0;
  public previousMax: number = 0;
  public percentageValues: boolean = false;
  public previousPercentageValues: boolean = false;
  public responses: number = 0;
  public legends: any[] = [];
  public columnMinHeight: string = '';
  public hoveredBar: number = null;
  public hoveredLegend: number = null;
  public hoveredContent: string = '';
  public tooltipPosition: number[] = [];
  public chartsAreaHeight: number = null;
  public chartBarHeight: number = null;
  public expandedTitles: boolean = false;
  public vsEnableAmount: number = 50;
  public vsHide: boolean = false;
  public searchTerm: string = '';

  // Settings
  public displayedColumns: string[] = ['title', 'bar'];
  public showImages: boolean = false;
  public showSearchBar: boolean = false;
  public wideModeOn: boolean = false;
  public exportChart: boolean = false;

  private filter: any;
  private selections: any = [];
  private selectionsY: any = [];
  private vsSizeUpdateNeeded: boolean = false;

  private dataService: Crossfilter | null = this.cf;

  public trackBy = (i: number, item: any): string => item.key;

  @ViewChildren('titleContainer') titleContainers!: QueryList<ElementRef>;
  @ViewChildren('titleSpan') titleSpans!: QueryList<ElementRef>;
  @ViewChild('tooltip') tooltipEl!: ElementRef;

  get Math() {
    return Math;
  }

  constructor(
    private _element: ElementRef,
    private cf: Crossfilter,
    private cdRef: ChangeDetectorRef,
    readonly dc: DataConverter,
    readonly hooks: LifecycleHooks,
  ) {}

  ngOnInit(): void {
    combineLatest(this.chartData$, this.searchUpdate$, this.sortKey$, this.sortDirection$)
      .pipe(takeUntil(this.hooks.destroy))
      .subscribe(([chartData, searchTerm, sortKey, sortDirection]) => {
        this.searchTerm = searchTerm;
        const rawArray = chartData.slice();
        const barArray =
          this.filterRows == null ? rawArray : rawArray.filter((item) => this.filterRows.indexOf(item.key) >= 0);
        const anonymityFilteredList: any[] = !(this.extraRespondentFieldAnonymity && this.anonymityTreshold)
          ? barArray
          : barArray.filter(
              (item) =>
                item.value >= this.anonymityTreshold &&
                (this.chartType === Charts.BARCHARTH ||
                  (item.childValues || []).length === 0 ||
                  item.childValues?.every((c) => c >= this.anonymityTreshold)),
            );
        this.showSearchBar = anonymityFilteredList.length > 10;
        let filteredList: any[];

        if (!searchTerm) {
          filteredList = anonymityFilteredList;
        } else {
          const filteredResults = anonymityFilteredList.filter(
            (item) =>
              item && item.title != null && item.title.toString().toLowerCase().includes(searchTerm.toLowerCase()),
          );
          filteredList = filteredResults;
        }

        const numberCheck: (val: string | number) => number = (val: string | number) =>
          !isNaN(Number(val)) ? Number(val) : -200;

        const sortedList = filteredList.sort((a, b) => {
          if (sortKey === 'value' && this.chartType === Charts.BARCHARTH) {
            if (numberCheck(a[sortKey]) > numberCheck(b[sortKey])) {
              return sortDirection === 'asc' ? 1 : -1;
            }
            if (numberCheck(a[sortKey]) < numberCheck(b[sortKey])) {
              return sortDirection === 'asc' ? -1 : 1;
            }
          } else if (sortKey === 'value') {
            if (
              numberCheck(a['childValues'].reduce((c, d) => c + d, 0)) >
              numberCheck(b['childValues'].reduce((c, d) => c + d, 0))
            ) {
              return sortDirection === 'asc' ? 1 : -1;
            }
            if (
              numberCheck(a['childValues'].reduce((c, d) => c + d, 0)) <
              numberCheck(b['childValues'].reduce((c, d) => c + d, 0))
            ) {
              return sortDirection === 'asc' ? -1 : 1;
            }
          } else if (sortKey === 'survey') {
            if (numberCheck(a['index']) > numberCheck(b['index'])) {
              return sortDirection === 'asc' ? 1 : -1;
            }
            if (numberCheck(a['index']) < numberCheck(b['index'])) {
              return sortDirection === 'asc' ? -1 : 1;
            }
          } else {
            if (a[sortKey] > b[sortKey]) {
              return sortDirection === 'asc' ? 1 : -1;
            }
            if (a[sortKey] < b[sortKey]) {
              return sortDirection === 'asc' ? -1 : 1;
            }
          }

          return 0;
        });

        const barCountChange: boolean = this.chartTable$.value.length - sortedList.length !== 0;

        this.chartTable$.next(sortedList);

        if (this.vsSizeUpdateNeeded && chartData.length > this.vsEnableAmount) {
          this.calculateBarHeight();
          this.forceVSUpdate();
          this.vsSizeUpdateNeeded = false;
        } else if (barCountChange) {
          this.calculateBarHeight();
          this.cdRef.detectChanges();
        } else {
          this.cdRef.detectChanges();
        }
      });

    const base = d3.select(this._element.nativeElement);

    base
      .on('mousemove', (event) => {
        const pos = d3.pointer(event);

        if (this.hoveredElement != null || this.hoveredLegend != null) {
          const w = (this.tooltipEl && this.tooltipEl.nativeElement && this.tooltipEl.nativeElement.offsetWidth) || 0;
          const h = (this.tooltipEl && this.tooltipEl.nativeElement && this.tooltipEl.nativeElement.offsetHeight) || 0;

          this.tooltipPosition[0] = pos[0] - w / 2;
          this.tooltipPosition[1] = pos[1] - h - 16;
          this.cdRef.detectChanges();
        }
      })
      .on('mouseout', () => {
        this.hoveredElement = null;
        this.hoveredBar = null;
        this.cdRef.detectChanges();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.exportChart = this.hasParentClass(this._element.nativeElement, 'export-chart');

    if (changes.chartSettings && changes.chartSettings.firstChange) {
      this.sortKey$.next(this.chartSettings.sortKey || 'survey');
      this.sortDirection$.next(this.chartSettings.sortDirection || 'asc');
    }

    if (
      changes.data ||
      changes.domain ||
      changes.scale ||
      changes.filterInput ||
      changes.showNumbers ||
      changes.update ||
      changes.filtering ||
      changes.anonymityLock ||
      changes.extraRespondentFieldAnonymity ||
      changes.anonymityTreshold ||
      changes.title ||
      changes.stats ||
      changes.filtersDemo ||
      changes.filterRows ||
      changes.crossfilter
    ) {
      this.setData();
    }
  }

  private setData(): void {
    if (this.crossfilter) {
      this.dataService = this.crossfilter;
    } else {
      this.dataService = this.cf;
    }

    const overScale: number = !(this.scale === 'percentage' && this.chartType === Charts.STACKEDBARCHARTH) ? 1.2 : 1;
    const maxs: number[] = [];
    const sums: number[] = [];
    const index: number = this.dataIndex || 0;

    this.previousPercentageValues = this.percentageValues;
    this.percentageValues = this.scale === 'percentage';
    this.selections = new Set(this.filterInput && this.filterInput[index] ? this.filterInput[index] : null);
    this.selectionsY =
      this.chartType !== Charts.BARCHARTH
        ? new Set(this.filterInput && this.filterInput[this.dataYIndex] ? this.filterInput[this.dataYIndex] : null)
        : [];
    this.responses =
      this.stats && this.stats[index] && this.stats[index]['responses'] != null
        ? this.stats[index]['responses']
        : this.totalAnswers;
    this.showImages = false;
    this.legends =
      this.chartType !== Charts.BARCHARTH &&
      this.domain &&
      this.domain.keysY &&
      this.domain.keysY.map((key, keyIndex) => {
        return {
          key,
          label: this.domain.labelsY[key],
          color: Colors.getComparisonColor(this.domain.colorsY[key] != null ? this.domain.colorsY[key] : keyIndex),
          responses:
            this.data[this.dataYIndex] &&
            this.data[this.dataYIndex][keyIndex] &&
            this.data[this.dataYIndex][keyIndex]['value'],
        };
      });

    const chartData = [];
    const labels: any = this.isWordsDimension()
      ? this.dataService.getTextLabels()[this.domain.key] || []
      : this.domain.labels;
    const groupSeparator: string = '\u001D';

    for (let i = 0, len = this.data[index].length; i < len; i++) {
      const d = this.data[index][i];
      const key = !this.isWordsDimension() ? d['key'] : Number(d['key']);
      const title = (labels[key] || '').split(groupSeparator)[0];
      const existingIndex: number = !this.isWordsDimension()
        ? -1
        : chartData.findIndex((dataItem) => dataItem?.title === title);

      if (this.isWordsDimension() && !(this.filterRows && this.filterRows.length) && existingIndex >= 0) {
        chartData[existingIndex]['value'] += d[this.scale === 'percentage' ? 'percentage' : 'value'];
        chartData[existingIndex]['textValue'] = `${
          this.data[index][chartData[existingIndex]['index']]['value'] + d['value']
        } (${((this.data[index][chartData[existingIndex]['index']]['percentage'] + d['percentage']) * 100).toFixed(
          1,
        )}%)`;

        if (!chartData[existingIndex]['otherKeysIncluded']) {
          chartData[existingIndex]['otherKeysIncluded'] = [];
        }

        chartData[existingIndex]['otherKeysIncluded'].push(key);
      } else {
        const item = {};
        const prevItem =
          this.chartData$.value[i] && this.chartData$.value[i]['key'] === key
            ? this.chartData$.value[i]
            : this.chartData$.value.find((pv) => pv.key === key);
        let color = this.selectionExists ? Colors.SELECTED : this.filtersDemo ? Colors.UNSELECTED : Colors.DEFAULT;
        let parentSelected = false;

        if (this.filterInput && this.filterInput[index] && this.filterInput[index].length > 0) {
          const xFi = this.filterInput[index].indexOf(key) > -1;

          if (!xFi) {
            color = Colors.UNSELECTED;
          } else {
            color = Colors.FILTER;
            parentSelected = true;
          }
        }

        item['index'] = i;
        item['key'] = key;
        item['title'] = title;
        item['value'] = d[this.scale === 'percentage' ? 'percentage' : 'value'];
        item['previousValue'] = prevItem && prevItem['value'];
        item['childValues'] =
          (d &&
            d['children'] &&
            d['children'].map((child) =>
              this.scale === 'percentage'
                ? this.chartType === Charts.STACKEDBARCHARTH
                  ? child.percentage
                  : child.percentage_all
                : child.value,
            )) ||
          [];
        item['previousChildValues'] = prevItem && prevItem['childValues'];
        item['textValue'] = `${d['value']} (${(
          (this.chartType === Charts.STACKEDBARCHARTH ? d['percentage'] : d['percentage_all']) * 100
        ).toFixed(1)}%)`;
        item['colorScale'] = [color, color];
        item['colors'] =
          (d &&
            d['children'] &&
            d['children'].map(
              (child, idx) =>
                child.key &&
                Colors.getComparisonColor(
                  this.domain.colorsY && this.domain.colorsY[child.key] != null ? this.domain.colorsY[child.key] : idx,
                ),
            )) ||
          [];
        item['childSelections'] =
          (this.chartType !== Charts.BARCHARTH &&
            (this.selections.size > 0 || this.selectionsY.size > 0) &&
            d &&
            d['children'] &&
            d['children'].map((child) => parentSelected || (child.key && this.selectionsY.has(child.key)))) ||
          false;
        item['imageUrl'] = this.domain['images'] && this.domain['images'][key] && this.domain['images'][key]['thumb'];
        item['imageMode'] = this.domain['images'] && this.domain['images'][key] && this.domain['images'][key]['mode'];
        chartData.push(item);

        if (item['imageUrl']) {
          this.showImages = true;
        }

        if (
          !this.vsSizeUpdateNeeded &&
          this.chartType === Charts.MULTIBARCHARTH &&
          len > this.vsEnableAmount &&
          item['previousChildValues'] &&
          item['childValues'] &&
          item['previousChildValues'].length !== item['childValues'].length
        ) {
          this.vsSizeUpdateNeeded = true;
        }
      }
    }

    for (let i = 0, len = chartData.length; i < len; i++) {
      if (this.filterRows == null || this.filterRows.indexOf(chartData[i].key) >= 0) {
        let max = 0;

        if (this.chartType === Charts.BARCHARTH) {
          max = max > chartData[i]['value'] ? max : chartData[i]['value'];
        } else if (this.chartType === Charts.STACKEDBARCHARTH) {
          let sum = 0;
          chartData[i]['childValues'].forEach((c) => {
            sum += c;
          });
          sums.push(sum);
        } else {
          for (const cVal of chartData[i]['childValues']) {
            max = max > cVal ? max : cVal;
          }
        }

        maxs.push(max);
      }
    }

    this.previousMax = this.max;
    this.max = Math.max(...(this.chartType === Charts.STACKEDBARCHARTH ? sums : maxs).map((d) => d)) * overScale || 1;
    this.displayedColumns = this.showImages ? ['title', 'image', 'bar'] : ['title', 'bar'];
    this.chartData$.next(chartData);
  }

  private updateTooltip(title, textValue): void {
    this.hoveredContent = `<div class="question">${title}</div>
    <div class="stats"><span class="icon">contact</span> ${textValue}</div>`;
  }

  public getColumnWidth(column: string): string {
    if (column === 'title') {
      return '25%';
    } else if (column === 'bar') {
      return !this.showImages ? '75%' : '60%';
    } else if (column === 'image') {
      return '15%';
    } else {
      return '';
    }
  }

  public onSortColumn(column: string): void {
    if (column === 'value') {
      this.sortDirection$.next('desc');
    } else {
      this.sortDirection$.next('asc');
    }

    this.sortKey$.next(column);
    this.settingsChanged();
  }

  public onSortDir(): void {
    this.sortDirection$.next(this.sortDirection$.value === 'asc' ? 'desc' : 'asc');
    this.settingsChanged();
  }

  public onFilter(item: any): void {
    if (this.selections.has(item.key)) {
      this.selections.delete(item.key);
      if (item.otherKeysIncluded && item.otherKeysIncluded.length) {
        this.selections.delete(...item.otherKeysIncluded);
      }
    } else {
      this.selections.add(item.key);
      if (item.otherKeysIncluded && item.otherKeysIncluded.length) {
        this.selections.add(...item.otherKeysIncluded);
      }
    }

    if (this.hoveredBar != null) {
      const childKey = this.domain.keysY && this.domain.keysY[this.hoveredBar];

      if (this.selectionsY.has(childKey)) {
        this.selectionsY.delete(childKey);
      } else {
        this.selectionsY.add(childKey);
      }
    }

    this.callFilter();
  }

  public onFilterLegend(index: number): void {
    if (index != null) {
      const childKey = this.domain.keysY && this.domain.keysY[index];

      if (this.selectionsY.has(childKey)) {
        this.selectionsY.delete(childKey);
      } else {
        this.selectionsY.add(childKey);
      }
    }

    this.callFilter();
  }

  public settingsChanged(): void {
    this.settingsChange.emit({
      sortKey: this.sortKey$.value,
      sortDirection: this.sortDirection$.value,
    });
  }

  public onHover(element: any | null): void {
    if (element !== this.hoveredElement) {
      this.hoveredElement = element && element.index;
      this.updateTooltip(element && element.title, element && element.textValue);
      this.cdRef.detectChanges();
    }
  }

  public showMoreButton(container, span) {
    const containerHeight = container && container.offsetHeight;
    const spanHeight = span && span.scrollHeight;

    return spanHeight - containerHeight > 0;
  }

  public showLessButton(container, span) {
    const spanSizes = Array.from(this.titleSpans || []).map((elem) => elem && elem.nativeElement.scrollHeight);
    const spanHeight = span && span.scrollHeight;
    const containerHeight = container && container.offsetHeight;

    return spanHeight === Math.max(...spanSizes) && containerHeight - spanHeight < 5;
  }

  public expandLabels(): void {
    const spanSizes = Array.from(this.titleSpans || []).map((elem) => elem && elem.nativeElement.scrollHeight);
    const containerSizes = Array.from(this.titleContainers || []).map(
      (elem) => elem && elem.nativeElement.offsetHeight,
    );
    const maxS = Math.max(...spanSizes);
    const maxC = Math.max(...containerSizes);

    this.chartBarHeight = Math.max(
      maxS - maxC > 0 ? maxS : null,
      this.chartsAreaHeight / (this.chartData$.value.length || 1),
      48,
    );
    this.expandedTitles = true;
    this.sizeUpdate = new Date();
    this.cdRef.detectChanges();
  }

  public shrinkLabels(): void {
    this.chartBarHeight = Math.max(this.chartsAreaHeight / (this.chartData$.value.length || 1), 48);
    setTimeout(() => {
      this.expandedTitles = false;
      this.sizeUpdate = new Date();
      this.cdRef.detectChanges();
    }, 0);
  }

  public showFooter(): boolean {
    return this.chartTable$?.value?.length > 0 && (this.chartType !== Charts.STACKEDBARCHARTH || this.percentageValues);
  }

  public contentAreaResize($event) {
    if ($event?.dimensions?.height) {
      this.chartsAreaHeight = $event.dimensions.height - (this.showFooter() ? 40 : 0);
      this.calculateBarHeight();

      if (this.chartData$.value.length > this.vsEnableAmount) {
        this.forceVSUpdate();
      } else {
        this.sizeUpdate = new Date();
        this.cdRef.detectChanges();
      }
    }
  }

  private calculateBarHeight() {
    const availableExportHeight: number = 360 - (this.legends?.length ? Math.ceil(this.legends?.length / 2) * 20 : 0);

    this.chartBarHeight = !this.exportChart
      ? Math.floor(
          Math.max(
            this.chartsAreaHeight / (this.chartData$.value.length || 1),
            this.chartType === Charts.MULTIBARCHARTH ? this.legends?.length * 32 : 0,
            48,
          ),
        )
      : Math.min(
          Math.max(
            this.chartType === Charts.MULTIBARCHARTH ? Math.max(this.legends?.length, 4) * 18 : 28,
            availableExportHeight / (this.chartData$.value.length || 1),
          ),
          this.chartType === Charts.MULTIBARCHARTH ? Infinity : 80,
        );
  }

  private forceVSUpdate(): void {
    this.vsHide = true;
    this.cdRef.detectChanges();
    setTimeout(() => {
      this.vsHide = false;
      this.cdRef.detectChanges();
    }, 0);
  }

  public onLegendHover($event) {
    this.hoveredLegend = $event;
    this.hoveredBar = $event;
    const title = $event != null && this.legends[$event] && this.legends[$event]['label'];
    const textValue =
      $event != null &&
      this.legends[$event] &&
      `${
        this.data[this.dataYIndex] && this.data[this.dataYIndex][$event] && this.data[this.dataYIndex][$event]['value']
      } (${(
        this.data[this.dataYIndex] &&
        this.data[this.dataYIndex][$event] &&
        this.data[this.dataYIndex][$event]['percentage'] * 100
      ).toFixed(1)}%)`;

    this.updateTooltip(title, textValue);
    this.cdRef.detectChanges();
  }

  public newHoveredBar($event) {
    const groupSeparator: string = '\u001D';
    this.hoveredBar = $event;
    const labels: any = this.isWordsDimension()
      ? this.dataService.getTextLabels()[this.domain.key] || []
      : this.domain.labels;
    const title = `${
      this.hoveredElement != null &&
      this.data[this.dataIndex || 0][this.hoveredElement].key &&
      labels[this.data[this.dataIndex || 0][this.hoveredElement].key] &&
      labels[this.data[this.dataIndex || 0][this.hoveredElement].key].split(groupSeparator)[0]
    }: ${
      (this.data[0][this.hoveredElement] &&
        this.data[0][this.hoveredElement]['children'] &&
        this.data[0][this.hoveredElement]['children'] &&
        this.data[0][this.hoveredElement]['children'][this.hoveredBar] &&
        this.data[0][this.hoveredElement]['children'][this.hoveredBar]['key'] &&
        this.domain.labelsY[this.data[0][this.hoveredElement]['children'][this.hoveredBar]['key']]) ||
      ''
    }`;
    const textValue =
      $event == null
        ? `${this.data[this.dataIndex || 0][this.hoveredElement].value} (${(
            this.data[this.dataIndex || 0][this.hoveredElement].percentage * 100
          ).toFixed(1)})`
        : `${
            this.data[0][this.hoveredElement] &&
            this.data[0][this.hoveredElement]['children'] &&
            this.data[0][this.hoveredElement]['children'] &&
            this.data[0][this.hoveredElement]['children'][this.hoveredBar] &&
            this.data[0][this.hoveredElement]['children'][this.hoveredBar]['value']
          } (${
            this.data[0][this.hoveredElement] &&
            this.data[0][this.hoveredElement]['children'] &&
            this.data[0][this.hoveredElement]['children'] &&
            this.data[0][this.hoveredElement]['children'][this.hoveredBar] &&
            (
              this.data[0][this.hoveredElement]['children'][this.hoveredBar][
                this.chartType === Charts.STACKEDBARCHARTH ? 'percentage' : 'percentage_all'
              ] * 100
            ).toFixed(1)
          }%)`;

    this.updateTooltip(title, textValue);
  }

  callFilter() {
    if (this.filtering && !this.anonymityLock) {
      this.filter = [];
      const filterX = { key: this.domain.key, values: this.domain.keys, filter: Array.from(this.selections) };

      this.filter.push(filterX);

      if (this.chartType !== Charts.BARCHARTH) {
        const filterY = { key: this.domain.keyY, values: this.domain.keysY, filter: Array.from(this.selectionsY) };

        this.filter.push(filterY);
      }

      const filterInput = JSON.stringify(this.filterInput.slice(0, 2).map((item) => (item == null ? [] : item)));
      const filter = JSON.stringify(this.filter.map((item) => item.filter));

      if (filter !== filterInput) {
        this.dataService.filter(this.filter);
      }
      // if (JSON.stringify(this.filter[0].filter) !== JSON.stringify(this.filterInput)) {
      //   this.cf.filter(this.filter);
      // }
    }
  }

  hasParentClass(element, classname): boolean {
    if (element.className && element.className.split(' ').indexOf(classname) >= 0) {
      return true;
    }

    return !!element.parentNode && this.hasParentClass(element.parentNode, classname);
  }

  isWordsDimension(): boolean {
    return this.domain?.key?.indexOf(':words') >= 0 || this.domain?.key?.indexOf(':rootCauseWords') >= 0;
  }
}
