/*
export class for pptx, docx, xlsx and pdf
*/
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Store } from '@ngxs/store';
import { Charts } from '@shared/enums/charts.enum';
import { Questions } from '@shared/enums/questions.enum';
import { DimensionData, DimensionDataItem, ExportItem, TextData } from '@shared/models/report.model';
import { DatabaseWrapper } from '@shared/services/database-wrapper.service';
import { AccountState } from '@shared/states/account.state';
import { AuthState } from '@shared/states/auth.state';
import { LocalStorage } from 'ngx-webstorage';
import { Observable, of } from 'rxjs';
import { catchError, first, mergeMap, timeout } from 'rxjs/operators';
import { ChartsManager } from './charts-manager.service';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  private data: any;
  private choiceLabels: { [questionKey: string]: { [choiceKey: string]: string } } = {};
  private outcomeLabels: { [outcomeKey: string]: string } = {};
  private contactFields: string[] = [];

  private title: string = '';
  private type: string = '';
  private survey: string = '';
  private outcomeMatching: string = '';
  private respondentCount: number;

  @LocalStorage('consent') trackingAllowed: boolean | undefined;

  constructor(
    private http: HttpClient,
    private store: Store,
    private db: DatabaseWrapper,
  ) {}

  create(
    cm: ChartsManager,
    title,
    type: string,
    survey: string,
    sampleData: boolean,
    respondentCount: number = 0,
    comments: boolean = false,
    datatables: boolean = false,
    reportKey?: string,
    extraRespondentFieldAnonymity?: boolean,
    extraHastagShareLinkAnonymity?: boolean,
  ) {
    const data: any = [];
    if (cm && cm.gridSubj) {
      cm.gridSubj.pipe(first()).subscribe((grid) => {
        for (const item of grid) {
          if (!item.isNotesCard && !item.isSubheader && !item.isInsightsCard && !item.hided) {
            const keys: string[] = [];
            const chartType: string =
              item.chartSettings.type !== Charts.SMARTTEXTCHART
                ? item.chartSettings.type
                : item.chartSettings.smartTextChartMode || Charts.TEXTANSWERTABLE;
            item.data.details.forEach((detail) => {
              keys.push(detail.key);
            });

            if (
              (sampleData && chartType === Charts.TEXTANSWERTABLE) ||
              (!comments && chartType === Charts.TEXTANSWERTABLE)
            ) {
              // we won't export sample text answers
            } else {
              const showDatatables: boolean =
                (type === 'xlsx' ||
                  type === 'csv' ||
                  (!(chartType === Charts.TEXTANSWERTABLE || chartType === Charts.TEXTSENTIMENTANALYSIS)
                    ? (item.exports.imageUrl?.length > 0 || item.exports.mixedTable?.length > 0) && datatables
                    : comments)) &&
                !(
                  extraRespondentFieldAnonymity &&
                  item.data?.details?.some((d) => d.originalType === 'respondent-field') &&
                  [Charts.BARCHARTH, Charts.MULTIBARCHARTH, Charts.STACKEDBARCHARTH].includes(item.chartSettings.type)
                ) &&
                !(
                  extraHastagShareLinkAnonymity &&
                  item.data?.details?.some((d) => ['hashtags', 'shareLink'].includes(d.originalType)) &&
                  [Charts.BARCHARTH, Charts.MULTIBARCHARTH, Charts.STACKEDBARCHARTH].includes(item.chartSettings.type)
                );
              const exportItem = {
                title: item.title,
                keys,
                imageUrl: item.exports.imageUrl,
                dataTable: showDatatables ? item.exports.dataTable.data : [],
                group: (item as any).group,
                questionList: item.exports.questionList,
                width: (item as any).exports.width,
                height: (item as any).exports.height,
              };
              if (item.exports.mixedTable) {
                exportItem['mixedTable'] = item.exports.mixedTable;
              }

              data.push(exportItem);
            }
          }
        }

        this.data = data;
        this.title = title;
        this.type = type;
        this.survey = survey;
        this.respondentCount = respondentCount;

        this.makeRequest('summary', reportKey);
      });
    }
  }

  createRaw(
    survey: string,
    type: string,
    title: string,
    dimensions: DimensionData,
    outcomeMatching: string = '',
    respondentCount: number = 0,
    textContacts: TextData = {},
  ) {
    this.type = type;
    this.survey = survey;
    this.title = title;
    this.outcomeMatching = outcomeMatching;
    this.respondentCount = respondentCount;

    for (const d in dimensions) {
      const dimension: DimensionDataItem = dimensions[d];
      if (
        Questions.choices.includes(dimension.originalType as Questions) ||
        dimension.originalType === Questions.INPUT_DROPDOWN
      ) {
        this.choiceLabels[dimension.key] = dimension.labelsCategorical;
      } else if (dimension.originalType === 'outcome') {
        this.outcomeLabels = dimension.labelsCategorical;
      } else if (
        dimension.originalType === 'contact-property' &&
        ((dimension.scale === 'contact-text' && textContacts?.[dimension.key]?.filter((text) => text).length) ||
          dimension.values.length > 0)
      ) {
        this.contactFields.push(dimension.key.slice(9));
      }
    }

    this.makeRequest('raw');
  }

  makeRequest(exportType: string, reportKey?: string) {
    const token = this.store.selectSnapshot(AuthState.authToken);
    const activeTeamKey = this.store.selectSnapshot(AccountState.teamKey);

    const headers = reportKey
      ? new HttpHeaders()
          .set('Active-Team', activeTeamKey || '')
          .set('Target-Report', reportKey || '')
          .set('firebase-authorization', token || '')
      : new HttpHeaders().set('Active-Team', activeTeamKey || '').set('firebase-authorization', token || '');

    const data = {
      data: this.data,
      title: this.title,
      survey: this.survey,
      choiceLabels: this.choiceLabels,
      respondentCount: this.respondentCount,
    };
    if (Object.keys(this.contactFields).length > 0) {
      data['contactFields'] = this.contactFields;
    }
    if (Object.keys(this.outcomeLabels).length > 0) {
      data['outcomeLabels'] = this.outcomeLabels;
    }
    if (this.outcomeMatching) {
      data['outcomeMatching'] = this.outcomeMatching;
    }
    this.http
      .post(`https:${environment.apiServer}/export/${exportType}?type=${this.type}`, data, {
        headers,
        withCredentials: this.trackingAllowed || false,
      })
      .subscribe();
  }

  download(key: string, survey: string, token: string, type: string = '') {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const userKey = this.store.selectSnapshot(AccountState.userKey);

    const auth = `token=${token}&team=${teamKey}&user=${userKey}${type ? '&type=' + type : ''}`;
    window.open(`https:${environment.apiServer}/export/download/${survey}/${key}?${auth}`);
  }

  public addExport(surveyKey: string, exportInfo: ExportItem) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const userKey = this.store.selectSnapshot(AccountState.userKey);
    if (userKey) {
      const items = this.db.list(`/exports/${teamKey}/${userKey}`);
      exportInfo.source = surveyKey;
      items.push(exportInfo);
    }
  }

  public deleteExport(exportKey: string) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const userKey = this.store.selectSnapshot(AccountState.userKey);
    if (userKey) {
      const item = this.db.object(`/exports/${teamKey}/${userKey}/${exportKey}`);
      item.remove();
    }
  }

  public listExports(surveyKey: string) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const userKey = this.store.selectSnapshot(AccountState.userKey);

    if (userKey) {
      return this.db
        .list(`/exports/${teamKey}/${userKey}`, (ref) => ref.orderByChild('source').equalTo(surveyKey))
        .snapshotChanges();
    } else {
      return of([]);
    }
  }

  public exportDataTable(
    exportType: string,
    dataTable: (string | number)[][],
    surveyKey: string,
    surveyName: string,
  ): Observable<any> {
    const token = this.store.selectSnapshot(AuthState.authToken);
    const activeTeamKey = this.store.selectSnapshot(AccountState.teamKey);
    const headers = new HttpHeaders()
      .set('Active-Team', activeTeamKey || '')
      .set('firebase-authorization', token || '');
    const data = { survey: surveyKey, title: surveyName, filename: surveyName, data: { data: dataTable } };

    return this.http
      .post(`https:${environment.apiServer}/export/table?type=${exportType}`, data, {
        headers,
        withCredentials: this.trackingAllowed || false,
      })
      .pipe(
        timeout(300000),
        catchError(() => of('failed')),
        mergeMap((response: any) => {
          if (response && response.key && response.authToken) {
            this.download(response.key, surveyKey, response.authToken, 'table');
            return of('success');
          } else {
            return of('failed');
          }
        }),
      );
  }
}
