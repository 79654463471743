import { ReplaySubject, Subject } from 'rxjs';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { Charts } from '@shared/enums/charts.enum';
import { Rights } from '@shared/enums/rights.enum';

import {
  AnonymityStatus,
  ChartContent,
  ChartSettings,
  ChartSizeInput,
  Exports,
  GridItem,
} from '@shared/models/report.model';

import { ReportManager } from '@shared/services/report-manager.service';

import { AnonymityChecker } from '@report/shared/services/anonymity-checker.service';
import { Crossfilter } from '@report/shared/services/crossfilter.service';
import { ChartsManager } from '@report/shared/services/charts-manager.service';
import { DataConverter } from '@report/shared/services/data-converter.service';

import { Calculations } from '@report/shared/enums/calculations.enum';
import { Questions } from '@shared/enums/questions.enum';
import { drawChartLegends, drawIntelligenceIcon, drawSummaryAverageItem } from '@shared/utilities/canvas.utilities';
import { generateChartImageContent, setChartImageSizes } from '@shared/utilities/report-export.utilities';
import { Colors } from '@report/shared/enums/colors.enum';

/**
 * This is a chartcard component which draws chart card elements: menu, chart, datatable etc.
 */
@Component({
  selector: 'chart-card',
  templateUrl: './chart-card.component.html',
  styleUrls: ['./chart-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartCard implements OnInit, OnChanges, OnDestroy {
  tooltip1 = $localize`:tooltip@@zef-i18n-00287:Enter full screen`;
  tooltip2 = $localize`:tooltip@@zef-i18n-00288:Exit full screen`;
  tooltip3 = $localize`:tooltip@@zef-i18n-00289:Absolute values`;
  tooltip4 = $localize`:tooltip@@zef-i18n-00290:Percentage values`;
  tooltip5 = $localize`:tooltip@@zef-i18n-00291:Grouped tables`;
  tooltip6 = $localize`:tooltip@@zef-i18n-00292:Joined tables`;
  tooltip7 = $localize`:tooltip@@zef-i18n-00293-e:`;
  tooltip8 = $localize`:tooltip@@zef-i18n-00294-e:`;
  tooltip9 = $localize`:tooltip@@zef-i18n-00295-e:`;
  tooltip10 = $localize`Add chart to report`;
  @Input() settings: GridItem = {} as GridItem;
  @Input() data: ChartContent = {} as ChartContent;
  @Input() update: Date | null = null;
  @Input() height: number = 0;
  @Input() width: number = 0;
  @Input() row: number = 0;
  @Input() visibleArea: number[] = [];
  @Input() comparison: any;
  @Input() userRights: number = 0;
  @Input() surveyRights: number = 0;
  @Input() sampleData: boolean = false;
  @Input() onResize: boolean = false;
  @Input() fullscreen: boolean = false;
  @Input() explorable: boolean = true;
  @Input() mobileView: boolean = false;
  @Input() touchDevice: boolean = false;
  @Input() isSharedReport: boolean = false;
  @Input() filtersDemo: boolean = false;
  @Input() trendHoverInput: string = '';
  @Input() crossfilter: Crossfilter | null = null;
  @Input() importedOriginalAtRiskStatus: boolean = false;
  @Input() importedOriginalNonSummaryStatus: boolean = false;
  @Input() discoverChart: boolean = false;
  @Output() openBuilder = new EventEmitter<any>();
  @Output() duplicateChart = new EventEmitter<any>();
  @Output() pinnedChart = new EventEmitter<any>();
  @Output() removedChart = new EventEmitter<any>();
  @Output() hidedChart = new EventEmitter<any>();
  @Output() toggleFullscreen = new EventEmitter<any>();
  @Output() trendHoverOutput = new EventEmitter<string>();
  @Output() addDiscoverChart = new EventEmitter<string>();

  @ViewChild('dataTable') dataTableElem: ElementRef | null = null;
  @ViewChild('editChartEl') editChartElem: ElementRef | null = null;
  @ViewChild('headerText') headerTextElem: ElementRef | null = null;

  readonly chartTypes = Charts;
  readonly Rights = Rights;

  public cardSettings: any;

  private imageSub: any;
  private closeMenusSub: any;
  private savedSub: any;
  private updateSub: any;
  private updateChartNameSub: any;

  private updateChartName: Subject<any> = new Subject();
  private previousSettings: ChartSettings;

  private dataService: Crossfilter | null = this.cf;

  public chartContent: ChartContent | null = null;
  public chartDataTableData: any;

  public chartHeight: number = 0;
  public exportChartHeight: number = 0;
  public chartItemHeight: number = 0;
  public dataTableHeight: number = 0;
  public dataTablePadding: string | null = null;
  public editChartHeight: number = 0;

  public isSaved: boolean = false;

  public transition: number = 0;

  public chartTypeSelector: any;
  public scaleTypeSelector: boolean = false;
  public summaryChartComparisonModeSelector: boolean = false;
  public zScoredValuesSelector: boolean = false;
  public recommendedTypes: string[] = [];

  public showMoreButton: boolean = false;
  public showLessButton: boolean = false;
  public headerTextHeight: number = 0;
  public headerTextArea: number = 64;

  public exportingOn: boolean = false;
  public exportSize: ChartSizeInput = { width: 0, height: 0 };

  private updateWaiting: boolean = false;
  private visible: boolean = false;
  private elementTop: number = 0;
  private elementBottom: number = 0;

  public image: string = '';

  public isData: boolean = false;
  public anonymityTreshold: number = null;
  public anonymityStatus: AnonymityStatus = { atRisk: false, hide: false, trend: false } as AnonymityStatus;
  public extraAnonymityProtection: boolean = false;
  public extraRespondentFieldAnonymity: boolean = false;

  public originalAtRiskStatus: boolean = false;
  public originalNonSummaryStatus: boolean = false;

  public trendHoverThrottled: string = '';

  private onChanges = new ReplaySubject<SimpleChanges>(1);

  constructor(
    private ac: AnonymityChecker,
    private cf: Crossfilter,
    private cm: ChartsManager,
    private dc: DataConverter,
    private rm: ReportManager,
    private _element: ElementRef,
    private cdRef: ChangeDetectorRef,
  ) {
    const text = $localize`:@@zef-i18n-00285:Show datatable`;

    this.cardSettings = {
      dataTable: { display: false, text, height: '0' },
      editChart: { display: false, height: '0' },
    };

    this.imageSub = this.cm.imageSubj.subscribe((exportType: string) => {
      if (exportType && !this.discoverChart) {
        if (!this.settings.exports) {
          this.settings.exports = {} as Exports;
        }
        this.createImageUrl(exportType);
        this.createDataTable();
        this.createQuestionList();
      } else if (!exportType && this.settings) {
        this.settings.exports = {} as Exports;
      }
    });

    this.closeMenusSub = this.cm.closeMenusSubj.subscribe((close) => {
      if (close) {
        this.editChart(false);
        if (this.cardSettings.dataTable.display === true) {
          this.dataTableSwitch();
        }
      }
    });

    this.savedSub = this.rm.saved.subscribe((state) => {
      this.isSaved = state;
    });

    this.updateSub = this.cm.updateSubj.subscribe((update) => {
      if (update) {
        this.updateData();
        this.updateOthers();

        this.cdRef.detectChanges();
      }
    });
  }

  ngOnInit() {
    this.onChanges.subscribe((changes: SimpleChanges) => {
      if (
        this.visibleArea &&
        (!changes['visibleArea'] || !changes['visibleArea'].isFirstChange()) &&
        (changes['gotSize'] || changes['visibleArea'] || changes['comparison'] || changes['trend'] || changes['height'])
      ) {
        const gridTile = this._element.nativeElement.closest('.mat-grid-tile');

        if (gridTile) {
          const offsetTop = gridTile.offsetTop;
          const offsetHeight = gridTile.offsetHeight;
          const offsetParent = gridTile.parentElement.parentElement.offsetTop;

          this.elementTop = offsetTop + offsetParent;
          this.elementBottom = offsetTop + offsetParent + offsetHeight;
          this.visible =
            this.elementTop <= this.visibleArea[3] + this.visibleArea[1] && this.elementBottom >= this.visibleArea[3];
          this.transition = 0;
        } else {
          this.visible = true;
          this.transition = 0;
        }

        const middlePoint: number = this.elementTop - (this.elementTop - this.elementBottom) / 2;
        const atLeastHalfVisible: boolean = middlePoint <= this.visibleArea[1] && middlePoint >= this.visibleArea[0];
        this.cm.updateItemVisibility(this.settings.gridId, atLeastHalfVisible);
      }

      if (this.fullscreen || this.discoverChart) {
        this.visible = true;

        if (!this.cardSettings.dataTable.display) {
          this.dataTableHeight = 0;
        }
      }

      if (changes['update']) {
        this.updateWaiting = true;
      }

      if (this.visible && changes['update']) {
        this.transition = 750;
      }

      if (this.visible) {
        this.trendHoverThrottled = this.trendHoverInput;
      }

      if (changes['crossfilter']) {
        if (this.crossfilter) {
          this.dataService = this.crossfilter;
        } else {
          this.dataService = this.cf;
        }
      }

      if (changes['update'] || changes['data'] || changes['settings']) {
        this.updateAnonymityStatus();
      }

      if (this.visible && changes) {
        if (changes['settings']) {
          this.updateData();
          this.updateOthers();
        } else if (!this.data) {
          this.updateData();
          this.updateOthers();
        } else if (changes['update'] || changes['data']) {
          this.updateData();
          this.updateOthers();
        } else if (changes['height'] || changes['width']) {
          this.updateData();
          this.updateOthers();
        } else if (this.updateWaiting) {
          this.updateData();
          this.updateOthers();
        }
      }
    });
  }

  ngOnDestroy() {
    this.onChanges.complete();
    this.imageSub.unsubscribe();
    this.closeMenusSub.unsubscribe();
    this.savedSub.unsubscribe();
    this.updateSub.unsubscribe();
    if (this.updateChartNameSub) {
      this.updateChartNameSub.unsubscribe();
    }
  }

  public onEditChartResize($event) {
    if (this.editChartHeight) {
      const editChartHeight = Calculations.gridSizeFromHeightCeil($event.dimensions.height);
      const change = editChartHeight - this.editChartHeight;

      if (change !== 0) {
        this.editChartHeight += change;
        this.cardSettings.editChart.height = this.editChartHeight * Calculations.gridHeightUnit(this.editChartHeight);
        if (!this.onResize && this.settings.gridSettings.sizey) {
          this.settings.gridSettings.sizey += change;
          this.cdRef.markForCheck();
        }
      }
    }
  }

  // public onDataTableResize($event) {
  // if (this.dataTableHeight) {
  //   const dataTableHeight = Calculations.gridSizeFromHeightCeil($event.dimensions.height);
  //   this.dataTablePadding = this.cardSettings.dataTable.height - $event.dimensions.height + 'px';
  //   const change = dataTableHeight - this.dataTableHeight;
  //   console.log('change', change);
  //   if (change !== 0) {
  //     this.dataTableHeight += change;
  //     this.cardSettings.dataTable.height = ( this.dataTableHeight )
  //                                           * Calculations.gridHeightUnit(this.dataTableHeight);
  //     this.dataTablePadding = this.cardSettings.dataTable.height - $event.dimensions.height + 'px';
  //     if (!this.onResize && this.settings.gridSettings.sizey) {
  //       this.settings.gridSettings.sizey += change;
  //     }
  //   }
  // }
  // }

  public onCardHeaderResize() {
    if (!this.elementTop || !this.elementBottom) {
      this.onChanges.next({ gotSize: {} as SimpleChange });
    }

    if (this.headerTextElem && this.headerTextElem.nativeElement.scrollHeight != null) {
      this.headerTextHeight = this.headerTextElem.nativeElement.scrollHeight;
      this.headerTextArea = this.settings.chartSettings.expandedTitle ? null : this.mobileView ? 150 : 64;

      if (
        ((!this.mobileView && this.headerTextElem.nativeElement.scrollHeight > 64) ||
          (this.mobileView && this.headerTextElem.nativeElement.scrollHeight > 150)) &&
        !this.settings.chartSettings.expandedTitle
      ) {
        this.showMoreButton = true;
      } else {
        this.showMoreButton = false;
      }
      this.cdRef.markForCheck();
      this.cdRef.detectChanges();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.onChanges.next(changes);
  }

  updateData() {
    const chartType: string = this.getChartType();
    this.chartContent = Object.assign({}, this.data);
    const nonHelperData: number[] =
      this.chartContent.totalAnswers?.filter(
        (totalAnswers, index) =>
          (!this.chartContent.comparison || this.chartContent.comparison.index !== index) &&
          (!this.chartContent.trend || this.chartContent.trend.index !== index),
      ) || [];
    this.isData = !nonHelperData.length || nonHelperData.some((val) => !!val) || chartType === Charts.RESPONSERATES;
    this.anonymityTreshold = this.dataService.getAnonymityTreshold();
    this.chartDataTableData = this.dc.chartDataTable(this.data, chartType, this.anonymityTreshold, this.dataService);

    this.updateWaiting = false;
  }

  updateOthers() {
    this.showScaleTypeSelector(this.getChartType());
    this.showsummaryChartComparisonModeSelector(this.settings.chartSettings.type);
    this.showZScoredValuesSelector(this.settings.chartSettings.type);
    this.recommendedTypes = this.cm.chartTypesRecommendation(this.data.scales, this.data.details, this.comparison);
  }

  updateAnonymityStatus() {
    this.anonymityTreshold = this.dataService.getAnonymityTreshold();
    this.extraRespondentFieldAnonymity =
      this.isSharedReport &&
      this.data?.details?.some((dim) => dim?.originalType === 'respondent-field') &&
      [Charts.BARCHARTH, Charts.MULTIBARCHARTH, Charts.STACKEDBARCHARTH].includes(this.settings?.chartSettings?.type) &&
      this.dataService.getExtraRespondentFieldAnonymityStatus();

    const chartType: string = this.getChartType();
    const nonLockableCharts: string[] = [Charts.NPS, Charts.SUMMARY1D, Charts.SUMMARY2D];
    const content = Object.assign({}, this.data);
    const hasFilters: boolean = Object.keys(this.dataService?.filters || {}).length > 0;
    const skipAnonymity = this.ac.getChartSkipAnonymity(
      content,
      this.dataService?.filters,
      chartType,
      this.anonymityTreshold,
      this.extraRespondentFieldAnonymity,
    );

    if (!hasFilters) {
      this.originalAtRiskStatus = this.ac.checkAtRiskStatus(content, chartType, this.anonymityTreshold);

      if (nonLockableCharts.includes(chartType)) {
        this.originalNonSummaryStatus = this.ac.checkAtRiskStatus(
          content,
          chartType === Charts.SUMMARY2D ? Charts.HEATMAP : Charts.BARCHARTV,
          this.anonymityTreshold,
        );
      } else {
        this.originalNonSummaryStatus = false;
      }
    }

    this.extraAnonymityProtection =
      !skipAnonymity &&
      hasFilters &&
      (this.originalAtRiskStatus ||
        this.importedOriginalAtRiskStatus ||
        (!nonLockableCharts.includes(chartType) &&
          (this.originalNonSummaryStatus || this.importedOriginalNonSummaryStatus)));

    this.anonymityStatus = this.ac.checkAnonymityStatus(
      content,
      chartType,
      this.anonymityTreshold,
      this.extraAnonymityProtection,
      hasFilters,
      this.dataService?.filters,
      this.extraRespondentFieldAnonymity,
    );
  }

  isAnonymousNPS(): boolean {
    return this.anonymityTreshold && this.settings?.chartSettings?.type === Charts.NPS;
  }

  isRecommended(chart: string) {
    return this.recommendedTypes.filter((item) => item === chart).length > 0 ? true : false;
  }

  showDataTableSwitch(menuOverride: boolean = false): boolean {
    return (
      !this.anonymityStatus.hide &&
      !this.anonymityStatus.trend &&
      this.isData &&
      !this.discoverChart &&
      (!this.isSharedReport || this.cardSettings.dataTable.display || menuOverride) &&
      !this.isAnonymousNPS() &&
      this.settings.chartSettings?.type &&
      this.settings.chartSettings.type !== Charts.SMARTTEXTCHART &&
      this.settings.chartSettings.type !== Charts.TEXTANSWERTABLE &&
      this.settings.chartSettings.type !== Charts.TEXTSENTIMENTANALYSIS &&
      this.settings.chartSettings.type !== Charts.WORDCLOUD &&
      this.settings.chartSettings.type !== Charts.WORDANALYSIS &&
      this.settings.chartSettings.type !== Charts.BARCHARTHPLUSTEXT &&
      this.settings.chartSettings.type !== Charts.INTERVIEWERSUMMARY &&
      this.settings.chartSettings.type !== Charts.WHYFINDERSUMMARY &&
      this.settings.chartSettings.type !== Charts.SUMMARYAVERAGES
    );
  }

  showScaleTypeSelector(type: string) {
    const supportedTypes = [
      Charts.BARCHARTH,
      Charts.BARCHARTV,
      Charts.MULTIBARCHARTH,
      Charts.MULTIBARCHARTV,
      Charts.MULTILINECHART,
      Charts.LINECHART,
      Charts.AREACHART,
      Charts.STACKEDBARCHARTH,
      Charts.STACKEDBARCHARTV,
      Charts.NPS,
      Charts.TRENDCATEGORICALCHART,
      Charts.TRENDNPSCHART,
      Charts.TIMELINECHART,
      Charts.WORDANALYSIS,
      Charts.SUMMARYCHOICE,
    ];
    if (supportedTypes.indexOf(type) >= 0) {
      this.scaleTypeSelector = true;
    } else {
      this.scaleTypeSelector = false;
    }
  }

  showsummaryChartComparisonModeSelector(type: string) {
    if (
      (type === Charts.SUMMARY1D ||
        type === Charts.SUMMARY2D ||
        type === Charts.TRENDLINEAR1DCHART ||
        type === Charts.TRENDLINEAR2DCHART) &&
      this.chartContent &&
      this.chartContent.comparison
    ) {
      this.summaryChartComparisonModeSelector = true;
    } else {
      this.summaryChartComparisonModeSelector = false;
    }
  }

  showZScoredValuesSelector(type: string) {
    if (type === Charts.RADARCHART) {
      let zAverages = 0;
      // let linearScales = 0;
      if (this.chartContent) {
        for (const i in this.chartContent.domain) {
          if (this.chartContent.domain[i]?.scale === 'linear') {
            // linearScales += 1;
            if (this.chartContent.stats[i]?.zAverage != null) {
              zAverages += 1;
            }
          }
        }
      }

      if (zAverages > 0) {
        this.zScoredValuesSelector = true;
      } else {
        this.zScoredValuesSelector = false;
      }
    } else {
      this.zScoredValuesSelector = false;
    }
  }

  expandHeader() {
    if (this.settings.chartSettings) {
      this.settings.chartSettings.expandedTitle = true;
    }
    this.showMoreButton = false;
    this.settings.gridSettings.sizey += Calculations.gridSizeFromHeightCeil(
      this.headerTextHeight - (this.mobileView ? 150 : 64),
    );

    this.showLessButton = true;
    this.cdRef.detectChanges();
  }

  shrinkHeader() {
    if (this.settings.chartSettings) {
      this.settings.chartSettings.expandedTitle = false;
    }
    this.showLessButton = false;
    this.cdRef.detectChanges();
    setTimeout(() => {
      this.settings.gridSettings.sizey -= Calculations.gridSizeFromHeightCeil(
        this.headerTextHeight - (this.mobileView ? 150 : 64),
      );

      this.cdRef.markForCheck();
      this.cdRef.detectChanges();
    }, 0);
  }

  dataTableSwitch() {
    if (this.cardSettings.dataTable.display === false) {
      this.cardSettings.dataTable.display = true;
      this.cardSettings.dataTable.text = $localize`:@@zef-i18n-00286:Hide datatable`;
      this.cdRef.detectChanges();

      const checkExist = setInterval(() => {
        if (
          this.dataTableElem &&
          this.dataTableElem.nativeElement &&
          this.dataTableElem.nativeElement.firstElementChild &&
          this.dataTableElem.nativeElement.firstElementChild.firstElementChild &&
          this.dataTableElem.nativeElement.firstElementChild.firstElementChild.scrollHeight > 0
        ) {
          this.dataTableHeight = Calculations.gridSizeFromHeightCeil(
            this.dataTableElem.nativeElement.firstElementChild.scrollHeight,
          );
          this.settings.gridSettings.sizey += this.dataTableHeight;
          this.cardSettings.dataTable.height = this.dataTableHeight * Calculations.gridHeightUnit(this.dataTableHeight);
          this.cdRef.markForCheck();
          this.cdRef.detectChanges();
          clearInterval(checkExist);
        }
      }, 10);
    } else {
      this.cardSettings.dataTable.display = false;
      this.cardSettings.dataTable.text = $localize`:@@zef-i18n-00285:Show datatable`;
      if (this.settings.gridSettings.sizey) {
        this.settings.gridSettings.sizey -= this.dataTableHeight;
      }
      this.cdRef.detectChanges();
    }
  }

  cancelEditChart(): void {
    if (this.previousSettings && this.previousSettings.zScoredValues !== this.settings.chartSettings.zScoredValues) {
      this.useZScoredValuesSwitch({ checked: this.previousSettings.zScoredValues });
    }
    if (
      this.previousSettings &&
      this.previousSettings.summaryChartComparisonMode !== this.settings.chartSettings.summaryChartComparisonMode
    ) {
      this.changesummaryChartComparisonMode(this.previousSettings.summaryChartComparisonMode);
    }
    if (this.previousSettings && this.previousSettings.showNumbers !== this.settings.chartSettings.showNumbers) {
      this.showValuesSwitch({ checked: this.previousSettings.showNumbers });
    }
    if (this.previousSettings && this.previousSettings.scale !== this.settings.chartSettings.scale) {
      this.changeScaleType(this.previousSettings.scale);
    }
    if (this.previousSettings && this.previousSettings.type !== this.settings.chartSettings.type) {
      this.changeChartType(this.previousSettings.type);
    }
    this.editChart(false);
  }

  editChart(action: any) {
    if (this.cardSettings.editChart.display === false && action === true) {
      this.previousSettings = JSON.parse(JSON.stringify(this.settings.chartSettings));
      this.cardSettings.editChart.display = action;

      const checkExist = setInterval(() => {
        if (this.editChartElem) {
          this.editChartHeight = Calculations.gridSizeFromHeightCeil(this.editChartElem.nativeElement.scrollHeight);
          this.settings.gridSettings.sizey += this.editChartHeight;
          this.cardSettings.editChart.height = this.editChartHeight * Calculations.gridHeightUnit(this.editChartHeight);
          this.cdRef.markForCheck();
          this.cdRef.detectChanges();
          clearInterval(checkExist);
        }
      }, 10);
      this.updateChartNameSub = this.updateChartName.pipe(debounceTime(500), distinctUntilChanged()).subscribe(() => {
        this.cm.gridChange.next('Chart name changed');
      });
    } else if (this.cardSettings.editChart.display === true && action === false) {
      this.previousSettings = {} as ChartSettings;
      this.cardSettings.editChart.display = action;

      if (this.settings.gridSettings.sizey) {
        this.settings.gridSettings.sizey -= this.editChartHeight;
      }

      this.editChartHeight = null;
      if (this.updateChartNameSub) {
        this.updateChartNameSub.unsubscribe();
      }
    }
  }

  showValuesSwitch(action) {
    this.settings.chartSettings.showNumbers = action.checked;
    if (this.chartContent) {
      this.chartContent.timestamp = new Date(); // For updating chart
    }
    this.cm.gridChange.next(`${action.checked ? 'Showed' : 'Hided'} numbers in chart`);
  }

  openChartBuilder() {
    this.openBuilder.emit({
      title: this.settings.title,
      customTitle: this.settings.customTitle,
      details: this.data.details,
    });
  }

  duplicateThisChart() {
    this.duplicateChart.emit(this.settings);
  }

  pin(action) {
    this.pinnedChart.emit({ key: this.settings.key, action });
  }

  changeChartType(action) {
    this.showScaleTypeSelector(action);

    this.settings.chartSettings.type = action;
    this.updateAnonymityStatus();
    this.cdRef.detectChanges();

    this.updateChartSize(action, this.comparison);

    if (this.chartContent) {
      this.chartContent.timestamp = new Date(); // For updating chart
    }

    this.cm.gridChange.next('Changed chart type');
  }

  updateChartSize(chartType: string, comparison: any = []) {
    let newSize = 0;
    newSize += this.chartContent
      ? this.cm.chartHeightRecommendation(this.chartContent, chartType, comparison, this.settings.chartSettings)
      : 0;
    newSize += this.cardSettings.editChart.display ? this.editChartHeight : 0;
    newSize += this.cardSettings.dataTable.display ? this.dataTableHeight : 0;

    this.settings.gridSettings.sizey = newSize;
    this.cdRef.markForCheck();
  }

  changeScaleType(action) {
    this.settings.chartSettings.scale = action;

    if (this.chartContent) {
      this.chartContent.timestamp = new Date(); // For updating chart
    }

    this.cm.gridChange.next('Changed scale type in chart');
  }

  public settingsChange($event) {
    for (const setting in $event || {}) {
      this.settings.chartSettings[setting] = $event[setting];
    }

    if ($event?.smartTextChartMode) {
      this.updateChartSize(this.settings.chartSettings.type, this.comparison);
    }

    this.cm.gridChange.next('Changed settings inside chart');
  }

  useZScoredValuesSwitch(action) {
    this.settings.chartSettings.zScoredValues = action.checked;

    if (this.chartContent) {
      this.chartContent.timestamp = new Date(); // For updating chart
    }

    this.cm.gridChange.next('Changed values in chart');
  }

  changesummaryChartComparisonMode(action) {
    this.settings.chartSettings.summaryChartComparisonMode = action;
    this.updateChartSize(this.settings.chartSettings.type, this.comparison);

    if (this.chartContent) {
      this.chartContent.timestamp = new Date(); // For updating chart
    }

    this.cm.gridChange.next('Changed summary chart comparison mode');
  }

  toggleFullScreen() {
    this.editChart(false);
    if (this.cardSettings.dataTable.display === true) {
      this.dataTableSwitch();
    }
    this.toggleFullscreen.emit(this.settings.gridId);
  }

  renameChart(title) {
    this.settings.title = title;
    if (!this.settings['customTitle']) {
      this.settings['customTitle'] = {};
    }
    this.settings['customTitle'][this.cm.activeLocale || 'default'] = title;
    this.updateChartName.next(title);
  }

  createImageUrl(exportType: string) {
    const type = this.getChartType();

    this.updateData();
    this.updateOthers();
    this.exportSize.width = 1000;

    setChartImageSizes(type, this.chartTypes, this.comparison, this.exportSize, this.chartContent, this.settings);

    this.exportingOn = type !== Charts.TEXTANSWERTABLE ? true : false;
    this.cdRef.detectChanges();

    setTimeout(() => {
      generateChartImageContent(
        exportType,
        type,
        this.chartTypes,
        this._element,
        this.chartContent,
        this.settings,
        Colors,
        Questions,
        drawChartLegends,
        drawIntelligenceIcon,
        drawSummaryAverageItem,
      );

      this.exportingOn = false;
      this.cdRef.detectChanges();
    }, 2000);
  }

  createDataTable() {
    const isSmartTextChart = this.settings.chartSettings.type === Charts.SMARTTEXTCHART;
    const type = this.getChartType();
    this.updateData();

    if (
      !this.anonymityStatus.hide &&
      !this.anonymityStatus.trend &&
      !this.isAnonymousNPS() &&
      ![Charts.WORDANALYSIS].includes(type) &&
      ![Charts.SUMMARYAVERAGES].includes(type)
    ) {
      if (type === Charts.TEXTANSWERTABLE && this.settings?.data?.details) {
        const columns = this.settings.data.details
          ? this.settings.data.details
              .filter(
                (item) =>
                  (!this.comparison ||
                    !(
                      ((this.dataService.getTextFreezingStatus() && this.isSharedReport) || this.anonymityTreshold) &&
                      item?.key === this.comparison.key
                    )) &&
                  !(
                    ((this.dataService.getTextFreezingStatus() && this.isSharedReport) || this.anonymityTreshold) &&
                    this.dataService.getTrendAnalysisStatus() &&
                    item?.key === 'time'
                  ) &&
                  (!isSmartTextChart ||
                    (isSmartTextChart &&
                      item.originalTypeSpecifier !== 'text-words' &&
                      item.originalTypeSpecifier !== 'text-emotions' &&
                      item.originalTypeSpecifier !== 'text-sentiment')),
              )
              .filter((item, i) => !this.anonymityTreshold || i === 0)
          : [];
        const textAnswerTable: any[] = this.dataService.getTextAnswersFor(columns, false, false, true) || [];
        const questionTitle = (question) => {
          for (const dom of this.settings.data.domain || []) {
            if (dom.key === question) {
              return dom.title + (dom.labelsLinear && dom.labelsLinear.axis ? ` (${dom.labelsLinear.axis})` : '');
            }
          }
          return '';
        };
        const headers: string[] =
          columns.map((det) => (det && columns.length > 1 && questionTitle(det.key)) || '') || [];
        const valuesOriginal: string[][] = textAnswerTable.map((row) =>
          row.filter((v, i) => i > 0).map((cell) => cell.text || ''),
        );
        let values: string[][];

        if (this.settings?.chartSettings?.sortKey) {
          const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
          const sortKey = this.settings.chartSettings.sortKey;
          const sortDirection = this.settings.chartSettings?.sortDirection;
          const sortIndex = columns.findIndex((item) => item.key === sortKey);

          values =
            sortKey === 'default'
              ? sortDirection === 'desc'
                ? valuesOriginal.reverse()
                : valuesOriginal
              : sortKey === 'time'
                ? sortDirection === 'asc'
                  ? valuesOriginal.reverse()
                  : valuesOriginal
                : sortIndex >= 0
                  ? valuesOriginal.sort((a, b) => {
                      if (a[sortIndex] && b[sortIndex]) {
                        if (sortDirection === 'asc') {
                          return collator.compare(a[sortIndex], b[sortIndex]);
                        } else {
                          return collator.compare(b[sortIndex], a[sortIndex]);
                        }
                      } else {
                        return a[sortIndex] ? -1 : 1;
                      }
                    })
                  : valuesOriginal;
        } else {
          values = valuesOriginal;
        }

        const modifiedTable: any = {
          data: [{ headers, values }],
        };

        this.settings.exports.dataTable = modifiedTable;
      } else if (type === Charts.TEXTSENTIMENTANALYSIS && this.settings?.data?.details) {
        const detailsArray = [
          this.settings.data.details.find((item) => item.scale === 'text'),
          this.settings.data.details.find((item) => item.scale === 'linear'),
          this.settings.data.details.find(
            (item) =>
              item.scale === 'categorical' &&
              item.originalTypeSpecifier !== 'text-words' &&
              item.originalTypeSpecifier !== 'text-emotions',
          ),
          this.settings.data.details.find((item) => item.scale === 'time'),
        ]
          .filter((item) => item)
          .filter(
            (item) =>
              (!this.comparison ||
                !(
                  ((this.dataService.getTextFreezingStatus() && this.isSharedReport) || this.anonymityTreshold) &&
                  item?.key === this.comparison.key
                )) &&
              !(
                ((this.dataService.getTextFreezingStatus() && this.isSharedReport) || this.anonymityTreshold) &&
                this.dataService.getTrendAnalysisStatus() &&
                item?.key === 'time'
              ),
          )
          .filter((item, i) => !this.anonymityTreshold || i === 0);
        const textAnswerTable: any[] = this.dataService.getTextAnswersFor(detailsArray, false, false, true) || [];
        const questionTitle = (question) => {
          for (const dom of this.settings.data.domain || []) {
            if (dom.key === question) {
              return dom.title + (dom.labelsLinear && dom.labelsLinear.axis ? ` (${dom.labelsLinear.axis})` : '');
            }
          }
          return '';
        };
        const headers: string[] = detailsArray.map((det) => (det && questionTitle(det.key)) || '') || [];
        const valuesOriginal: string[][] = textAnswerTable.map((row) =>
          row.filter((v, i) => i > 0).map((cell) => cell.text || ''),
        );

        let values: string[][];

        if (this.settings?.chartSettings?.sortKey) {
          const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
          const sortKey = this.settings.chartSettings.sortKey;
          const sortDirection = this.settings.chartSettings?.sortDirection;
          const sortIndex = detailsArray.findIndex((item) => item.key === sortKey);

          values =
            sortKey === 'default'
              ? sortDirection === 'desc'
                ? valuesOriginal.reverse()
                : valuesOriginal
              : sortKey === 'time'
                ? sortDirection === 'asc'
                  ? valuesOriginal.reverse()
                  : valuesOriginal
                : sortIndex >= 0
                  ? valuesOriginal.sort((a, b) => {
                      if (a[sortIndex] && b[sortIndex]) {
                        if (sortDirection === 'asc') {
                          return collator.compare(a[sortIndex], b[sortIndex]);
                        } else {
                          return collator.compare(b[sortIndex], a[sortIndex]);
                        }
                      } else {
                        return a[sortIndex] ? -1 : 1;
                      }
                    })
                  : valuesOriginal;
        } else {
          values = valuesOriginal;
        }

        const modifiedTable: any = {
          data: [{ headers, values }],
        };

        this.settings.exports.dataTable = modifiedTable;
      } else if (type === Charts.INTERVIEWERSUMMARY && this.settings?.data?.details) {
        const detailsArray = this.settings.data.details
          .filter((item) => item)
          .filter((item) => item.originalTypeSpecifier !== 'text-words');
        const textAnswerTable: any[] = this.dataService.getTextAnswersFor(detailsArray, false, false, true) || [];
        const questionTitle = (question) => {
          for (const dom of this.settings.data.domain || []) {
            if (dom.key === question) {
              return dom.title;
            }
          }
          return '';
        };
        const headers: string[] = detailsArray.map((det) => (det && questionTitle(det.key)) || '') || [];
        const values: string[][] = textAnswerTable
          .map((row) => row.filter((v, i) => i > 0).map((cell) => cell.text || ''))
          .filter((row) => row?.[0]?.length && row?.[1]?.length);

        const modifiedTable: any = {
          data: [{ headers, values }],
        };

        this.settings.exports.dataTable = modifiedTable;
      } else {
        this.chartDataTableData?.subscribe((data) => {
          this.settings.exports.dataTable = data;
        });
      }
    } else {
      this.settings.exports.dataTable = { data: [] };
    }
  }

  createQuestionList() {
    const questionList: string[][] = [];
    const base = this._element.nativeElement.querySelector('.export-chart');
    const containers = base
      ? base.querySelectorAll(
          '.summary2d-chart-container, .summary1d-chart-container, .trend-linear-2d-chart-question-list',
        )
      : [];

    for (let i = 0, len = containers.length; i < len; i++) {
      questionList.push([]);
      const container = containers[i];
      const questions = container.querySelectorAll(
        '.summary2d-question-export-text, .summary1d-question-text, .trend-linear-2d-chart-question-list-item',
      );

      for (const question of questions) {
        questionList[i].push(question && question.textContent);
      }

      if (questionList[i].length === 0) {
        questionList[i].push('');
      }
    }

    if (this.settings.chartSettings.type === Charts.SUMMARYAVERAGES) {
      setTimeout(() => {
        const base = this._element.nativeElement.querySelector('.export-chart');
        const text: string = (base ? base.querySelector('.summary-text')?.textContent || '' : '').trim();

        if (text) {
          questionList.push([text]);
          this.settings.exports.questionList = questionList;
        }
      }, 500);
    }

    this.settings.exports.questionList = questionList;
  }

  removeChart($event) {
    if ($event) {
      this.removedChart.emit(this.settings.key);
    }
  }

  hideChart($event) {
    if ($event) {
      this.hidedChart.emit(this.settings.key);
    }
  }

  changeTimePeriod($event) {
    if ($event && $event !== this.dataService.getTimePeriod()) {
      this.dataService.changeTimePeriod($event);
    }
  }

  showRegenerateChartOption() {
    return (
      this.settings?.chartSettings?.type === Charts.WHYFINDERSUMMARY &&
      !this.isSharedReport &&
      (Rights.hasRights(Rights.EDIT, this.surveyRights) || Rights.hasRights(Rights.ADMIN, this.userRights)) &&
      !this.sampleData
    );
  }

  regenerateChart($event) {
    if ($event) {
      const whyFinderKey = this.settings?.data?.details
        ?.find(
          (item) =>
            item?.originalTypeSpecifier === 'why-finder-strengths' ||
            item?.originalTypeSpecifier === 'why-finder-weaknesses',
        )
        ?.key?.split(':')[0];
      this.dataService.updateWhyFinders([whyFinderKey], true);
    }
  }

  isNotMergeable() {
    return [Charts.RESPONSERATES].includes(this.settings?.chartSettings?.type);
  }

  getChartType() {
    return this.settings?.chartSettings?.type !== Charts.SMARTTEXTCHART
      ? this.settings?.chartSettings?.type
      : this.settings?.chartSettings?.smartTextChartMode || Charts.TEXTANSWERTABLE;
  }
}
