import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';

import { BehaviorSubject, debounceTime, first, forkJoin, of, takeUntil } from 'rxjs';

import { Rights } from '@shared/enums/rights.enum';
import { CrossfilterData, Exports, GridItem, GridItemData } from '@shared/models/report.model';

import { Charts } from '@shared/enums/charts.enum';
import { Crossfilter } from '@report/shared/services/crossfilter.service';
import { DataConverter } from '@report/shared/services/data-converter.service';
import { Questions } from '@shared/enums/questions.enum';
import { ChartsManager } from '@report/shared/services/charts-manager.service';
import { OLSRegression } from '@report/shared/enums/ols-regression.enum';
import { ReportAssistant } from '@report/shared/services/report-assistant.service';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { DataChangeEvent } from '@report/shared/enums/data-change-events.enum';
import { getPlainText } from '@shared/utilities/string.utilities';

@Component({
  selector: 'chart-discover',
  templateUrl: './chart-discover.component.html',
  styleUrls: ['./chart-discover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartDiscover implements OnInit {
  tooltip1 = $localize`:tooltip@@zef-i18n-00287:Enter full screen`;
  tooltip2 = $localize`:tooltip@@zef-i18n-00288:Exit full screen`;

  @Output() hidedChart = new EventEmitter<any>();

  readonly Rights = Rights;

  private cfGridItems: GridItemData = {};
  private regressionCache: { [key: string]: any } = {};
  private choiceRegressionCache: { [key: string]: any } = {};
  private aiTitlesCache: { [key: string]: string } = {};
  private deepUpdateNeeded: boolean = false;

  public npsDimensions: any[] = [];
  public categoricalDimensions: any[] = [];
  public gridItems: GridItem[] = [];
  public aiInsights: { [key: string]: string } = {};

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private cdRef: ChangeDetectorRef,
    private cf: Crossfilter,
    private dc: DataConverter,
    private cm: ChartsManager,
    private hooks: LifecycleHooks,
    private ra: ReportAssistant,
  ) {}

  ngOnInit() {
    this.cf.dataChange.pipe(takeUntil(this.hooks.destroy)).subscribe(($event) => {
      if (DataChangeEvent.isInsightUpdateNeeded($event)) {
        this.deepUpdateNeeded = true;
        this.loading$.next(true);
      }
    });
    this.cf.crossfilter.pipe(debounceTime(750), takeUntil(this.hooks.destroy)).subscribe((crossfilter) => {
      this.npsDimensions = [];
      this.categoricalDimensions = [];

      const activeLocale: string = this.cf.getActiveLocale();
      const locales: any = this.cf.getLocales();

      if (crossfilter) {
        for (const dim in crossfilter.dimensions) {
          const dimension = crossfilter.dimensions[dim];
          if (dimension) {
            const survey: string = dimension?.['survey'];
            const title: string = dimension['localeStrings']?.[activeLocale]?.['title'] || dimension.title;
            const customTitle: { [s: string]: string } = {
              default: title,
            };
            for (const locale in locales[survey]?.config || {}) {
              customTitle[locale] = dimension['localeStrings']?.[locale]?.['title'] || dimension.title;
            }
            if (Questions.isNps({ type: dimension.originalType as Questions })) {
              this.npsDimensions.push({
                key: dimension.key,
                title,
                customTitle,
                details: dimension,
              });
            } else if (
              dimension.scale === 'categorical' &&
              dimension.originalType !== 'free-text' &&
              dimension.originalTypeSpecifier !== 'text-words' &&
              dimension.originalType !== 'response-rates' &&
              dimension.key !== 'userSegments' &&
              dimension.values?.length > 1 &&
              dimension.values?.length < 500
            ) {
              this.categoricalDimensions.push({
                key: dimension.key,
                title,
                customTitle,
                details: dimension,
              });
            }
          }
        }
        this.updateCharts(crossfilter);
        this.cdRef.detectChanges();
      }
    });
  }

  private updateCharts(crossfilter: CrossfilterData) {
    const alreadyInGrid: (items: string[]) => boolean = (items) =>
      Object.values(crossfilter.gridItems)
        .map(
          (item) =>
            item?.details?.map((detail) => detail.key).filter((key) => key !== 'userSegments' && key !== 'time') || [],
        )
        .some((keys: string[]) => keys.includes(items[0]) && keys.includes(items[1]) && keys.length === items.length);
    const disableRegression: boolean =
      crossfilter?.stats?.respondentsAll > 10000 ||
      (crossfilter?.stats?.respondentsAll >= 1000 &&
        this.categoricalDimensions.map((d) => d?.details?.values?.length || 0).reduce((a, b) => a + b, 0) > 750);
    this.cfGridItems = {};
    this.gridItems = [];

    for (const nps of this.npsDimensions) {
      for (const cat of this.categoricalDimensions) {
        const dims = [nps.key, cat.key];
        const key = `${nps.key}-${cat.key}`;

        if (!alreadyInGrid(dims)) {
          const cfGridItem = this.cf.gridItemGenerate(dims);
          this.cfGridItems[key] = cfGridItem;
          const calculatedItem = this.cf.calculate({ newGriditem: cfGridItem }, true);

          if (calculatedItem.newGriditem?.totalAnswers?.every((item) => item)) {
            this.gridItems.push({
              hided: false,
              gridId: this.cm.grid.length + this.gridItems.length,
              key,
              title: `${nps.title} - ${cat.title}`,
              // customTitle,
              showDataTable: false,
              chartSettings: {
                type: Charts.NPS,
                scale: 'absolute',
                sortKey: 'npsScore',
                sortDirection: 'desc',
              },
              isGroup: false,
              data: this.dc.BasicConverter(calculatedItem.newGriditem),
              gridSettings: {
                sizex: 1,
                sizey: this.cm.chartHeightRecommendation(calculatedItem['newGriditem'], Charts.NPS),
                col: 1,
                dragHandle: '.header',
              },
              isCustomCard: true,
              exports: {} as Exports,
            });
            if (!disableRegression) {
              this.calculateRegression(cat, nps);
            }
          }
        }
      }
    }
    this.gridItems.sort((a, b) => this.regressionCache[b.key]?.rSquared - this.regressionCache[a.key]?.rSquared);
    this.renameCharts();
  }

  private calculateRegression(cat: any, nps: any) {
    if (!this.deepUpdateNeeded && this.regressionCache[`${nps.key}-${cat.key}`]) {
      return this.regressionCache[`${nps.key}-${cat.key}`];
    }

    const choices = [];
    const allChoices = [];
    const npsVals = [];
    const rawData = this.cf.getTextAnswersFor([nps.details, cat.details]);
    let npsValCount = 0;

    for (let i = 0, len = rawData?.length; i < len; i++) {
      if (rawData[i]?.[1]?.text?.length) {
        const npsVal = Number(rawData[i]?.[1]?.text);
        const choiceArr = rawData[i]?.[2]?.value?.[0]?.filter((d) => d != null && d > -1) || [];
        !isNaN(npsVal) && npsValCount++;

        if (!isNaN(npsVal)) {
          const npsValConverted = [npsVal];
          const choiceArrConverted = cat.details.values.map((d, i) => (choiceArr.includes(i) ? 1 : 0));
          allChoices.push(choiceArrConverted.slice());

          choiceArrConverted.shift();
          npsVals.push(npsValConverted);
          choices.push(choiceArrConverted.filter((d, i) => i < 50));
        }
      }
    }

    const regression = OLSRegression.calculate(choices, npsVals);
    this.regressionCache[`${nps.key}-${cat.key}`] = regression.result || {};

    const valRegs = [];

    for (let i = 0, len = cat.details.values?.length; i < len; i++) {
      const regression = OLSRegression.calculate(
        allChoices.map((item) => item.filter((ci, index) => index === i)),
        npsVals,
      );
      valRegs.push({
        key: cat.details?.values?.[i],
        rsquared: regression.result.rSquared,
        title: cat.details?.labelsCategorical?.[cat.details?.values?.[i]],
        isSignificant: regression.result.pValues[0] < 0.05,
      });
    }
    valRegs.sort((a, b) => b.rsquared - a.rsquared);
    this.choiceRegressionCache[`${nps.key}-${cat.key}`] = valRegs;
  }

  private renameCharts() {
    const isJSON: (string) => boolean = (str) => {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    };
    const activeLocale: string = this.cf.getActiveLocale();
    const language: string = this.cf?.getLocaleConfig()?.[activeLocale]?.name || 'English';
    const reportKey: string = this.cf?.connectedReportKey || '';
    const surveyKey: string = this.cf?.key;
    const currentNames: any[] = this.gridItems.map((item) => ({
      key: item.key,
      originalNames: [
        getPlainText(
          item.data?.details?.[0]?.['localeStrings']?.[activeLocale]?.['title'] || item.data?.details?.[0]?.title || '',
        ),
        getPlainText(
          item.data?.details?.[1]?.['localeStrings']?.[activeLocale]?.['title'] || item.data?.details?.[1]?.title || '',
        ),
      ],
      dataType: [
        'NPS question',
        item.data?.details?.[1]?.originalType === 'respondent-field'
          ? 'Background information'
          : 'Categorical question',
      ],
    }));
    if (!this.gridItems.every((item) => this.aiTitlesCache[item.key]) || this.deepUpdateNeeded) {
      this.ra
        .getShortenedChartNames(surveyKey, reportKey, language, currentNames)
        .pipe(first())
        .subscribe((res: any) => {
          const result = res?.[0]?.message?.function_call?.arguments;
          const names = (isJSON(result) ? JSON.parse(result) : { names: [] })?.names;

          for (const item of this.gridItems) {
            item.title = names.find((nameItem) => item.key === nameItem.key)?.newName || item.title;
            this.aiTitlesCache[item.key] = item.title;
          }

          this.updateInsights();
        });
    } else {
      for (const item of this.gridItems) {
        item.title = this.aiTitlesCache[item.key];
      }
      this.cdRef.detectChanges();
    }
  }

  private updateInsights() {
    const activeLocale: string = this.cf.getActiveLocale();
    const language: string = this.cf?.getLocaleConfig()?.[activeLocale]?.name || 'English';
    const reportKey: string = this.cf?.connectedReportKey || '';
    const surveyKey: string = this.cf?.key;
    const insightArray: any[] = [];
    const npsScores = (allDistr, distr, dom, key) => {
      const npsAll = this.dc.calculateNPS(allDistr);
      const npsCalcCross = this.dc.calculateNPS(distr, dom);
      const significantItems = this.choiceRegressionCache[key]
        ?.filter((d) => d.isSignificant)
        .map((d, i) => {
          const nps = npsCalcCross?.find((item) => item.key === d.key)?.npsScore;
          return `${i + 1}. ${d.title} (NPS: ${nps} (${
            nps - npsAll[0].npsScore > 0 ? '+' : ''
          }${nps - npsAll[0].npsScore}${i === 0 ? 'compared to overall NPS' : ''}))`;
        })
        .join(', ');
      return significantItems?.length > 0
        ? `### Categories with statistical significance ###
          ${
            dom?.title
          } - categories that do have individually calculated statistical significance when predicting NPS: ${
            significantItems || '-'
          }. Other (${
            this.choiceRegressionCache[key]?.filter((d) => !d.isSignificant)?.length
          }) categories were not statistically significant predictors of NPS.`
        : `There were no individual categories with statistical significance when predicting NPS.`;
    };

    for (const item of this.gridItems) {
      const regressionData = this.regressionCache[item.key];
      if (regressionData?.rSquared > 0.1) {
        const text = `### Chart name ###
        ${item.title}

        ### Dimensional significance ###
        Share of NPS variance that could be explained by (${
          item.data?.domain?.[1]?.title
        }): ${Math.round(regressionData.rSquared * 100)}%

        ${npsScores(item.data?.distributions?.[0], item.data?.distributions?.[1], item.data?.domain?.[1], item.key)}`;

        insightArray.push({ key: item.key, text });
      }
    }
    if (insightArray.length > 0) {
      forkJoin(
        insightArray
          .slice(0, 3)
          .map((item) =>
            forkJoin([of(item.key), this.ra.getCrossNPSInsights(surveyKey, reportKey, language, item.text)]),
          ),
      ).subscribe((items) => {
        for (const item of items) {
          this.aiInsights[item[0]] = item[1]?.[0]?.message?.content;
        }
        this.deepUpdateNeeded = false;
        this.loading$.next(false);
        this.cdRef.detectChanges();
      });
    } else {
      this.deepUpdateNeeded = false;
      this.loading$.next(false);
      this.cdRef.detectChanges();
    }
  }

  public addDiscoverChart(key: string) {
    const newKey: string = this.cf.generateId();
    const gridItem = this.gridItems.find((item) => item.key === key);
    const gridItemIndex = this.gridItems.findIndex((item) => item.key === key);
    const cfGridItem = this.cfGridItems[key];
    const insightsIndex = this.cm.grid.findIndex((item) => item.isInsightsCard);

    if (gridItem != null && cfGridItem != null) {
      this.cf.gridItems[newKey] = cfGridItem;

      let row: number = 0;
      for (let i = 0, len = this.cm.grid.length; i < len; i++) {
        if (this.cm.grid[i].gridSettings && this.cm.grid[i].gridSettings.row > row) {
          row = this.cm.grid[i].gridSettings.row;
        }
      }

      gridItem.gridSettings.row = row + 1;
      gridItem.gridId = this.cm.grid.length;
      gridItem.key = newKey;
      this.cm.createGridItem(gridItem);
      this.gridItems.splice(gridItemIndex, 1);

      this.cm.changeOrder(this.cm.grid.length - 1, insightsIndex + 1);

      this.cm.gridChange.next('Added new custom chart');
    }
  }

  public trackByKey(i: number, item: GridItem): string {
    return item.key;
  }
}
