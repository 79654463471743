import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  Component,
  OnInit,
  OnChanges,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';

import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';

import { ChartDistribution, ChartDomain, ChartSettings, DimensionDataItem } from '@shared/models/report.model';

import { rotateAnimation } from '@shared/animations/rotate.anim';
import { Questions } from '@shared/enums/questions.enum';

import { Colors } from '@report/shared/enums/colors.enum';

import { Crossfilter } from '@report/shared/services/crossfilter.service';
import { DataConverter } from '@report/shared/services/data-converter.service';
import { ReportAssistant } from '@report/shared/services/report-assistant.service';

import * as d3 from 'd3';

/**
 * This is a text emotions analysis.
 */
@Component({
  selector: 'why-finder-summary',
  templateUrl: './why-finder-summary.component.html',
  styleUrls: ['./why-finder-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [rotateAnimation],
})
export class WhyFinderSummary implements OnInit, OnChanges {
  @Input() details: DimensionDataItem[] = [];
  @Input() domain: ChartDomain[] = [];
  @Input() distributions: ChartDistribution[][] = [];
  @Input() stats: any[] = [];
  @Input() filterInput: any[] = [];
  @Input() crossfilter: Crossfilter | null = null;
  @Input() comparison: any;
  @Input() trend: any;
  @Input() totalAnswers: any;
  @Input() trendHoverInput: string = '';
  @Input() update: Date = new Date();
  @Input() chartSettings: ChartSettings = {} as ChartSettings;
  @Input() size: string = '0px';
  @Input() transitionDuration: number = 0;
  @Input() isSharedReport: boolean = false;
  @Input() selectionExists: boolean = false;
  @Input() filtersDemo: boolean = false;
  @Input() filtering: boolean = false;
  @Input() anonymityLock: boolean = false;
  @Input() touchDevice: boolean = false;
  @Input() anonymityTreshold: number = null;

  @Output() settingsChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() hover: EventEmitter<string> = new EventEmitter<string>();

  // Variables used in HTML template
  readonly axisLabels = [$localize`PERFORMANCE`, $localize`IMPORTANCE`];

  public responses: number = 0;
  public isDataAvailable: boolean = false;
  public loadingTextSummary$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public targetQuestionIndex: number = null;
  public strengthIndex: number = null;
  public weaknessIndex: number = null;
  public themesIndex: number = null;

  public importancies: any[] = [];

  public themes: any[] = [];
  public selectedThemes: any[] = [];
  public hoveredThemes: any[] = [];

  public insight: string[][] = [];
  public hoveredInsight: any[] = [];
  public showOversizedInsight: boolean = false;

  public tooltipPosition: [number, number] = [0, 0];
  public chartAreaSize: number = 0;
  public exportChart: boolean = false;
  public sizeUpdate: Date = new Date();

  public dataService: Crossfilter | null = this.cf;
  public whyFinderKey: string;

  private cachedInsights: { [args: string]: string[][] } = {};
  private baseMessages: { role: string; message: string }[] = [];
  private filter: any;

  get Math() {
    return Math;
  }

  @ViewChild('tooltip') tooltipEl!: ElementRef;

  constructor(
    private cdRef: ChangeDetectorRef,
    private cf: Crossfilter,
    private dc: DataConverter,
    private _element: ElementRef,
    private ra: ReportAssistant,
    readonly hooks: LifecycleHooks,
  ) {}

  ngOnInit(): void {
    const base = d3.select(this._element.nativeElement);

    this.whyFinderKey = this.details
      ?.find(
        (item) =>
          item?.originalTypeSpecifier === 'why-finder-strengths' ||
          item?.originalTypeSpecifier === 'why-finder-weaknesses',
      )
      ?.key?.split(':')[0];

    base
      .on('mousemove', (event) => {
        const pos = d3.pointer(event);

        if (this.hoveredThemes?.length) {
          const w = (this.tooltipEl && this.tooltipEl.nativeElement && this.tooltipEl.nativeElement.offsetWidth) || 0;
          const h = (this.tooltipEl && this.tooltipEl.nativeElement && this.tooltipEl.nativeElement.offsetHeight) || 0;
          const pageLimitLeft = pos[0] - event.clientX + 8;
          const difference = event.clientX - pos[0];
          const pageLimitRight = window.innerWidth - w - 16 - difference;

          this.tooltipPosition[0] = Math.max(Math.min(pos[0] - w / 2, pageLimitRight), pageLimitLeft);
          this.tooltipPosition[1] = pos[1] - h - 16;
          this.cdRef.detectChanges();
        }
      })
      .on('mouseout', () => {
        this.hoveredThemes = [];
        this.cdRef.detectChanges();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.exportChart = this.hasParentClass(this._element.nativeElement, 'export-chart');
    if (!(Object.keys(changes).length === 1 && changes.trendHoverInput && !this.trend)) {
      this.themesIndex = this.details.findIndex((item) => item.originalTypeSpecifier === 'why-finder-themes');
      this.strengthIndex = this.details.findIndex((item) => item.originalTypeSpecifier === 'why-finder-strengths');
      this.weaknessIndex = this.details.findIndex((item) => item.originalTypeSpecifier === 'why-finder-weaknesses');
      this.targetQuestionIndex = this.details.findIndex(
        (item, index) =>
          item.originalType !== Questions.AI_INTERVIEWER &&
          item.key !== 'time' &&
          (!this.comparison || this.comparison.index !== index),
      );

      this.responses =
        this.stats && this.stats[this.targetQuestionIndex] && this.stats[this.targetQuestionIndex]['responses'] != null
          ? this.stats[this.targetQuestionIndex]['responses']
          : this.totalAnswers;
      this.isDataAvailable = !!this.distributions?.[this.themesIndex]?.length;
      this.selectedThemes = this.filterInput?.[this.themesIndex]?.slice() || [];

      this.setData();

      if (
        this.themes?.length &&
        (changes.details ||
          changes.domain ||
          changes.distributions ||
          changes.stats ||
          changes.filterInput ||
          changes.comparison ||
          changes.trend ||
          changes.totalAnswers)
      ) {
        this.updateTextSummary();
      }
    }
  }

  private setData(): void {
    if (this.crossfilter) {
      this.dataService = this.crossfilter;
    } else {
      this.dataService = this.cf;
    }

    this.calculateImportances();
    this.setThemes();
  }

  private calculateImportances(): void {
    const rawData = this.dataService.getTextAnswersFor(
      [this.details[this.targetQuestionIndex], this.details[this.strengthIndex], this.details[this.weaknessIndex]],
      this.isSharedReport,
      false,
      false,
      false,
      this.filterInput[this.themesIndex]?.length ? this.details[this.themesIndex]?.key : '',
    );
    const strengths = this.details[this.strengthIndex]?.values?.map((val) => ({
      key: val,
      theme: this.details[this.strengthIndex]?.valueGroupKeys?.find((_, index) =>
        this.details[this.strengthIndex]?.valueGroupValues?.[index]?.includes(Number(val)),
      ),
    }));
    const weaknesses = this.details[this.weaknessIndex]?.values?.map((val) => ({
      key: val,
      theme: this.details[this.weaknessIndex]?.valueGroupKeys?.find((_, index) =>
        this.details[this.weaknessIndex]?.valueGroupValues?.[index]?.includes(Number(val)),
      ),
    }));

    this.importancies = this.domain[this.themesIndex]?.keys?.map((key) => ({
      key,
      values: [],
    }));
    const valueScaler: (val) => number = (val) => {
      if (this.domain[this.targetQuestionIndex]?.origin) {
        const origMin = this.domain[this.targetQuestionIndex].origin.min;
        const origMax = this.domain[this.targetQuestionIndex].origin.max;
        return ((val - origMin) * (10 - 0)) / (origMax - origMin) + 0;
      }
      return 0;
    };

    for (let i = 0, len = rawData.length; i < len; i++) {
      const row = rawData[i] || [];
      const importanceScore =
        this.details[this.targetQuestionIndex]?.originalType === Questions.SLIDER_E_NPS ||
        this.details[this.targetQuestionIndex]?.originalType === Questions.SLIDER_NPS
          ? Number(row[1]?.text)
          : valueScaler(Number(row[1]?.text));

      if (!isNaN(importanceScore) && importanceScore != null) {
        row[2]?.value?.[0].forEach((item) => {
          this.importancies?.find((t) => t.key === strengths?.[item].theme)?.values.push(importanceScore);
        });
        row[3]?.value?.[0].forEach((item) => {
          this.importancies?.find((t) => t.key === weaknesses?.[item].theme)?.values.push(10 - importanceScore);
        });
      }
    }
  }

  private setThemes(): void {
    const themesIteration1 = this.domain[this.themesIndex]?.keys?.slice()?.map((key, index) => {
      const title = key;
      const valueGroupIndex = this.details[this.strengthIndex]?.valueGroupKeys?.indexOf(key);
      const description = this.details[this.strengthIndex]?.valueGroupDescriptions?.[valueGroupIndex];
      const size = this.distributions[this.themesIndex]?.[index]?.value;
      const value =
        this.distributions[this.themesIndex]?.[index]?.customValues?.strengths -
        this.distributions[this.themesIndex]?.[index]?.customValues?.weaknesses;
      const importancies = this.importancies?.find((t) => t.key === key)?.values;
      const importance = importancies?.length ? importancies.reduce((a, b) => a + b, 0) / importancies.length : null;

      return {
        title,
        description,
        importance,
        size,
        value,
        stats: {
          strengths: this.distributions[this.themesIndex]?.[index]?.customValues?.strengths,
          weaknesses: this.distributions[this.themesIndex]?.[index]?.customValues?.weaknesses,
        },
      };
    });

    const themesIteration2 =
      themesIteration1?.map((item, index, arr) => {
        const averageX = arr.reduce((a, b) => a + Math.abs(b?.value || 0), 0) / arr.length;
        const stdX = Math.sqrt(
          arr.reduce((a, b) => a + Math.pow(Math.abs(b?.value) - averageX, 2) || 0, 0) / arr.length,
        );
        const averageY =
          arr.reduce((a, b) => a + (b?.importance || 0), 0) / arr.filter((c) => c.importance != null).length;
        const stdY = Math.sqrt(
          arr
            .filter((c) => c.importance != null)
            .reduce((a, b) => a + (Math.pow(b?.importance - averageY, 2) || 0), 0) /
            arr.filter((c) => c.importance != null).length,
        );

        const zX = stdX > 0 ? (Math.abs(item.value) - averageX) / stdX : 0;
        const zY = stdY > 0 ? (item.importance - averageY) / stdY : 0;

        const x =
          item.value !== 0 ? 50 + (item.value > 0 ? 1 : -1) * (Math.min(Math.max(zX, -1.5), 1.5) + 1.5) * (50 / 3) : 50;
        const y = (Math.min(Math.max(zY, -1.5), 1.5) + 1.5) * (100 / 3);

        return {
          ...item,
          x,
          y,
          color: y < 50 ? Colors.WHYFINDERBUBBLE[2] : x > 50 ? Colors.WHYFINDERBUBBLE[0] : Colors.WHYFINDERBUBBLE[1],
          colorText: y < 50 ? Colors.WHYFINDERTEXT[2] : x > 50 ? Colors.WHYFINDERTEXT[0] : Colors.WHYFINDERTEXT[1],
          index: index + 1,
        };
      }) || [];

    this.themes = themesIteration2;
    console.log(themesIteration2);
  }

  private updateTextSummary(forceUpdate: boolean = false): void {
    const surveyKey = this.dataService?.key;
    const isBaseSituation: boolean = !Object.keys(this.dataService?.filters || {}).length;
    const generateLabel: () => string = (): string => {
      let labelText: string = `Active filters applied. ${this.dataService.getRespondentCounts()?.selected} / ${
        this.dataService.getRespondentCounts()?.total
      } respondents selected.\n`;
      let i: number = 0;
      for (const f in this.dataService?.filters || {}) {
        const timeFilterValues = (tv, tf) =>
          tf.map((tfv) => this.dataService.getTimeFormat(tv)?.format(new Date(Number(tfv))));
        const filter = this.dataService.filters[f];
        const isQuestion: boolean = ![
          'time',
          'survey',
          'lang',
          'shareLink',
          'hashtags',
          'zefSurveyUserRating',
          'userSegments',
          'outcomes',
          'respondent-field',
        ].includes(filter?.dimensionData?.originalType);
        const isTimeFilter: boolean = filter?.dimensionData?.originalType === 'time';

        if (i > 0) {
          labelText += ',\n';
        }

        labelText += `${i + 1}. `;
        labelText += isQuestion
          ? 'Question filter: '
          : filter?.dimensionData?.originalType === 'respondent-field'
            ? 'Background information filter: '
            : 'Filter: ';
        labelText += `"${filter?.dimensionData.title}" `;
        labelText += isQuestion ? 'Selected answer(s): ' : 'Selected option(s): ';
        labelText += `"${
          !isTimeFilter ? filter?.['customName'] || filter?.labels : timeFilterValues(filter?.values, filter?.filter)
        }"`;

        i++;
      }
      return labelText;
    };
    const language: string =
      this.dataService?.getLocaleConfig()?.[this.dataService?.getActiveLocale()]?.name || 'English';
    const reportKey: string = this.dataService?.connectedReportKey || '';
    const filters = isBaseSituation
      ? `Initial state. ${this.dataService.getRespondentCounts()?.total} respondents.`
      : generateLabel();

    const args = `${filters}${language}${JSON.stringify(
      this.themes.map((item) => ({
        title: item.title,
        description: item.description,
        importance: Math.round(item.y / 10 - 5),
        performance: Math.round(item.x / 5 - 10),
        mentions: item.size,
        mentionedAsStrength: item.stats?.strengths,
        mentionedAsWeakness: item.stats?.weaknesses,
      })),
    )}`;

    const themes = this.themes.map((item) => {
      return {
        name: item.title,
        description: item.description,
        mentions: item.size,
        importance: item.y,
        performance: item.x,
        importancePerformanceBalance: item.x - item.y,
      };
    });
    const mapFn = (item, index) =>
      `Title: ${item.name} (${((item.mentions / this.dataService.getRespondentCounts()?.selected) * 100).toFixed(1)}${
        index === 0 ? '% of respondents mentioned it' : '%'
      })`;
    const priorityFn = (item) =>
      (item.mentions / this.dataService.getRespondentCounts()?.selected) * (item.importance * 2 + item.performance);

    const thematicAnalysis = `Thematic Analysis:
      Strengths:
      ${themes
        .filter((item) => item.importance >= 50 && item.performance > 50)
        .filter((item) => !this.selectedThemes.length || this.selectedThemes.includes(item.name))
        .sort((a, b) => priorityFn(b) - priorityFn(a))
        .map(mapFn)
        .join(', ')}

      Weaknesses:
      ${themes
        .filter(
          (item) =>
            (item.importance >= 50 && item.performance <= 50) ||
            (item.performance < 50 && item.performance - item.importance < -25),
        )
        .filter((item) => !this.selectedThemes.length || this.selectedThemes.includes(item.name))
        .sort((a, b) => b.importance - a.importance)
        .map(mapFn)
        .join(', ')}${
        this.selectedThemes.length
          ? `

      Low priority themes:
      ${themes
        .filter((item) => item.importance < 50 && item.performance - item.importance >= -25)
        .filter((item) => this.selectedThemes.includes(item.name))
        .sort((a, b) => b.importance - a.importance)
        .map(mapFn)
        .join(', ')}`
          : '.'
      }`;

    if (!this.cachedInsights[args]?.length || forceUpdate) {
      const npsData = this.dc.calculateNPS(this.distributions[this.targetQuestionIndex]);
      const filteredScore = () => {
        const promoterOrig = npsData?.[0]?.distribution?.find((item) => item.key === 'promoter')?.originalValues || [];
        const detractorOrig =
          npsData?.[0]?.distribution?.find((item) => item.key === 'detractor')?.originalValues || [];
        const included =
          npsData?.[0]?.originalDistribution?.filter((item) =>
            this.filterInput[this.targetQuestionIndex].includes(item.key),
          ) || [];
        const count = included.reduce((a, b) => a + b.value, 0);
        const promoter = included.filter((item) => promoterOrig.includes(item.key)).reduce((a, b) => a + b.value, 0);
        const detractor = included.filter((item) => detractorOrig.includes(item.key)).reduce((a, b) => a + b.value, 0);
        const promoterPercent = (promoter / count) * 100;
        const detractorPercent = (detractor / count) * 100;

        return Math.round(promoterPercent - detractorPercent);
      };
      const npsScore = !this.filterInput[this.targetQuestionIndex] ? npsData?.[0]?.npsScore : filteredScore();
      const npsResults: string = `The NPS was ${npsScore}.`;
      const npsEResults: string = `The eNPS was ${npsScore}.`;
      const sliderResults: string = `The average was ${this.stats[this.targetQuestionIndex]?.average || '-'} in scale ${
        this.domain[this.targetQuestionIndex]?.labelsLinear?.axis
      }: ${this.domain[this.targetQuestionIndex]?.labelsLinear?.min} (${
        this.domain[this.targetQuestionIndex]?.origin?.min
      }) - ${this.domain[this.targetQuestionIndex]?.labelsLinear?.max} (${
        this.domain[this.targetQuestionIndex]?.origin?.max
      }).`;
      const choiceResults: string = `The distribution of answers was: ${this.distributions[this.targetQuestionIndex]
        .map(
          (item, index) =>
            `${this.domain[this.targetQuestionIndex]?.labels?.[item?.key]} (${item.value}${
              index === 0 ? ' respondents' : ''
            })`,
        )
        .join(', ')}`;
      const detKey: string = this.details[this.strengthIndex].key;
      const backgroundInfo: string =
        this.dataService?.questions?.[detKey.split('/')[0]]?.find(
          (q) => q.$key === detKey.split('/')?.[1]?.split(':')?.[0],
        )?.whyFinder?.backgroundInfo || '';
      const messageContent = `
      ### Context ###
      ${filters}

      ### BaseQuestion ###
      ${this.details[this.targetQuestionIndex]?.title}

      ### BaseQuestionResults ###
      ${
        this.details[this.targetQuestionIndex]?.originalType === Questions.SLIDER_NPS
          ? npsResults
          : this.details[this.targetQuestionIndex]?.originalType === Questions.SLIDER_E_NPS
            ? npsEResults
            : this.details[this.targetQuestionIndex]?.scale === 'categorical'
              ? choiceResults
              : sliderResults
      }

      ### ThematicAnalysis ###
      ${thematicAnalysis}
      `;
      const message: { role: string; message: string } = { role: 'user', message: messageContent };

      if (isBaseSituation && this.baseMessages?.length) {
        this.baseMessages = [];
      }

      this.insight = [];

      this.loadingTextSummary$.next(true);
      this.ra
        .getWhyFinderTextSummary(surveyKey, reportKey, language, backgroundInfo, message, this.baseMessages)
        .pipe(takeUntil(this.hooks.destroy))
        .subscribe((res) => {
          let responseContent = res?.[0]?.message?.content || '';

          if (isBaseSituation) {
            this.baseMessages = [message, { role: 'assistant', message: responseContent }];
          }

          if (responseContent) {
            responseContent = this.parseInsight(responseContent);
          }

          this.cachedInsights[args] = responseContent || [];
          this.insight = this.cachedInsights[args];
          this.loadingTextSummary$.next(false);
          this.cdRef.detectChanges();
        });
    } else if (this.cachedInsights[args]) {
      this.insight = this.cachedInsights[args];
      this.cdRef.detectChanges();
    }
  }

  trackByTitle(i: number, item: any): string {
    return item?.title;
  }

  hasParentClass(element, classname): boolean {
    if (element.className && element.className.split(' ').indexOf(classname) >= 0) {
      return true;
    }

    return !!element.parentNode && this.hasParentClass(element.parentNode, classname);
  }

  callFilter() {
    if (this.filtering && !this.anonymityLock) {
      this.filter = [];
      const filterX = {
        key: this.domain[this.themesIndex].key,
        values: this.domain.keys,
        filter: this.selectedThemes,
      };

      this.filter.push(filterX);

      const filterInput = JSON.stringify([this.filterInput[this.themesIndex] || []]);
      const filter = JSON.stringify(this.filter.map((item) => item.filter));

      if (filter !== filterInput) {
        this.dataService.filter(this.filter);
      }
    }
  }

  public isNotFreezed(): boolean {
    return !(this.dataService.getTextFreezingStatus() && this.isSharedReport) && !(this.anonymityTreshold > 1);
  }

  public chartAreaResize($event) {
    this.chartAreaSize = $event.dimensions.height - 6 - 32 - 64;
    this.sizeUpdate = new Date();
  }

  public insightHover($event) {
    if ($event?.filter((item) => item[1]?.includes('insight-color')).length) {
      this.hoveredInsight = $event.filter((item) => item?.[1]?.includes('insight-color')).map((item) => item[0]);
    } else {
      this.hoveredInsight = [];
    }

    this.showOversizedInsight = !!$event;
  }

  public themeChartHover($event) {
    this.hoveredThemes = $event?.length ? $event.map((item) => this.themes.find((theme) => theme?.title === item)) : [];
  }

  public themeChartClick() {
    this.selectedThemes = this.themes
      .map((item) => item.title)
      .filter(
        (theme) =>
          (this.selectedThemes.includes(theme) && !this.hoveredThemes.some((ht) => ht?.title === theme)) ||
          (!this.selectedThemes.includes(theme) && this.hoveredThemes.some((ht) => ht?.title === theme)),
      );

    this.callFilter();
  }

  public parseInsight(content: string) {
    let colorizedContent = content;
    const separator: string = '\u001D';
    const classSeparator: string = '\u001C';

    for (const theme of this.themes) {
      colorizedContent = colorizedContent.replace(
        new RegExp(theme.title, 'ig'),
        separator +
          theme.title +
          classSeparator +
          `insight-color-${
            theme.colorText === Colors.WHYFINDERTEXT[0]
              ? 'success'
              : theme.colorText === Colors.WHYFINDERTEXT[1]
                ? 'warning'
                : 'neutral'
          }` +
          separator,
      );
    }

    return colorizedContent.split(separator).map((item) => item.split(classSeparator));
  }
}
