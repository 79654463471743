/**
 * Contacts export and download dialog.
 *
 * @unstable
 */

import { Observable, of } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';

import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { Select, Store } from '@ngxs/store';

import { SurveyData, SurveyModel } from '@shared/models/survey.model';
import { ExportItem, ExportSettings } from '@shared/models/report.model';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { SurveyState } from '@shared/states/survey.state';
import { BillingState } from '@shared/states/billing.state';
import { LicenseFeature } from '@shared/enums/license-feature.enum';

import { Exports } from '@shared/enums/exports.enum';

import { ExportService } from '@report/shared/services/export.service';
import { UpdateReportExportSettings } from '@shared/states/prefs.actions';
import { PrefsState } from '@shared/states/prefs.state';
import { GetDesign } from '@shared/states/survey.actions';
import { ReportState } from '@shared/states/report.state';
import { getLastValue } from '@shared/operators/share-ref.operator';

@Component({
  selector: 'report-export',
  templateUrl: './report-export.dialog.html',
  styleUrls: ['./report-export.dialog.scss'],
  providers: [LifecycleHooks],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportExport implements OnInit {
  public exportTypes = Exports;

  public sampleData: boolean = false;

  public exports: ExportItem[] = [];

  public exportType: string = 'raw';
  public exportFormat: string = 'CSV';

  public exportOnProgress: boolean = false;
  public formatChange: boolean = false;
  public typeChange: boolean = false;
  public includeComments: boolean = false;
  public includeDatatables: boolean = false;

  public selectedExportTab: number | null = null;
  public selectedExportTypeIndex: number | null = null;

  public showExportTypeList: boolean = false;
  public showAdvancedOptions: boolean = false;

  public reportName: string = '';

  public anonymityTreshold: number = null;

  proExportTypes = ['PPTX', 'DOCX', 'XLSX', 'PDF'];
  dialogTitle = $localize`:dialog title@@zef-i18n-00276:Export report`;

  private isDefaultExportType: boolean = true;

  public survey$: Observable<SurveyModel>;

  @Select(BillingState.featureActive(LicenseFeature.DataExporting))
  canExport$: Observable<boolean>;

  @Select(PrefsState.reportExportSettings)
  readonly reportExportSettings$!: Observable<ExportSettings>;

  readonly canExport$$: Observable<boolean>;

  @ViewChild('dialog') dialogRef: MatDialogRef<ReportExport> | undefined;

  formatsList$ = this.store
    .select(ReportState.isPrivateReport)
    .pipe(map((isPrivateReport) => (isPrivateReport ? Exports.ExportPrivate : Exports.ExportAll)));

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: any,
    readonly cdRef: ChangeDetectorRef,
    readonly es: ExportService,
    readonly store: Store,
    readonly hooks: LifecycleHooks,
    private router: Router,
  ) {
    this.canExport$$ = this.canExport$.pipe(
      tap((can) => {
        if (this.isDefaultExportType) {
          this.selectedExportTypeIndex = can
            ? this.selectedExportTypeIndex || 0
            : Exports.ExportAll.indexOf(Exports.CSV);
          this.isDefaultExportType = false;
        }
      }),
    );
  }

  ngOnInit() {
    if (!this.store.selectSnapshot(SurveyState.design(this.data.surveyKey))) {
      this.store.dispatch(new GetDesign(this.data.surveyKey));
    }

    this.survey$ = this.store.select(SurveyState.activeSurvey(this.data.surveyKey));

    this.exports = [];
    this.sampleData = this.data.sampleData;

    this.anonymityTreshold = this.data.cf.getAnonymityTreshold();

    if (this.anonymityTreshold) {
      this.exportType = 'summary';
    }

    const surveyData = getLastValue(this.survey$)?.survey;
    const isPrivateReport = this.store.selectSnapshot(ReportState.isPrivateReport);
    this.reportName = this.data.reportData.title || (!isPrivateReport && surveyData?.name ? surveyData.name : '');

    this.reportExportSettings$.pipe(takeUntil(this.hooks.destroy)).subscribe((exportSettings) => {
      this.includeComments = !!exportSettings?.comments;
      this.includeDatatables = !!exportSettings?.datatables;
      this.cdRef.detectChanges();
    });

    this.es
      .listExports(this.data.surveyKey)
      .pipe(
        takeUntil(this.hooks.destroy),
        catchError(() => of(null)),
      )
      .subscribe((exports) => {
        this.exportOnProgress = false;
        this.exports = [];

        if (exports) {
          exports.forEach((e) => {
            if (e.payload.val()) {
              this.formatChange = false;
              this.typeChange = false;
              const item = { $key: e.key, ...(e.payload.val() as ExportItem) };
              this.exports.push(item);
              if (item.format) {
                this.selectedExportTypeIndex = Exports.ExportAll.indexOf(item.format);
              }
              if (item.type && Exports.ExportRaw.indexOf(Exports.ExportAll[this.selectedExportTypeIndex]) >= 0) {
                this.exportType = item.type;
              }
            }
          });
        }
        this.cdRef.detectChanges();
      });
  }

  onCreateExport(surveyData: SurveyData) {
    this.exportOnProgress = true;
    this.formatChange = false;

    const isPrivateReport = this.store.selectSnapshot(ReportState.isPrivateReport);
    const reportKey = this.data.reportData.$key;
    const surveyKey = surveyData.$key;
    const exportName =
      this.reportName ||
      `Export ${new Date().getUTCDate()}.${new Date().getUTCMonth() + 1}.${new Date().getFullYear()}`;

    const respondentCount: number = !this.data.cf.getAnswerData()?.sampleData
      ? this.data.cf.getAnswerData()?.answers?.length || 0
      : 0;

    if (this.exports.length > 0) {
      this.formatChange = true;
      for (let e = 0, len = this.exports.length; e < len; e++) {
        this.onDeleteExport(this.exports[e]);
      }
    }

    this.data.cm.imageSubj.next(Exports.ExportAll[this.selectedExportTypeIndex]);
    if (Exports.ExportRaw.indexOf(Exports.ExportAll[this.selectedExportTypeIndex]) >= 0 && this.exportType === 'raw') {
      this.es.createRaw(
        surveyKey,
        Exports.ExportAll[this.selectedExportTypeIndex],
        exportName,
        this.data.cf.getDimensions(),
        surveyData?.resultsOptions?.score,
        respondentCount,
        this.data.cf.getTextContacts(),
      );
      this.data.cm.imageSubj.next('');
    } else {
      setTimeout(() => {
        this.es.create(
          this.data.cm,
          exportName,
          Exports.ExportAll[this.selectedExportTypeIndex],
          surveyKey,
          this.sampleData,
          respondentCount,
          this.includeComments,
          this.includeDatatables,
          isPrivateReport ? reportKey : null,
          isPrivateReport && this.data.cf.getExtraRespondentFieldAnonymityStatus(),
          this.data.cf.getExtraHashtagShareLinkAnonymityStatus(),
        );
        this.data.cm.imageSubj.next('');
        this.store.dispatch(
          new UpdateReportExportSettings({ comments: this.includeComments, datatables: this.includeDatatables }),
        );
      }, 3500); // Wait for images to be updated... To be fixed later...
    }
  }

  onDownloadExport(exportInfo: ExportItem, surveyKey: string) {
    this.typeChange = false;
    this.formatChange = false;

    this.es.download(exportInfo['$key'], surveyKey, exportInfo.downloadToken);
  }

  onDeleteExport(exportInfo: ExportItem) {
    if (exportInfo['$key']) {
      this.es.deleteExport(exportInfo['$key']);
    }
  }

  onExportTabChange() {}

  onExportTypeChange($event) {
    if (this.exports && this.exports[0] && this.exports[0]['type'] !== $event.value) {
      this.typeChange = true;
    } else {
      this.typeChange = false;
    }
  }

  onShowExportTypes() {
    this.showExportTypeList = true;
    this.cdRef.detectChanges();
  }

  onShowAdvancedOptions() {
    this.showAdvancedOptions = true;
    this.cdRef.detectChanges();
  }

  onClickLink() {
    this.dialogRef.close();
    this.router.navigate(['/plans']);
  }
}
