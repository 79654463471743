import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { Store } from '@ngxs/store';

import { ActivatedRoute, Router } from '@angular/router';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { CdkDragEnter, CdkDropList } from '@angular/cdk/drag-drop';

import { Panel } from '@shared/components/panel/panel.component';

import { GridItem, GridItemDataItem } from '@shared/models/report.model';
import { ReportData, SurveyData } from '@shared/models/survey.model';

import { Questions } from '@shared/enums/questions.enum';
import { Rights } from '@shared/enums/rights.enum';

import { fromQuery } from '@shared/operators/from-query.operator';

import { ReportManager } from '@shared/services/report-manager.service';
import { DialogControl } from '@shared/services/dialog-control.service';

import { SurveyState } from '@shared/states/survey.state';
import { DisplaySnackbar } from '@shared/states/dialog.actions';

import { Crossfilter } from '@report/shared/services/crossfilter.service';
import { ReportSave } from '@report/shared/services/report-save.service';

import { ChartsManager } from '@report/shared/services/charts-manager.service';

import { Calculations } from '@report/shared/enums/calculations.enum';

import { FullscreenChart } from '@report/+charts/fullscreen-chart/fullscreen-chart.dialog';
import { ChartCard } from '@report/+charts/chart-card/chart-card.component';

import { isField } from '@report/shared/utilities/report.utilities';

/**
 * This is charts view component which converts grid object to actual ng-grid and pushes data to chart card component.
 */
@Component({
  selector: 'charts-view',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartsView implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() scrollState: number[] = [];
  @Input() userRights: number = 0;
  @Input() surveyRights: number = 0;
  @Input() touchDevice: boolean = false;
  @Input() isSharedReport: boolean = false;
  @Input() filtersDemo: boolean = false;
  @Output() scrollTo: EventEmitter<number> = new EventEmitter<number>();

  readonly Rights = Rights;
  readonly questionTypes = Questions;

  public surveyData: Observable<SurveyData> | null = null;

  public originalGrid: GridItem[] = [];
  public visibilityGrid: GridItem[] = [];
  public grid: GridItem[] = [];

  public builder: boolean = false;
  public builderDimensions: any;

  public chartRemoval: number | null = null;

  public selectedReportData: ReportData = new ReportData();
  public selectedSurvey: string = '';
  public selectedReport: string = '';

  public gridSize: number = 0;

  public onResize: boolean = false;
  public explorable: boolean = true;

  public visibleArea: number[] = [];

  public sampleData: boolean = false;
  public mobileView: boolean = false;
  // private saveDialogDetails: any = null;

  public searchTerm: string = '';
  public searchList: { title: string; key: string }[] = [];
  public searchUpdate: Subject<string> = new Subject<string>();

  public width: number = 0;

  private cmSub: any;
  private cfSub: any;
  private searchSub: any;

  public drops: CdkDropList[];

  public resizerOn: string = '';
  public resizedWidth: string = '';
  public resizedHeight: string = '';

  public dragOn: string = '';

  public previewWidth: string = '';
  public previewHeight: string = '';

  public previewCursorLeft: string = '';
  public previewCursorTop: string = '';

  public placeholderSizeY: number | null = null;

  public trendHoverValue: string = '';

  public toggleUsed: boolean = false;

  @ViewChildren(CdkDropList) dropsQuery: QueryList<CdkDropList>;
  @ViewChildren(ChartCard) chartCards: QueryList<ChartCard>;
  @ViewChild('showHidePanel') showHidePanel: Panel;

  constructor(
    readonly store: Store,
    public cm: ChartsManager,
    private cf: Crossfilter,
    private _element: ElementRef,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private rm: ReportManager,
    private rs: ReportSave,
    readonly dc: DialogControl,
  ) {
    const data = this.route.snapshot.data;

    this.surveyData = data.survey;

    const params = this.route.snapshot.params;

    this.selectedReportData.title = '';

    this.selectedSurvey = params['survey'];
    this.selectedReport = params['report'];

    if (data.reports) {
      data.reports.forEach((snapshot) => {
        if (snapshot !== null) {
          for (const item of snapshot) {
            if (item['$key'] === this.selectedReport) {
              this.selectedReportData = item;
            }
          }
        }
      });
    }
  }

  ngOnInit() {
    this.cmSub = this.cm.gridSubj.subscribe((grid) => {
      if (grid) {
        this.originalGrid = grid;
        this.grid = this.originalGrid.filter((item) => !item.hided);
        this.visibilityGrid = this.originalGrid.filter((item) => !item.isNotesCard);
        this.cdRef.detectChanges();
      }

      this.gridSize = grid.length;
    });

    this.cfSub = this.cf.crossfilter.subscribe((crossfilter) => {
      if (this.cf.getPublicSettings() != null && this.cf.getPublicSettings().explorable === false) {
        this.explorable = false;
      }
      if (crossfilter) {
        this.sampleData = crossfilter.stats.sampleData;
      }
    });

    this.searchUpdate.pipe(debounceTime(150), distinctUntilChanged()).subscribe((term) => {
      this.searchTerm = term;
      this.searchList = this.grid
        .map((item) => ({ title: item.title || '', key: item.key }))
        .filter((item) => item.title.toLowerCase().indexOf(term.toLowerCase()) > -1);
      this.cdRef.detectChanges();
    });
  }

  ngAfterViewInit() {
    this.dropsQuery.changes.subscribe(() => {
      this.drops = this.dropsQuery.toArray();
      this.cdRef.markForCheck();
      this.cdRef.detectChanges();
    });
    Promise.resolve().then(() => {
      this.drops = this.dropsQuery.toArray();
    });
  }

  ngOnChanges() {
    this.visibleArea = this.scrollState;
  }

  ngOnDestroy() {
    if (this.cmSub) {
      this.cmSub.unsubscribe();
    }

    if (this.cfSub) {
      this.cfSub.unsubscribe();
    }

    if (this.searchSub) {
      this.searchSub.unsubscribe();
    }
  }

  public dropListEnter($event: CdkDragEnter) {
    let indexCorrection: number = 0;

    if ($event.container.data > $event.item.data) {
      if (this.grid[$event.item.data]['gridSettings']['sizex'] === 2) {
        // This fill fix situation where one is dragging 2 column chart downwards and chart below is 1 column chart
        if (
          $event.container.data - $event.item.data === 1 &&
          this.grid[$event.container.data]['gridSettings']['sizex'] === 1 &&
          this.grid[$event.container.data + 1] &&
          this.grid[$event.container.data + 1]['gridSettings']['sizex'] === 1
        ) {
          indexCorrection--;
        }
      } else {
        // This will fix situation where one is dragging item over 2 column chart
        for (let i = $event.item.data, len = $event.container.data; i < len; i++) {
          if (this.grid[i]['gridSettings'] && this.grid[i]['gridSettings']['sizex'] === 2) {
            indexCorrection++;
          }
        }
      }

      // This will fix situation where one is dragging item downwards when dragged item is bigger than item on right
      if (indexCorrection === 0 && $event.container.data - $event.item.data === 3) {
        const dragEl: HTMLElement = document.getElementById(`grid-item-${this.grid[$event.item.data]['key']}`);
        const nextEl: HTMLElement = document.getElementById(`grid-item-${this.grid[$event.item.data + 1]['key']}`);

        if (
          dragEl &&
          nextEl &&
          dragEl.offsetLeft < nextEl.offsetLeft &&
          dragEl.offsetTop + dragEl.offsetHeight > nextEl.offsetTop + nextEl.offsetHeight
        ) {
          indexCorrection++;
        }
      }

      // This will fix situation where one is dragging item downwards when dragged item is bigger than next element
      // and items wouldn't be on their natural places because of item on left is bigger than next element
      if (indexCorrection === 0 && $event.container.data - $event.item.data === 2) {
        const prevLeftEl: HTMLElement = document.getElementById(`grid-item-${this.grid[$event.item.data - 1]['key']}`);
        const dragEl: HTMLElement = document.getElementById(`grid-item-${this.grid[$event.item.data]['key']}`);
        const nextEl: HTMLElement = document.getElementById(`grid-item-${this.grid[$event.item.data + 1]['key']}`);

        if (
          prevLeftEl &&
          dragEl &&
          nextEl &&
          dragEl.offsetLeft > nextEl.offsetLeft &&
          prevLeftEl.offsetTop + prevLeftEl.offsetHeight > dragEl.offsetTop + nextEl.offsetHeight
        ) {
          indexCorrection++;
        }
      }
    }

    // This will fix situation where one is dragging item upwards when dragged item is smaller than target element
    // and thus mouse cursor would end up on top of wrong element
    if (
      $event.container.data < $event.item.data &&
      this.grid[$event.container.data]['gridSettings']['sizey'] > this.grid[$event.item.data]['gridSettings']['sizey']
    ) {
      const dragEl: HTMLElement = document.getElementById(`grid-item-${this.grid[$event.item.data]['key']}`);
      const targetEl: HTMLElement = document.getElementById(`grid-item-${this.grid[$event.container.data]['key']}`);

      if (dragEl.offsetTop > targetEl.offsetTop) {
        this.placeholderSizeY = this.grid[$event.container.data]['gridSettings']['sizey'];
      } else {
        this.placeholderSizeY = null;
      }
    } else if ($event.container.data === $event.item.data) {
    } else {
      this.placeholderSizeY = null;
    }

    this.cm.changeOrder(
      this.grid[$event.item.data]['gridId'],
      this.grid[$event.container.data]['gridId'] - indexCorrection,
    );
    this.cm.gridChange.next('Reorganized charts');

    this.cdRef.markForCheck();
    this.cdRef.detectChanges();
  }

  public dragStarted(item: string) {
    this.dragOn = item;
    this.previewWidth = document.getElementById(`grid-item-${item}`)['offsetWidth'] + 'px';
    this.previewHeight = document.getElementById(`grid-item-${item}`)['offsetHeight'] + 'px';
  }

  public resizeStartedMousePosition($event) {
    const childItem: Element = document.getElementsByClassName('report-card')[0];
    const paddingLeft: string = window.getComputedStyle(childItem, null).getPropertyValue('padding');
    const paddingTop: string = window.getComputedStyle(childItem, null).getPropertyValue('padding-top');
    this.previewCursorLeft = -($event.offsetX + Number(paddingLeft.replace(/[^0-9]/g, ''))) + 'px';
    this.previewCursorTop = -($event.offsetY + Number(paddingTop.replace(/[^0-9]/g, ''))) + 'px';
  }

  public trackByKey(i: number, item: GridItem): string {
    return item.key;
  }

  public resizeGridItem($event) {
    if (this.resizerOn) {
      // Removing text selection from all elements when resizing
      window.getSelection().removeAllRanges();

      // resizing visual helper resizer box
      const element: HTMLElement = document.getElementById(`grid-item-${this.resizerOn}`);
      const container: HTMLElement = document.getElementById(`report-grid-container`);
      const scrollState: number = this.scrollState[3] || 0;
      const reportContainerOffset: number = this.scrollState[2] || 0;
      const maxWidth: number = container.offsetWidth / (element.offsetLeft > 0 ? 2 : 1);

      const resizedWidth: number = Math.min($event.clientX - container.offsetLeft - element.offsetLeft, maxWidth);
      const resizedHeight: number =
        $event.clientY + scrollState - reportContainerOffset - container.offsetTop - element.offsetTop;

      this.resizedWidth = resizedWidth + 'px';
      this.resizedHeight = resizedHeight + 'px';

      // Firing actual resize when treshold surpassed
      const gridItem: GridItem = this.grid.find((item) => item.key === this.resizerOn);

      if (gridItem) {
        const resizedGridWidth: number = resizedWidth > container.offsetWidth * 0.75 ? 2 : 1;
        const resizedGridHeight: number = Calculations.gridSizeFromHeightRound(resizedHeight);

        if (resizedGridWidth !== gridItem['gridSettings']['sizex']) {
          gridItem['gridSettings']['sizex'] = resizedGridWidth;
          this.cm.gridChange.next('Resized chart');
        }
        if (resizedGridHeight !== gridItem['gridSettings']['sizey'] && resizedGridHeight > 3) {
          gridItem['gridSettings']['sizey'] = resizedGridHeight;
          this.cm.gridChange.next('Resized chart');
        }
      }
    }
  }

  public resetMouseEvents() {
    this.resizerOn = '';
    this.resizedWidth = '';
    this.resizedHeight = '';
    this.dragOn = '';
    this.placeholderSizeY = null;
  }

  public openBuilder(dimension) {
    this.builderDimensions = dimension;

    if (this.cf?.getAnonymityTreshold() > 1) {
      this.removeFilters();
    }

    this.builder = true;
  }

  public duplicateChart(gridItem) {
    const key: string = this.cf.generateId();
    const newGridItem: GridItem = JSON.parse(JSON.stringify(gridItem));
    newGridItem.gridId = this.originalGrid.length;
    const dimensions: string[] = [];

    for (const details of newGridItem?.data?.details || []) {
      if (details.key) {
        dimensions.push(details.key);
      }
    }

    const cfGridItem: GridItemDataItem = this.cf.gridItems[gridItem.key]
      ? JSON.parse(JSON.stringify(this.cf.gridItems[gridItem.key]))
      : this.cf.gridItemGenerate(dimensions);

    this.cf.gridItems[key] = cfGridItem;

    newGridItem.key = key;
    newGridItem.isCustomCard = true;
    newGridItem.gridSettings.row = gridItem.gridSettings.row + 1;
    this.cm.duplicateGridItem(newGridItem, gridItem.key);
  }

  public showBuilder(status) {
    if (status === false) {
      this.builderDimensions = '';
    } else if (this.cf?.getAnonymityTreshold() > 1) {
      this.removeFilters();
    }
    this.builder = status;
  }

  public removeChart($event) {
    const card: GridItem = this.originalGrid.find((item) => item?.key === $event);

    if (card != null) {
      if (card.isCustomCard) {
        this.openRemoveChartDialog(card);
      }
    }
  }

  public hideChart($event) {
    const card: GridItem = this.originalGrid.find((item) => item?.key === $event);
    if (card != null) {
      this.onToggleChart(card);

      this.store.dispatch(
        new DisplaySnackbar($localize`Chart hidden`, {
          color: 'primary',
          actionCallback: () => this.showHidePanel.openPanel(),
          actionName: $localize`Show / Hide`,
        }),
      );
    }
  }

  // saveDialog(action) {
  //   this.saveDialogDetails = action;
  //
  //   const lang = this.store.selectSnapshot((state: AppState) => state.prefs.language);
  //
  //   const title = this.selectedReportData.$key ?
  //     (lang !== 'fi' ? 'Save Changes?' : 'Tallenna muutokset?') :
  //     (lang !== 'fi' ? 'Save Report?' : 'Tallenna raportti?');
  //
  //   const header = this.selectedReportData.$key ?
  //     (lang !== 'fi' ?
  //       'Save changes before pinning chart.' :
  //       'Tallenne muutokset ennen kaavion kiinnitystä etusivulle.') :
  //     (lang !== 'fi' ?
  //       'Save report to be a reference for the pinned chart.' :
  //       'Tallenna raportti pohjaksi etusivulle kinnitetylle kaaviolle.'
  //     );
  //
  //   this.dc.openSurveyDialog(
  //     'input',
  //     title,
  //     header,
  //     (lang !== 'fi') ? 'Name the report...' : 'Nimeä raportti...',
  //     (lang !== 'fi') ? 'Save & Pin' : 'Tallenna & kiinnitä',
  //     this.selectedReportData.title || ''
  //   ).afterClosed().subscribe((result: ActionValue) => {
  //     if (result && result.value &&
  //        (result.action === 'Save & Pin' || result.action === 'Tallenna & kiinnitä'))
  //     {
  //       this.selectedReportData.title = result.value;
  //
  //       this.cm.closeMenusSubj.next(true);
  //       this.cm.updateSubj.next(true);
  //
  //       setTimeout(() => {
  //         this.rs.save(
  //           this.cf,
  //           this.cm,
  //           this.selectedSurvey,
  //           this.selectedReportData.$key,
  //           this.selectedReportData.title
  //         ).subscribe((res) => {
  //           this.cm.closeMenusSubj.next(false);
  //           this.cm.updateSubj.next(false);
  //
  //           const data = this.grid.find(item => item.key === this.saveDialogDetails.key);
  //           this.rm.pin(this.selectedSurvey,
  //             res ? res : this.selectedReportData['$key'],
  //             this.saveDialogDetails.key,
  //             this.saveDialogDetails.action,
  //             data
  //           );
  //           this.saveDialogDetails = null;
  //           if (res) {
  //             this.router.navigate([`/report/${this.selectedSurvey}/${res}`]);
  //           } else {
  //             this.cdRef.detectChanges();
  //           }
  //         });
  //       }, 0);
  //     } else {
  //       this.saveDialogDetails = null;
  //     }
  //   });
  // }

  public pin($event: { key: string; action: any }) {
    const data = this.grid.find((item) => item.key === $event.key);

    if (!this.selectedReportData || !this.selectedReportData['$key']) {
      this.cm.closeMenusSubj.next(true);
      this.cm.updateSubj.next(true);

      setTimeout(() => {
        this.rs.save(this.cf, this.cm, this.selectedSurvey).subscribe((res) => {
          this.cm.closeMenusSubj.next(false);
          this.cm.updateSubj.next(false);

          this.rm.pin(this.selectedSurvey, res, $event.key, $event.action, data);

          if (res) {
            this.router.navigate([`/surveys/${this.selectedSurvey}/analyze/${res}`]);
          } else {
            this.cdRef.detectChanges();
          }
        });
      }, 0);
    } else {
      this.rm.pin(this.selectedSurvey, this.selectedReportData['$key'], $event.key, $event.action, data);
    }
  }

  public openRemoveChartDialog(card) {
    this.chartRemoval = this.grid.findIndex((item) => item?.key === card.key);

    let title = (this.grid[this.chartRemoval] || ({} as any)).title || '';

    title = title.length > 300 ? title.slice(0, 300) + '...' : title;

    const survey = this.store.selectSnapshot(SurveyState.activeSurvey());

    this.dc
      .openSurveyDialog(
        survey,
        'ask',
        $localize`:dialog title@@zef-i18n-00124:Confirm Remove`,
        $localize`:dialog header@@zef-i18n-00283:Remove card ”${title}:INTERPOLATION:”?`,
        $localize`:dialog content@@zef-i18n-00284:Tip: You can always re-create card with ”Add new card” feature.`,
        $localize`:dialog button label@@zef-i18n-00127:Yes, Remove`,
      )
      .afterClosed()
      .subscribe((action: string) => {
        if (action === 'Yes, Remove' || action === 'Kyllä, Poista') {
          if (card != null) {
            this.cm.removeGridItem(card);
          }

          this.chartRemoval = null;
        } else {
          this.chartRemoval = null;
        }
      });
  }

  public openFullscreenDialog(id: number) {
    const events: EventEmitter<any> = new EventEmitter<any>();
    const subscription = events.pipe().subscribe((event) => {
      this[event.action](event.content);
      if (event.action === 'openBuilder') {
        this.cdRef.detectChanges();
      }
    });
    const originalAtRiskStatuses = fromQuery(this.chartCards).pipe(
      map((cards) => cards.reduce((a, v) => ({ ...a, [v?.settings?.gridId]: v?.originalAtRiskStatus }), {})),
    );
    const originalNonSummaryStatuses = fromQuery(this.chartCards).pipe(
      map((cards) => cards.reduce((a, v) => ({ ...a, [v?.settings?.gridId]: v?.originalNonSummaryStatus }), {})),
    );

    this.dc
      .open(
        FullscreenChart,
        {
          data: {
            charts: this.cm.gridSubj,
            chart: id,
            explorable: this.explorable,
            userRights: this.userRights,
            surveyRights: this.surveyRights,
            sampleData: this.sampleData,
            events,
            isSharedReport: this.isSharedReport,
            cm: this.cm,
            cf: this.cf,
            originalNonSummaryStatuses,
            originalAtRiskStatuses,
          },
        },
        {
          width: '90%',
          height: '90%',
          maxHeight: '100%',
          maxWidth: '90%',
        },
      )
      .afterClosed()
      .subscribe(() => {
        subscription.unsubscribe();
        // Update charts somehow
      });
  }

  public gridItemChange($event, col, row, sizex, sizey) {
    if ($event.col !== col || $event.row !== row) {
      this.cm.gridChange.next('Reorganized charts');
    }
    if ($event.sizex !== sizex || $event.sizey !== sizey) {
      this.cm.gridChange.next('Resized chart');
    }
  }

  public goTo(chartKey) {
    this.searchTerm = '';
    this.cdRef.detectChanges();
    const chart: GridItem = this.grid.find((item) => item.key === chartKey);

    if (chart) {
      const positionY: number = chart
        ? document.getElementById(`grid-item-${chart.key}`).offsetTop +
          document.getElementById(`report-grid-container`).offsetTop -
          16
        : 0;

      this.scrollTo.emit(positionY);
    }
  }

  public getQuestionIcon(item) {
    if (item.isGroup && item.key !== 'outcome') {
      return 'editor_question_group';
    } else if (item.isSubheader) {
      return 'heading';
    } else if (item.isInsightsCard) {
      return 'highlight_ai';
    } else if (isField(item.key)) {
      return 'property';
    } else if (item.data && item.data.details && item.data.details.length > 0) {
      return item.key === 'time'
        ? 'time'
        : item.key === 'hashtags'
          ? 'hash'
          : item.key === 'lang'
            ? 'flag'
            : item.key === 'shareLink'
              ? 'link'
              : item.key === 'zefSurveyUserRating'
                ? 'player_thumbs_up'
                : item.key === 'survey'
                  ? 'survey'
                  : item.key === 'outcome'
                    ? 'editor_star_empty'
                    : item.key === 'responseRates'
                      ? 'funnel'
                      : Questions.QuestionIcons[item.data.details[0].originalType];
    } else {
      return null;
    }
  }

  public belongsGroup(item) {
    return (
      !item.isGroup && item.data && item.data.details && item.data.details.length > 0 && item.data.details[0].group
    );
  }

  public onToggleChart(item) {
    if (item.isSubheader || item.isNotesCard || item.isInsightsCard) {
      const index = this.cm.grid?.findIndex((cmItem) => cmItem?.gridId === item.gridId);
      if (index >= 0) {
        this.cm.grid[index].hided = !item.hided;
        this.cm.gridSubj.next(this.cm.grid);
      }
    } else {
      this.cf.toggleGridItem(item.key);
    }

    this.toggleUsed = true;
  }

  public resizeArea($event) {
    this.width = document.documentElement.clientWidth;
    if ($event.dimensions.width < 580) {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
  }

  public trendHover($event) {
    this.trendHoverValue = $event;
    this.cdRef.detectChanges();
  }

  public hasGroups() {
    for (let i = 0, len = this.visibilityGrid.length; i < len; i++) {
      if (this.visibilityGrid[i]?.isGroup) {
        return false;
      }
    }

    return true;
  }

  public showAllCharts() {
    for (let i = 0, len = this.visibilityGrid.length; i < len; i++) {
      const item = this.visibilityGrid[i];

      if (this.cm.grid[item.gridId].hided) {
        this.onToggleChart(item);
      }
    }
  }

  public showCompactView() {
    for (let i = 0, len = this.visibilityGrid.length; i < len; i++) {
      const item = this.visibilityGrid[i];
      const condition = item.isGroup || item.key === 'time' || item.key === 'Answers_header';

      if (condition) {
        if (this.cm.grid[item.gridId].hided) {
          this.onToggleChart(item);
        }
      } else if (!this.cm.grid[item.gridId].hided) {
        this.onToggleChart(item);
      }
    }
  }

  public hideEmptyCharts() {
    for (let i = 0, len = this.visibilityGrid.length; i < len; i++) {
      const item = this.visibilityGrid[i];
      const isData =
        this.cm.grid[item.gridId]['data'] &&
        this.cm.grid[item.gridId]['data']['totalAnswers'] &&
        this.cm.grid[item.gridId]['data']['totalAnswers'].findIndex((count) => count > 0) >= 0;
      const isResponseRates = this.cm.grid[item.gridId]['data']['details']?.some(
        (data) => data.originalType === 'response-rates',
      );

      if (!item.isSubheader && !this.cm.grid[item.gridId].hided && !isData && !isResponseRates) {
        this.onToggleChart(item);
      }
    }
  }

  public hideContactCharts() {
    for (let i = 0, len = this.visibilityGrid.length; i < len; i++) {
      const item = this.visibilityGrid[i];
      const condition =
        (item &&
          item.data &&
          item.data.details &&
          item.data.details.find(
            (det) =>
              det &&
              det.originalType &&
              (det.originalType.indexOf('contact-property') >= 0 || det.originalType.indexOf('respondent-field') >= 0),
          )) ||
        (item.isSubheader && item.key === 'Contacts_header');

      if (condition && !this.cm.grid[item.gridId].hided) {
        this.onToggleChart(item);
      }
    }
  }

  public hideAllCharts() {
    for (let i = 0, len = this.visibilityGrid.length; i < len; i++) {
      const item = this.visibilityGrid[i];

      if (!this.cm.grid[item.gridId].hided) {
        this.onToggleChart(item);
      }
    }
  }

  public itemClass(item) {
    const isMetric =
      ['time', 'hashtags', 'lang', 'shareLink', 'zefSurveyUserRating', 'survey', 'responseRates'].indexOf(item.key) >=
      0;
    const isContact = isField(item.key);

    const type = isMetric
      ? 'metric'
      : isContact
        ? 'contact'
        : item.isGroup && item.key !== 'outcome'
          ? 'group_cards'
          : item.data && item.data.details && item.data.details.length > 0
            ? item.data.details[0].originalType
            : '';
    return `item-${type}`;
  }

  public updateQuestionOrder($event) {
    const key = $event?.item?.data?.key;

    this.cm.changeOrder($event.previousIndex, $event.currentIndex);
    this.cm.gridChange.next('Reorganized charts');

    this.cdRef.markForCheck();
    this.cdRef.detectChanges();

    if (key) {
      this.goTo(key);
    }
  }

  public removeFilters() {
    const removedFilters: any[] = [];

    for (const f in this.cf?.filters || {}) {
      removedFilters.push({ key: f, values: this.cf.filters[f].values, filter: [] });
    }

    if (removedFilters.length > 0) {
      this.cf.filter(removedFilters);
    }
  }

  public regenerateInsights() {
    this.cf.regenerateInsights();
  }
}
