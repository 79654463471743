// TODO: Cleanup

import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { SliderValuesData } from '@shared/models/survey.model';
import {
  ChartContent,
  ChartDistribution,
  ChartDomain,
  DistributionData,
  GridItemDataItem,
  NPSData,
  NPSDistribution,
} from '@shared/models/report.model';

import { Charts } from '@shared/enums/charts.enum';
import { Colors } from '@report/shared/enums/colors.enum';
import { Questions } from '@shared/enums/questions.enum';

/**
 * This is a data converter service which converts data to a proper format needed for the charts.
 */
@Injectable({
  providedIn: 'root',
})
export class DataConverter {
  private domain: ChartDomain[] = [];
  private distribution: ChartDistribution[][] = [];
  private stats: any;

  constructor() {}

  BasicConverter(data: GridItemDataItem) {
    this.setDomain(data.details, data.indexes, data.comparison, data.activeLocale || '', data.timePeriod);
    this.setDistribution(data.distributions, data.details, data.stats, data.totalAnswers, data.comparison);
    this.setStats(data.stats);

    const content: ChartContent = {
      comparison: data.comparison !== undefined ? data.comparison : null,
      trend: data.trend != null ? data.trend : null,
      domain: this.domain,
      distributions: this.distribution,
      stats: data.stats,
      filters: data.filters,
      filtersAll: Object.assign({}, data.filtersAll),
      details: data.details,
      scales: data.scales,
      timestamp: new Date(),
      totalAnswers: data.totalAnswers,
      totalCrosstabAnswers: data.totalCrosstabAnswers != null ? data.totalCrosstabAnswers : null,
    };

    return content;
  }

  setDomain(domains, indexes, comparison, activeLocale, timePeriod) {
    this.domain = [];
    for (let d = 0, len = domains.length; d < len; d++) {
      const comp = comparison != null && domains[d].key === comparison.key && len > 1 ? comparison : null;
      this.setDomainContents(domains[d], indexes[d], d, comp, activeLocale, timePeriod);
    }
  }

  setLinearClasses(scale) {
    const min = !scale || scale.min == null ? 0 : scale.min;
    const max = !scale || scale.max == null ? 100 : scale.max;
    const step = !scale || scale.step == null ? 1 : scale.step;

    const originalClasses = (max + step - min) / step;

    if (originalClasses === 101) {
      return 11;
    } else if (originalClasses < 25) {
      return originalClasses;
    } else if (originalClasses > 25 && originalClasses % 2 > 0) {
      return 11;
    } else {
      return 10;
    }
  }

  setLinearKeys(classes) {
    const keys: number[] = [];

    for (let i = 0; i < classes; i++) {
      keys.push(i);
    }

    return keys;
  }

  setLinearLabels(scale, classes, values) {
    const labels = {};

    if (scale.step != null) {
      const min = !scale || scale.min == null ? 0 : scale.min;
      const max = !scale || scale.max == null ? 100 : scale.max;
      const step = !scale || scale.step == null ? 1 : scale.step;
      const defaulRounder = Math.pow(10, 5);

      const smallerStep =
        step > 1 && step % 1 === 0
          ? 1
          : Math.round((step % 1) * defaulRounder) / defaulRounder > 0
            ? Number('1e-' + step.toString().split('.')[1].length)
            : step;
      const originalClasses = (max + step - min) / step;
      const rounder = step % 1 > 0 ? Math.pow(10, step.toString().split('.')[1].length) : Math.pow(10, 0);
      const classCalc = (answer) => Math.floor((Number(answer) - min) / ((max + smallerStep - min) / classes));

      for (let i = 0; i < classes; i++) {
        if (classes === originalClasses) {
          const val = min + Math.round(i * step * rounder) / rounder;
          labels[i] = `${val}`;
        } else {
          let valMin = Math.floor((i * ((max - min) / classes) + min) * rounder) / rounder;
          let valMax = Math.floor(((i + 1) * ((max - min) / classes) + min) * rounder) / rounder;

          valMin = Math.round(valMin * defaulRounder) / defaulRounder;
          valMax = Math.round(valMax * defaulRounder) / defaulRounder;

          valMin =
            classCalc(valMin) < i
              ? classCalc(valMin + smallerStep) < i
                ? classCalc(valMin + 2 * smallerStep) === i
                  ? valMin + 2 * smallerStep
                  : valMin
                : valMin + smallerStep
              : valMin;
          valMax =
            classCalc(valMax + smallerStep) === i
              ? valMax + smallerStep
              : classCalc(valMax) > i
                ? classCalc(valMin - smallerStep) === i
                  ? valMax - smallerStep
                  : valMax
                : valMax;

          valMin = Math.round(valMin * defaulRounder) / defaulRounder;
          valMax = Math.round(valMax * defaulRounder) / defaulRounder;

          labels[i] = `${valMin} - ${valMax}`;
        }
      }
    } else {
      for (let v = 0, lenv = values.length; v < lenv; v++) {
        labels[values[v]] = values[v] != null ? values[v].toString() : '';
      }
    }

    return labels;
  }

  setDomainContents(dom, index, i, comparison: any = null, activeLocale: string = '', timePeriod = null) {
    let keys: (number | string)[] = [];
    let labels = {};
    let originValues = {};
    let labelsLinear = {};
    const key = dom.key;
    const scale = dom.scale;
    const colors = dom.customColors || {};
    const title: string =
      activeLocale && dom.localeStrings && dom.localeStrings[activeLocale] && dom.localeStrings[activeLocale]['title']
        ? dom.localeStrings[activeLocale]['title']
        : dom.title;
    const images = dom.imagesCategorical || {};

    const groupLabel: string =
      activeLocale &&
      dom.localeStrings &&
      dom.localeStrings[activeLocale] &&
      dom.localeStrings[activeLocale]['groupLabel']
        ? dom.localeStrings[activeLocale]['groupLabel']
        : dom.groupLabel || '';

    if (scale === 'linear') {
      originValues = dom.valueScaleLinear ? dom.valueScaleLinear : new SliderValuesData();

      if (
        activeLocale &&
        dom.localeStrings &&
        dom.localeStrings[activeLocale] &&
        dom.localeStrings[activeLocale]['labelsLinear']
      ) {
        labelsLinear = Object.assign({}, dom.localeStrings[activeLocale]['labelsLinear']);
        for (const labelKey of Object.keys(dom.labelsLinear)) {
          if (!labelsLinear[labelKey]) {
            labelsLinear[labelKey] = dom.labelsLinear && dom.labelsLinear[labelKey] ? dom.labelsLinear[labelKey] : '';
          }
        }
      } else {
        labelsLinear = dom.labelsLinear ? dom.labelsLinear : {};
      }

      keys =
        dom.valueScaleLinear?.step != null
          ? this.setLinearKeys(this.setLinearClasses(dom.valueScaleLinear))
          : dom.values;
      labels = this.setLinearLabels(dom.valueScaleLinear, this.setLinearClasses(dom.valueScaleLinear), dom.values);
    } else if (scale === 'categorical' || (scale === 'text' && dom.values && dom.values.length > 0)) {
      originValues = {};
      if (comparison !== null && comparison.values.length > 0) {
        keys = [];
        for (let item = 0, len = comparison.values.length; item < len; item++) {
          keys.push(dom.values[comparison.values[item]]);
        }
      } else {
        keys = dom.values;
      }

      if (
        activeLocale &&
        dom.localeStrings &&
        dom.localeStrings[activeLocale] &&
        dom.localeStrings[activeLocale]['labelsCategorical']
      ) {
        labels = Object.assign({}, dom.localeStrings[activeLocale]['labelsCategorical']);
        for (let k = 0, len = keys.length; k < len; k++) {
          const labelKey = keys[k];
          if (!labels[labelKey]) {
            labels[labelKey] =
              dom.labelsCategorical && dom.labelsCategorical[labelKey] ? dom.labelsCategorical[labelKey] : '';
          }
        }
      } else {
        labels = dom.labelsCategorical ? dom.labelsCategorical : {};
      }
    } else if (scale === 'time') {
      originValues = {};
      const detValues = Object.assign([], dom.values);
      detValues.sort((a, b) => Number(a) - Number(b));

      if (detValues[0] != null) {
        keys = [detValues[0].toString()];
        let activeDay: number = Number(detValues[0]);
        const end: number = Number(detValues[detValues.length - 1]);

        while (activeDay < end) {
          let date;
          if (timePeriod === 'week') {
            date = new Date(new Date(activeDay).setDate(new Date(activeDay).getDate() + 7)).setHours(0, 0, 0, 0);
          } else if (timePeriod === 'month') {
            const now = new Date(activeDay);
            if (now.getMonth() === 11) {
              date = new Date(now.getFullYear() + 1, 0, 1).setHours(0, 0, 0, 0);
            } else {
              date = new Date(now.getFullYear(), now.getMonth() + 1, 1).setHours(0, 0, 0, 0);
            }
          } else if (timePeriod === 'year') {
            const now = new Date(activeDay);

            date = new Date(now.getFullYear() + 1, 0, 1).setHours(0, 0, 0, 0);
          } else {
            date = new Date(new Date(activeDay).setDate(new Date(activeDay).getDate() + 1)).setHours(0, 0, 0, 0);
          }

          keys.push(date.toString());
          activeDay = date;
        }

        for (const k of keys) {
          const timestamp: Date = new Date(Number(k));
          labels[k] = new Date(timestamp).toLocaleDateString();
        }
      }
    } else {
    }

    if (i === 0) {
      this.domain[0] = {
        groupLabel,
        title,
        keys,
        keysY: [],
        labels,
        labelsY: {},
        labelsLinear,
        labelsLinearY: {},
        colors,
        colorsY: {},
        scale,
        scaleY: {},
        origin: originValues,
        originY: '',
        key,
        keyY: '',
        index,
        indexY: null,
        images,
      };
    } else if (i === 1) {
      this.domain[1] = {
        groupLabel,
        title,
        keys,
        keysY: this.domain[0]['keys'],
        labels,
        labelsY: this.domain[0]['labels'],
        labelsLinear,
        labelsLinearY: this.domain[0]['labelsLinear'],
        colors,
        colorsY: this.domain[0]['colors'],
        scale,
        scaleY: this.domain[0]['scale'],
        origin: originValues,
        originY: this.domain[0]['origin'],
        key,
        keyY: this.domain[0]['key'],
        index,
        indexY: this.domain[0]['index'],
        images,
      };
      this.domain[0]['keysY'] = keys;
      this.domain[0]['labelsY'] = labels;
      this.domain[0]['labelsLinearY'] = labelsLinear;
      this.domain[0]['colorsY'] = colors;
      this.domain[0]['scaleY'] = scale;
      this.domain[0]['originY'] = originValues;
      this.domain[0]['keyY'] = key;
      this.domain[0]['indexY'] = index;
    } else {
      this.domain[i] = {
        groupLabel,
        title,
        keys,
        keysY: this.domain[0]['keys'],
        labels,
        labelsY: this.domain[0]['labels'],
        labelsLinear,
        labelsLinearY: this.domain[0]['labelsLinear'],
        colors,
        colorsY: this.domain[0]['colors'],
        scale,
        scaleY: this.domain[0]['scale'],
        origin: originValues,
        originY: this.domain[0]['origin'],
        key,
        keyY: this.domain[0]['key'],
        index,
        indexY: this.domain[0]['index'],
        images,
      };

      // adding proper labels to categorical&text dimension case (e.g. emotions)
      if (this.domain[1].scale === 'text' && this.domain[0].scale === 'categorical' && comparison) {
        this.domain[0]['keysY'] = keys;
        this.domain[0]['labelsY'] = labels;
        this.domain[0]['labelsLinearY'] = labelsLinear;
        this.domain[0]['colorsY'] = colors;
        this.domain[0]['scaleY'] = scale;
        this.domain[0]['originY'] = originValues;
        this.domain[0]['keyY'] = key;
        this.domain[0]['indexY'] = index;
      }
    }

    if (dom?.colorsCategorical) {
      this.domain[i].itemColors = dom.colorsCategorical;
    }
  }

  setDistribution(distribution, details, stats, totalAnswers, comparison) {
    const categoricalCount: number = details.filter((d) => d.scale === 'categorical').length;
    const linearCount: number = details.filter((d) => d.scale === 'linear').length;
    const timeCount: number = details.filter((d) => d.scale === 'time').length;
    const aiInterviewerCount: number = details.filter((d) => d.originalType === Questions.AI_INTERVIEWER).length;
    const hasText: boolean = !!details.find((d) => d.scale === 'text' || d.scale === 'contact-text');
    const hasSentiment: boolean = !!details.find((d) => d.originalTypeSpecifier === 'text-sentiment');
    const hasWords: boolean = !!details.find((d) => d.originalTypeSpecifier === 'text-words');
    const hasEmotions: boolean = !!details.find((d) => d.originalTypeSpecifier === 'text-emotions');

    this.distribution = [];

    if (this.domain.length === 1) {
      this.distribution[0] = [];
      this.distributionMaster(0, this.domain[0], details[0], distribution[0], stats[0] && stats[0]['responses']);
    } else if (this.domain.length === 2 && !hasText && details[0].originalTypeSpecifier !== 'text-words') {
      for (const dom in this.domain) {
        if (dom === '0') {
          this.distribution[0] = [];
          this.distributionMaster(0, this.domain[0], details[0], distribution[0], stats[0] && stats[0]['responses']);
          this.distributionChild(
            0,
            this.domain[0],
            this.domain[1],
            details[0],
            details[1],
            distribution[0],
            stats[1],
            stats[1] && stats[1]['responses'],
          );
        } else if (dom === '1') {
          this.distribution[1] = [];
          this.distributionMaster(1, this.domain[1], details[1], distribution[1], stats[1] && stats[1]['responses']);
          if (
            (this.domain[0].scale === 'linear' && this.domain[1].scale === 'categorical') ||
            this.domain[1].scale === 'time'
          ) {
            this.distributionChild(
              1,
              this.domain[1],
              this.domain[0],
              details[1],
              details[0],
              distribution[1],
              stats[1],
              stats[1] && stats[1]['responses'],
            );
          }
        } else {
        }
      }
    } else if (
      this.domain.length === 2 &&
      details[0].originalTypeSpecifier === 'text-words' &&
      this.domain[1].scale === 'categorical'
    ) {
      this.distribution[0] = [];
      this.distributionMaster(0, this.domain[0], details[0], distribution[0], stats[0] && stats[0]['responses']);
      this.distributionChild(
        0,
        this.domain[0],
        this.domain[1],
        details[0],
        details[1],
        distribution[0],
        stats[1],
        stats[1] && stats[1]['responses'],
      );
      this.distribution[1] = [];
      this.distributionMaster(1, this.domain[1], details[1], distribution[1], stats[1] && stats[1]['responses']);
      this.distributionChild(
        1,
        this.domain[1],
        this.domain[0],
        details[1],
        details[0],
        distribution[1],
        stats[1],
        stats[1] && stats[1]['responses'],
      );
    } else if (this.domain.length === 3 && !hasText && categoricalCount === 1 && linearCount === 2) {
      let firstLinear;
      for (const d in this.domain) {
        if (this.domain[d].scale === 'linear') {
          firstLinear = d;
          break;
        }
      }
      for (const dom in this.domain) {
        if (this.domain[dom].scale === 'categorical') {
          this.distribution[dom] = [];
          this.distributionMaster(
            dom,
            this.domain[dom],
            details[dom],
            distribution[dom],
            stats[dom] && stats[dom]['responses'],
          );
          this.distributionChild(
            dom,
            this.domain[dom],
            this.domain[firstLinear],
            details[dom],
            details[firstLinear],
            distribution[dom],
            stats[dom],
            stats[dom] && stats[dom]['responses'],
          );
          this.distributionGrandChild(
            dom,
            this.domain,
            details,
            distribution[dom],
            stats[dom] && stats[dom]['responses'],
          );
        } else {
          this.distribution[dom] = [];
          this.distributionMaster(
            dom,
            this.domain[dom],
            details[dom],
            distribution[dom],
            stats[dom] && stats[dom]['responses'],
          );
        }
      }
    } else if (this.domain.length === 3 && !hasText && categoricalCount === 2 && linearCount === 1) {
      let linear;
      for (const d in this.domain) {
        if (this.domain[d].scale === 'linear') {
          linear = d;
          break;
        }
      }
      for (const dom in this.domain) {
        if (this.domain[dom].scale === 'categorical') {
          this.distribution[dom] = [];
          this.distributionMaster(
            dom,
            this.domain[dom],
            details[dom],
            distribution[dom],
            stats[dom] && stats[dom]['responses'],
          );
          this.distributionChild(
            dom,
            this.domain[dom],
            this.domain[linear],
            details[dom],
            details[linear],
            distribution[dom],
            stats[dom],
            stats[dom] && stats[dom]['responses'],
          );
        } else {
          this.distribution[dom] = [];
          this.distributionMaster(
            dom,
            this.domain[dom],
            details[dom],
            distribution[dom],
            stats[dom] && stats[dom]['responses'],
          );
        }
      }
    } else if (this.domain.length === 3 && !hasText && timeCount === 1 && categoricalCount === 1) {
      for (const dom in this.domain) {
        if (this.domain[dom].scale === 'categorical') {
          this.distribution[dom] = [];
          this.distributionMaster(
            dom,
            this.domain[dom],
            details[dom],
            distribution[dom],
            stats[dom] && stats[dom]['responses'],
          );
          this.distributionChild(
            dom,
            this.domain[dom],
            this.domain[0],
            details[dom],
            details[0],
            distribution[dom],
            stats[dom],
            stats[dom] && stats[dom]['responses'],
          );
          this.distributionGrandChild(
            dom,
            this.domain,
            details,
            distribution[dom],
            stats[dom] && stats[dom]['responses'],
          );
        } else {
          this.distribution[dom] = [];
          this.distributionMaster(
            dom,
            this.domain[dom],
            details[dom],
            distribution[dom],
            stats[dom] && stats[dom]['responses'],
          );
        }
      }
    } else if (this.domain.length === 3 && !hasText && timeCount === 1 && categoricalCount === 2) {
      for (const dom in this.domain) {
        if (comparison && this.domain[dom].key === comparison.key) {
          this.distribution[dom] = [];
          this.distributionMaster(
            dom,
            this.domain[dom],
            details[dom],
            distribution[dom],
            stats[dom] && stats[dom]['responses'],
          );
          this.distributionChild(
            dom,
            this.domain[dom],
            this.domain[0],
            details[dom],
            details[0],
            distribution[dom],
            stats[dom],
            stats[dom] && stats[dom]['responses'],
          );
          this.distributionGrandChild(
            dom,
            this.domain,
            details,
            distribution[dom],
            stats[dom] && stats[dom]['responses'],
          );
        } else {
          this.distribution[dom] = [];
          this.distributionMaster(
            dom,
            this.domain[dom],
            details[dom],
            distribution[dom],
            stats[dom] && stats[dom]['responses'],
          );
        }
      }
    } else if (this.domain.length === 3 && hasSentiment && linearCount === 1 && categoricalCount === 1) {
      let linear;
      for (const d in this.domain) {
        if (this.domain[d].scale === 'linear') {
          linear = d;
          break;
        }
      }
      for (const dom in this.domain) {
        if (this.domain[dom].scale === 'categorical') {
          this.distribution[dom] = [];
          this.distributionMaster(
            dom,
            this.domain[dom],
            details[dom],
            distribution[dom],
            stats[dom] && stats[dom]['responses'],
          );
          this.distributionChild(
            dom,
            this.domain[dom],
            this.domain[linear],
            details[dom],
            details[linear],
            distribution[dom],
            stats[dom],
            stats[dom] && stats[dom]['responses'],
          );
        } else {
          this.distribution[dom] = [];
          this.distributionMaster(
            dom,
            this.domain[dom],
            details[dom],
            distribution[dom],
            stats[dom] && stats[dom]['responses'],
          );
        }
      }
    } else if (
      this.domain.length === 3 &&
      categoricalCount === 2 &&
      details.filter((d) => d.scale === 'text').length === 1
    ) {
      let cat1;
      let cat2;
      for (const d in this.domain) {
        if (this.domain[d].scale === 'categorical' && !cat1) {
          cat1 = d;
        } else if (this.domain[d].scale === 'categorical' && !cat2) {
          cat2 = d;
        }
      }
      for (const dom in this.domain) {
        if (this.domain[dom].scale === 'categorical') {
          this.distribution[dom] = [];
          this.distributionMaster(
            dom,
            this.domain[dom],
            details[dom],
            distribution[dom],
            stats[dom] && stats[dom]['responses'],
          );
          this.distributionChild(
            dom,
            this.domain[dom],
            this.domain[dom === cat1 ? cat2 : cat1],
            details[dom],
            details[dom === cat1 ? cat2 : cat1],
            distribution[dom],
            stats[dom === cat1 ? cat2 : cat1],
            stats[dom === cat1 ? cat2 : cat1] && stats[dom === cat1 ? cat2 : cat1]['responses'],
          );
        } else {
          this.distribution[dom] = [];
          this.distributionMaster(
            dom,
            this.domain[dom],
            details[dom],
            distribution[dom],
            stats[dom] && stats[dom]['responses'],
          );
        }
      }
    } else if (this.domain.length === 3 && hasSentiment && timeCount === 1) {
      for (const dom in this.domain) {
        if (dom === '0') {
          this.distribution[0] = [];
          this.distributionMaster(0, this.domain[0], details[0], distribution[0], stats[0] && stats[0]['responses']);
          this.distributionChild(
            0,
            this.domain[0],
            this.domain[1],
            details[0],
            details[1],
            distribution[0],
            stats[1],
            stats[1] && stats[1]['responses'],
          );
        } else if (dom === '1') {
          this.distribution[1] = [];
          this.distributionMaster(1, this.domain[1], details[1], distribution[1], stats[1] && stats[1]['responses']);
          if (
            (this.domain[0].scale === 'linear' && this.domain[1].scale === 'categorical') ||
            this.domain[1].scale === 'time'
          ) {
            this.distributionChild(
              1,
              this.domain[1],
              this.domain[0],
              details[1],
              details[0],
              distribution[1],
              stats[1],
              stats[1] && stats[1]['responses'],
            );
          }
        } else {
        }
      }
    } else if (
      this.domain.length === 3 &&
      details.filter((d, i) => d.scale === 'time' && i === 0).length === 1 &&
      details.filter((d, i) => d.scale === 'categorical' && i === 1).length === 1 &&
      details.filter((d, i) => d.scale === 'text' && i === 2).length === 1
    ) {
      for (const dom in this.domain) {
        if (dom === '0') {
          this.distribution[0] = [];
          this.distributionMaster(0, this.domain[0], details[0], distribution[0], stats[0] && stats[0]['responses']);
          this.distributionChild(
            0,
            this.domain[0],
            this.domain[1],
            details[0],
            details[1],
            distribution[0],
            stats[1],
            stats[1] && stats[1]['responses'],
          );
        } else if (dom === '1') {
          this.distribution[1] = [];
          this.distributionMaster(1, this.domain[1], details[1], distribution[1], stats[1] && stats[1]['responses']);
          if (
            (this.domain[0].scale === 'linear' && this.domain[1].scale === 'categorical') ||
            this.domain[1].scale === 'time'
          ) {
            this.distributionChild(
              1,
              this.domain[1],
              this.domain[0],
              details[1],
              details[0],
              distribution[1],
              stats[1],
              stats[1] && stats[1]['responses'],
            );
          }
        } else {
        }
      }
    } else if (this.domain.length === 4 && hasSentiment && timeCount === 1 && categoricalCount === 1 && !hasWords) {
      for (const dom in this.domain) {
        if (this.domain[dom].scale === 'categorical') {
          this.distribution[dom] = [];
          this.distributionMaster(
            dom,
            this.domain[dom],
            details[dom],
            distribution[dom],
            stats[dom] && stats[dom]['responses'],
          );
          this.distributionChild(
            dom,
            this.domain[dom],
            this.domain[0],
            details[dom],
            details[0],
            distribution[dom],
            stats[dom],
            stats[dom] && stats[dom]['responses'],
          );
          this.distributionGrandChild(
            dom,
            this.domain,
            details,
            distribution[dom],
            stats[dom] && stats[dom]['responses'],
          );
        } else {
          this.distribution[dom] = [];
          this.distributionMaster(
            dom,
            this.domain[dom],
            details[dom],
            distribution[dom],
            stats[dom] && stats[dom]['responses'],
          );
        }
      }
    } else if (
      this.domain.length === 4 &&
      details.filter((d, i) => d.scale === 'time' && i === 0).length === 1 &&
      details.filter((d, i) => d.scale === 'text' && i === 2).length === 1 &&
      details.filter((d, i) => d.scale === 'categorical' && (i === 1 || i === 3)).length === 2
    ) {
      for (const dom in this.domain) {
        if (this.domain[dom].scale === 'categorical' && dom === '3') {
          this.distribution[dom] = [];
          this.distributionMaster(
            dom,
            this.domain[dom],
            details[dom],
            distribution[dom],
            stats[dom] && stats[dom]['responses'],
          );
          this.distributionChild(
            dom,
            this.domain[dom],
            this.domain[0],
            details[dom],
            details[0],
            distribution[dom],
            stats[dom],
            stats[dom] && stats[dom]['responses'],
          );
          this.distributionGrandChild(
            dom,
            this.domain,
            details,
            distribution[dom],
            stats[dom] && stats[dom]['responses'],
          );
        } else {
          this.distribution[dom] = [];
          this.distributionMaster(
            dom,
            this.domain[dom],
            details[dom],
            distribution[dom],
            stats[dom] && stats[dom]['responses'],
          );
        }
      }
    } else if (aiInterviewerCount > 3 && comparison?.values.length > 0) {
      const categoryIndex = details.findIndex((d) => d.originalTypeSpecifier === 'interviewer-rootCauseCategory');
      const wordsIndex = details.findIndex((d) => d.originalTypeSpecifier === 'text-words');
      const comparisonIndex = comparison.index;
      const isTrend = details.filter((d, i) => d.scale === 'time' && i === 0).length === 1;

      for (const dom in this.domain) {
        this.distribution[dom] = [];
        this.distributionMaster(
          dom,
          this.domain[dom],
          details[dom],
          distribution[dom],
          stats[dom] && stats[dom]['responses'],
        );
      }

      if (isTrend) {
        this.distributionChild(
          comparisonIndex,
          this.domain[comparisonIndex],
          this.domain[0],
          details[comparisonIndex],
          details[0],
          distribution[comparisonIndex],
          stats[comparisonIndex],
          stats[comparisonIndex] && stats[comparisonIndex]['responses'],
        );
        this.distributionGrandChild(
          comparisonIndex,
          this.domain,
          details,
          distribution[comparisonIndex],
          stats[comparisonIndex] && stats[comparisonIndex]['responses'],
        );
      } else {
        this.distributionChild(
          comparisonIndex,
          this.domain[comparisonIndex],
          this.domain[categoryIndex],
          details[comparisonIndex],
          details[categoryIndex],
          distribution[comparisonIndex],
          stats[comparisonIndex],
          stats[comparisonIndex] && stats[comparisonIndex]['responses'],
        );
        this.distributionChild(
          wordsIndex,
          this.domain[wordsIndex],
          this.domain[comparisonIndex],
          details[wordsIndex],
          details[comparisonIndex],
          distribution[wordsIndex],
          stats[comparisonIndex],
          stats[comparisonIndex] && stats[comparisonIndex]['responses'],
        );
      }
    } else if (aiInterviewerCount > 3 && details.filter((d, i) => d.scale === 'time' && i === 0).length === 1) {
      const categoryIndex = details.findIndex((d) => d.originalTypeSpecifier === 'interviewer-rootCauseCategory');
      for (const dom in this.domain) {
        this.distribution[dom] = [];
        this.distributionMaster(
          dom,
          this.domain[dom],
          details[dom],
          distribution[dom],
          stats[dom] && stats[dom]['responses'],
        );
      }
      this.distributionChild(
        categoryIndex,
        this.domain[categoryIndex],
        this.domain[0],
        details[categoryIndex],
        details[0],
        distribution[categoryIndex],
        stats[categoryIndex],
        stats[categoryIndex] && stats[categoryIndex]['responses'],
      );
      this.distributionChild(
        0,
        this.domain[0],
        this.domain[categoryIndex],
        details[0],
        details[categoryIndex],
        distribution[0],
        stats[0],
        stats[0] && stats[0]['responses'],
      );
    } else if (comparison && categoricalCount > 2) {
      const wordsIndex = details.findIndex((d) => d.originalTypeSpecifier === 'text-words');

      for (const dom in this.domain) {
        this.distribution[dom] = [];
        this.distributionMaster(
          dom,
          this.domain[dom],
          details[dom],
          distribution[dom],
          stats[dom] && stats[dom]['responses'],
        );

        if (this.domain[dom].key !== comparison.key) {
          this.distributionChild(
            dom,
            this.domain[dom],
            this.domain[comparison.index],
            details[dom],
            details[comparison.index],
            distribution[dom],
            stats[comparison.index],
            stats[comparison.index] && stats[comparison.index]['responses'],
          );
        } else if (hasWords && hasSentiment) {
          this.distributionChild(
            dom,
            this.domain[dom],
            this.domain[wordsIndex],
            details[dom],
            details[wordsIndex],
            distribution[dom],
            stats[wordsIndex],
            stats[wordsIndex] && stats[wordsIndex]['responses'],
          );
        }
      }
      if (timeCount > 0 && hasEmotions) {
        this.distributionGrandChild(
          comparison.index,
          this.domain,
          details,
          distribution[comparison.index],
          stats[comparison.index] && stats[comparison.index]['responses'],
        );
      }
    } else if (timeCount > 0 && hasEmotions) {
      const emotionsIndex = details.findIndex((d) => d.originalTypeSpecifier === 'text-emotions');
      const timeIndex = details.findIndex((d) => d.scale === 'time');

      for (const dom in this.domain) {
        this.distribution[dom] = [];
        this.distributionMaster(
          dom,
          this.domain[dom],
          details[dom],
          distribution[dom],
          stats[dom] && stats[dom]['responses'],
        );
      }
      this.distributionChild(
        timeIndex,
        this.domain[timeIndex],
        this.domain[emotionsIndex],
        details[timeIndex],
        details[emotionsIndex],
        distribution[timeIndex],
        stats[emotionsIndex],
        stats[emotionsIndex] && stats[emotionsIndex]['responses'],
      );
    } else if (this.domain.length === 4 && comparison && hasSentiment && hasWords) {
      const wordsIndex = details.findIndex((d) => d.originalTypeSpecifier === 'text-words');

      for (const dom in this.domain) {
        this.distribution[dom] = [];
        this.distributionMaster(
          dom,
          this.domain[dom],
          details[dom],
          distribution[dom],
          stats[dom] && stats[dom]['responses'],
        );
      }
      this.distributionChild(
        comparison.index,
        this.domain[comparison.index],
        this.domain[wordsIndex],
        details[comparison.index],
        details[wordsIndex],
        distribution[comparison.index],
        stats[wordsIndex],
        stats[wordsIndex] && stats[wordsIndex]['responses'],
      );
    } else {
      for (const dom in this.domain) {
        this.distribution[dom] = [];
        this.distributionMaster(
          dom,
          this.domain[dom],
          details[dom],
          distribution[dom],
          stats[dom] && stats[dom]['responses'],
        );
      }
    }
  }

  distributionMaster(i, dom, details, distribution, totalAnswers) {
    if (dom.scale === 'linear' && dom.origin?.step != null) {
      const convertedData = this.convert1DScale(distribution, details.values, dom.origin, dom.keys.length);
      for (const k in dom.keys) {
        const key = Number(dom.keys[k]);
        const value = convertedData[key] ? convertedData[key]['value'] : 0;

        this.distribution[i].push({
          key: key.toString(),
          value,
          percentage: value && totalAnswers ? value / totalAnswers : 0,
          percentage_all: value && totalAnswers ? value / totalAnswers : 0,
          children: [],
        });
      }
    } else {
      for (const k in dom.keys) {
        const key = dom.keys[k];
        const index = details.values.indexOf(key);
        const value =
          distribution && distribution[index] && distribution[index][0] && distribution[index][0][0] != null
            ? distribution[index][0][0]
            : 0;
        const customValues = {};

        if (details.originalTypeSpecifier === 'why-finder-themes') {
          customValues['strengths'] = distribution?.[index]?.[0]?.[1] || 0;
          customValues['weaknesses'] = distribution?.[index]?.[0]?.[2] || 0;
        }

        this.distribution[i].push({
          key,
          value,
          percentage: value && totalAnswers ? value / totalAnswers : 0,
          percentage_all: value && totalAnswers ? value / totalAnswers : 0,
          children: [],
          ...(Object.keys(customValues)?.length && { customValues }),
        });
      }
    }
  }

  distributionChild(i, dom, domChild, detailsMaster, detailsChild, distribution, stats, totalAnswers) {
    let convertedMaster;

    if (dom.scale === 'linear' && dom.origin?.step != null) {
      convertedMaster = this.advancedConvert1DScale(distribution, detailsMaster.values, dom.origin, dom.keys.length);
    } else {
      convertedMaster = distribution;
    }
    for (const k in dom.keys) {
      const key = dom.keys[k];
      const indexMaster = detailsMaster.values.indexOf(key);

      const value =
        dom.scale === 'linear' && dom.origin?.step != null
          ? convertedMaster && convertedMaster[key] && convertedMaster[key][domChild.index] != null
            ? convertedMaster[key][domChild.index]
            : []
          : distribution &&
              distribution[indexMaster] &&
              distribution[indexMaster][1] &&
              distribution[indexMaster][1][domChild.index] != null
            ? distribution[indexMaster][1][domChild.index]
            : [];
      let convertedChild;
      if (domChild.scale === 'linear' && domChild.origin?.step != null) {
        convertedChild = this.convert1DScale(value, detailsChild.values, domChild.origin, domChild.keys.length);
      } else {
        convertedChild = value;
      }
      let totalAnswersStacked = 0;

      for (const item in convertedChild) {
        if (domChild.scale === 'linear' && domChild.origin?.step != null) {
          totalAnswersStacked += convertedChild[item] ? convertedChild[item]['value'] : 0;
        } else {
          totalAnswersStacked +=
            convertedChild[item] && convertedChild[item][0] && convertedChild[item][0][0] != null
              ? convertedChild[item][0][0]
              : 0;
        }
      }

      for (const ky in domChild.keys) {
        if (domChild.keys[ky] != null) {
          const cfIndex = detailsChild.values.indexOf(domChild.keys[ky]);
          const keyY = domChild.keys[ky];
          let valueY;
          if (domChild.scale === 'linear' && domChild.origin?.step != null) {
            valueY = convertedChild[keyY] ? convertedChild[keyY]['value'] : 0;
          } else {
            valueY =
              convertedChild &&
              convertedChild[cfIndex] &&
              convertedChild[cfIndex][0] &&
              convertedChild[cfIndex][0][0] != null
                ? convertedChild[cfIndex][0][0]
                : 0;
          }

          const totalAnswer =
            stats &&
            stats['children'] &&
            stats['children'][cfIndex] &&
            stats['children'][cfIndex]['children'] &&
            stats['children'][cfIndex]['children'][dom.index] &&
            stats['children'][cfIndex]['children'][dom.index]['responses'] != null
              ? stats['children'][cfIndex]['children'][dom.index]['responses']
              : totalAnswers;

          this.distribution[i][k]['children'].push({
            key: keyY.toString(),
            value: valueY,
            percentage: valueY && totalAnswersStacked ? valueY / totalAnswersStacked : 0,
            percentage_all: valueY && totalAnswer ? valueY / totalAnswer : 0,
            children: [],
          });
        }
      }
    }
  }

  distributionGrandChild(i, domains, details, distribution, totalAnswers) {
    const linear: string[] = [];
    const categorical: string[] = [];
    const time: string[] = [];
    const aiInterviewer: boolean = details.filter((d) => d.originalType === Questions.AI_INTERVIEWER).length > 3;

    for (const domain in domains) {
      if (domains[domain].scale === 'categorical' && !domains[domain].key.includes(':words')) {
        categorical.push(domain);
      } else if (domains[domain].scale === 'linear') {
        linear.push(domain);
      } else if (domains[domain].scale === 'time') {
        time.push(domain);
      }
    }

    let dom;
    let domGrandChild;
    let domMaster;
    let detailsMaster;
    let detailsChild;
    let detailsGrandChild;

    if (aiInterviewer) {
      const categoryIndex = details.findIndex((d) => d.originalTypeSpecifier === 'interviewer-rootCauseCategory');

      dom = domains[time[0]];
      domMaster = domains[i];
      domGrandChild = domains[categoryIndex];

      detailsMaster = details[i];
      detailsChild = details[time[0]];
      detailsGrandChild = details[categoryIndex];
    } else if (categorical.length === 1 && linear.length === 2) {
      dom = domains[linear[0]];
      domGrandChild = domains[linear[1]];
      domMaster = domains[categorical[0]];

      detailsMaster = details[categorical[0]];
      detailsChild = details[linear[0]];
      detailsGrandChild = details[linear[1]];
    } else if (time.length === 1 && categorical.length === 2) {
      dom = domains[time[0]];
      domGrandChild = domains[categorical[0]];
      domMaster = domains[categorical[1]];

      detailsMaster = details[categorical[1]];
      detailsGrandChild = details[categorical[0]];
      detailsChild = details[time[0]];
    } else if (time.length === 1 && categorical.length === 1 && linear.length === 1) {
      dom = domains[time[0]];
      domGrandChild = domains[linear[0]];
      domMaster = domains[categorical[0]];

      detailsMaster = details[categorical[0]];
      detailsGrandChild = details[linear[0]];
      detailsChild = details[time[0]];
    }

    let convertedMaster;

    if (domMaster.scale === 'linear' && domMaster.origin?.step != null) {
      convertedMaster = this.advancedConvert1DScale(
        distribution,
        detailsMaster.values,
        domMaster.origin,
        domMaster.keys.length,
      );
    } else {
      convertedMaster = distribution;
    }

    for (const k in domMaster.keys) {
      const key = domMaster.keys[k];
      const indexMaster = detailsMaster.values.indexOf(key);
      const value =
        domMaster.scale === 'linear' && domMaster.origin?.step != null
          ? convertedMaster && convertedMaster[key] && convertedMaster[key][dom.index] != null
            ? convertedMaster[key][dom.index]
            : []
          : distribution &&
              distribution[indexMaster] &&
              distribution[indexMaster][1] &&
              distribution[indexMaster][1][dom.index] != null
            ? distribution[indexMaster][1][dom.index]
            : [];

      let convertedChild;
      if (dom.scale === 'linear' && dom.origin?.step != null) {
        convertedChild = this.advancedConvert1DScale(value, detailsChild.values, dom.origin, dom.keys.length);
      } else {
        convertedChild = value;
      }

      for (const kx in dom.keys) {
        const keyX = dom.keys[kx];
        const indexChild = detailsChild.values.indexOf(keyX);

        const valueX =
          dom.scale === 'linear' && dom.origin?.step != null
            ? convertedChild[keyX] && convertedChild[keyX][domGrandChild.index] != null
              ? convertedChild[keyX][domGrandChild.index]
              : []
            : convertedChild[indexChild] &&
                convertedChild[indexChild][1] &&
                convertedChild[indexChild][1][domGrandChild.index] != null
              ? convertedChild[indexChild][1][domGrandChild.index]
              : [];

        let convertedGrandChild;
        if (dom.scaleY === 'linear' && dom.originY?.step != null && !aiInterviewer) {
          convertedGrandChild = this.convert1DScale(
            valueX,
            detailsGrandChild.values,
            domGrandChild.origin,
            domGrandChild.keys.length,
          );
        } else {
          convertedGrandChild = valueX;
        }

        let totalAnswersStacked = 0;

        for (const item in convertedGrandChild) {
          if (domGrandChild.scale === 'linear' && domGrandChild.origin?.step != null) {
            totalAnswersStacked += convertedGrandChild[item] ? convertedGrandChild[item]['value'] : 0;
          } else {
            totalAnswersStacked +=
              convertedGrandChild[item] && convertedGrandChild[item][0] && convertedGrandChild[item][0][0] != null
                ? convertedGrandChild[item][0][0]
                : 0;
          }
        }

        for (const ky in domGrandChild.keys) {
          const keyY = domGrandChild.keys[ky];
          const indexGrandChild = detailsGrandChild.values.indexOf(keyY);

          let valueY;
          if (dom.scaleY === 'linear' && dom.originY?.step != null && !aiInterviewer) {
            valueY = convertedGrandChild[keyY] ? convertedGrandChild[keyY]['value'] : 0;
          } else {
            valueY =
              convertedGrandChild[indexGrandChild] &&
              convertedGrandChild[indexGrandChild][0] &&
              convertedGrandChild[indexGrandChild][0][0] != null
                ? convertedGrandChild[indexGrandChild][0][0]
                : 0;
          }

          this.distribution[i][k]['children'][kx]['children'].push({
            key: keyY.toString(),
            value: valueY,
            percentage: valueY && totalAnswersStacked ? valueY / totalAnswersStacked : 0,
            percentage_all: valueY && totalAnswers ? valueY / totalAnswers : 0,
            children: [],
          });
        }
      }
    }
  }

  convert1DScale(data: any, vals: any, origin: any, classes: any) {
    const newData = {};
    for (const item in data) {
      const val = vals[item];
      const i = this.scale1DAnswer(val, origin, classes);

      if (i !== null) {
        if (!newData[i]) {
          newData[i] = { value: 0 };
        }

        if (data[item] && data[item][0] && data[item][0][0] != null) {
          newData[i]['value'] += data[item][0][0];
        }
      }
    }
    return newData;
  }

  advancedConvert1DScale(data: any, vals: any, origin: any, classes: any) {
    const newData = {};
    for (const item in data) {
      const val = vals[item];
      const i = this.scale1DAnswer(val, origin, classes);

      if (i !== null) {
        if (!newData[i]) {
          newData[i] = {};
        }
        for (const dimension in data[item][1]) {
          if (!newData[i][dimension]) {
            newData[i][dimension] = {};
          }
          for (const key in data[item][1][dimension]) {
            if (!newData[i][dimension][key]) {
              newData[i][dimension][key] = [[0]];
            }
            if (
              data[item] &&
              data[item][1] &&
              data[item][1][dimension] &&
              data[item][1][dimension][key] &&
              data[item][1][dimension][key][0] &&
              data[item][1][dimension][key][0][0] != null
            ) {
              newData[i][dimension][key][0][0] += data[item][1][dimension][key][0][0];
            }
          }
        }
      }
    }
    return newData;
  }

  scale1DAnswer(answer, origin, classes) {
    const min = !origin || origin.min == null ? 0 : origin.min;
    const max = !origin || origin.max == null ? 100 : origin.max;
    const step = !origin || origin.step == null ? 1 : origin.step;
    const rounder = Math.pow(10, 5);

    const value = Math.floor(Math.round(((Number(answer) - min) / ((max + step - min) / classes)) * rounder) / rounder);
    return !isNaN(value) && value !== undefined ? value : null;
  }

  setStats(stats) {
    this.stats = stats.map((item) => {
      if (item?.children) {
        if (item.key === 'time') {
          item.children.sort((a, b) => Number(a.key) - Number(b.key));
        } else {
          for (const child in item.children || []) {
            if (item.children[child] && item['children'][child]['children']) {
              for (const grandChild in item['children'][child]['children']) {
                if (
                  item['children'][child]['children'][grandChild] &&
                  item['children'][child]['children'][grandChild]['children'] &&
                  item['children'][child]['children'][grandChild]['key'] === 'time'
                ) {
                  item['children'][child]['children'][grandChild]['children'].sort(
                    (a, b) => Number(a?.key) - Number(b?.key),
                  );
                }
              }
            }
          }
        }
      }
      return item;
    });
  }

  chartDataTable(data: any, chartType: string, anonymityTreshold: number, dataService = null) {
    return new Observable<any>((observer) => {
      const c = 'children';
      const dataTable: any[] = [];

      if (
        data.distributions &&
        data.totalAnswers &&
        data.domain &&
        chartType !== Charts.WORDANALYSIS &&
        chartType !== Charts.RESPONSERATES
      ) {
        const stats = data.stats;
        const timeIndex = data.domain.findIndex((det) => det.key === 'time');

        const questionTitle = (question) => {
          for (const dom of data.domain) {
            if (dom.key === question) {
              const axis =
                dom.labelsLinear && dom.labelsLinear.axis
                  ? ` (${dom.labelsLinear.axis})`
                  : dom.labelsLinear && dom.labelsLinear.min && dom.labelsLinear.max
                    ? ` (${dom.labelsLinear.min} - ${dom.labelsLinear.max})`
                    : data.details?.find((d) => d.key === question)?.originalTypeSpecifier === 'x-axis'
                      ? ` (X)`
                      : data.details?.find((d) => d.key === question)?.originalTypeSpecifier === 'y-axis'
                        ? ` (Y)`
                        : ``;
              return dom.title + axis;
            }
          }
        };

        const scaleType = (key) => {
          for (const dom of data.domain) {
            if (dom.key === key) {
              return dom.scale;
            }
          }
        };

        const choiceLabel = (question, choice) => {
          for (const dom of data.domain) {
            if (dom.key === question) {
              return dom.labels[choice] ? dom.labels[choice] : '';
            }
          }
        };

        const axisLabel: (key: string) => string = (key: string) => {
          for (const dom of data.domain) {
            if (dom.key === key) {
              const axis = dom.labelsLinear.axis ? dom.labelsLinear.axis + ': ' : '';
              const min = dom.labelsLinear.min ? dom.labelsLinear.min : '';
              const max = dom.labelsLinear.max ? dom.labelsLinear.max : '';
              const fallback =
                data.details.find((d) => d.key === key)?.originalTypeSpecifier === 'x-axis'
                  ? 'X: '
                  : data.details.find((d) => d.key === key)?.originalTypeSpecifier === 'y-axis'
                    ? 'Y: '
                    : '';
              return (
                (axis && (min || max)
                  ? `${axis}(${min} - ${max})`
                  : min || max
                    ? `(${min} - ${max})`
                    : axis || fallback) + ` (${dom.origin ? dom.origin.min : ''}-${dom.origin ? dom.origin.max : ''})`
              );
            }
          }
        };

        if (chartType === Charts.SUMMARY2D || chartType === Charts.SUMMARY1D) {
          const dim = 0;
          dataTable[dim] = { values: [], headers: [$localize`:@@zef-i18n-00293:Question`] };
          const axisAverages = {};
          const groupSeparator: string = '\u001D';

          if (!data.comparison) {
            dataTable[dim]['headers'].push($localize`:@@zef-i18n-00294:Average`);
            dataTable[dim]['headers'].push($localize`:@@zef-i18n-00295:Standard Deviation`);
            for (let i = 0, len = stats.length; i < len; i++) {
              if (stats[i]) {
                const key = stats[i].key;
                if (scaleType(key) === 'linear') {
                  const text = questionTitle(key); // + (statsKeys.length > 1 ? ' (all)' : '');
                  const value = stats[i]['average'] != null ? stats[i]['average'].toFixed(1) : '-';
                  const std = stats[i]['std'] != null ? stats[i]['std'].toFixed(1) : '-';
                  const isUnderAnonymityTreshold: boolean = anonymityTreshold && anonymityTreshold > stats[i]['count'];

                  if (!isUnderAnonymityTreshold) {
                    dataTable[dim]['values'].push([text, value, std]);

                    // Axis averages
                    const axis: string = axisLabel(key);

                    if (!axisAverages[axis]) {
                      axisAverages[axis] = {};
                    }

                    axisAverages[axis][key] = {
                      count: stats[i]['count'],
                      average: stats[i]['average'],
                      std: stats[i]['std'],
                    };
                  }
                }
              }
            }
          } else if (data.comparison && data.comparison.index != null) {
            const comparisonIndex = data.comparison.index;

            if (stats[comparisonIndex] && stats[comparisonIndex]['children']) {
              const values = data.domain
                .filter((dom) => dom['key'] !== data.comparison.key && dom['scale'] === 'linear')
                .map((dom) => [questionTitle(dom.key)]);
              for (let cid = 0, lencid = data.comparison.values.length; cid < lencid; cid++) {
                const ch = data.comparison.values[cid];
                if (stats[comparisonIndex]['children'][ch]) {
                  const chKey = stats[comparisonIndex]['children'][ch]['key'];
                  const chTitle = choiceLabel(data.comparison.key, chKey);
                  dataTable[dim]['headers'].push(chTitle);
                  for (let dom = 0, lendom = data.domain.length; dom < lendom; dom++) {
                    if (data.domain[dom]['key'] !== data.comparison.key && data.domain[dom]['scale'] === 'linear') {
                      const domIndex = data.domain[dom]['index'];
                      const domKey = data.domain[dom]['key'];
                      const text = questionTitle(domKey);
                      const currentRow = values.findIndex((row) => row[0] === text);
                      const count =
                        stats[comparisonIndex]['children'][ch] &&
                        stats[comparisonIndex]['children'][ch]['children'] &&
                        stats[comparisonIndex]['children'][ch]['children'][domIndex] &&
                        stats[comparisonIndex]['children'][ch]['children'][domIndex]['count'];
                      const value =
                        stats[comparisonIndex]['children'][ch] &&
                        stats[comparisonIndex]['children'][ch]['children'] &&
                        stats[comparisonIndex]['children'][ch]['children'][domIndex] &&
                        stats[comparisonIndex]['children'][ch]['children'][domIndex]['average'];
                      const valueString = value != null ? value.toFixed(1) : '-';
                      const std =
                        stats[comparisonIndex]['children'][ch] &&
                        stats[comparisonIndex]['children'][ch]['children'] &&
                        stats[comparisonIndex]['children'][ch]['children'][domIndex] &&
                        stats[comparisonIndex]['children'][ch]['children'][domIndex]['std'];
                      const stdString =
                        std != null
                          ? stats[comparisonIndex]['children'][ch]['children'][domIndex]['std'].toFixed(1)
                          : '-';
                      const isUnderAnonymityTreshold: boolean = anonymityTreshold && anonymityTreshold > count;

                      // Axis averages
                      const axis = axisLabel(domKey) + groupSeparator + chKey;

                      if (!axisAverages[axis]) {
                        axisAverages[axis] = {};
                      }

                      if (!isUnderAnonymityTreshold) {
                        values[currentRow].push(`${valueString} (σ=${stdString})`);
                        axisAverages[axis][domKey] = { count, average: value, std };
                      } else {
                        values[currentRow].push(``);
                        axisAverages[axis][domKey] = {};
                      }
                    }
                  }
                }
              }
              dataTable[dim]['values'] = values;
            }
          }
          if (Object.keys(axisAverages).length > 0) {
            const values = Array.from(
              new Set(Object.keys(axisAverages).map((axis) => $localize`Total` + ': ' + axis.split(groupSeparator)[0])),
            ).map((axis) => [axis]);
            for (const axis in axisAverages) {
              let weightedSum: number = 0;
              let varNumerator: number = 0;
              let count: number = 0;

              for (const item in axisAverages[axis]) {
                if (axisAverages[axis][item]['average'] != null && axisAverages[axis][item]['count'] > 0) {
                  weightedSum += axisAverages[axis][item]['average'] * axisAverages[axis][item]['count'];
                  count += axisAverages[axis][item]['count'];
                }
              }

              if (count > 0) {
              }
              const average = count > 0 ? weightedSum / count : null;

              if (average != null) {
                for (const item in axisAverages[axis]) {
                  if (axisAverages[axis][item]['average'] != null && axisAverages[axis][item]['count'] > 0) {
                    varNumerator +=
                      axisAverages[axis][item]['count'] * Math.pow(axisAverages[axis][item]['std'], 2) + 0;
                    varNumerator +=
                      axisAverages[axis][item]['count'] * Math.pow(axisAverages[axis][item]['average'] - average, 2) +
                      0;
                  }
                }
              }

              const std = count > 0 ? Math.sqrt(varNumerator / count) : null;
              const averageString = average != null ? average.toFixed(1) : '-';
              const stdString = std != null ? std.toFixed(1) : '-';
              const rowIndex = values.findIndex(
                (row) => row[0].replace($localize`Total` + ': ', '') === axis.split(groupSeparator)[0],
              );

              if (data.comparison && data.comparison.index != null) {
                values[rowIndex].push(average != null ? `${averageString} (σ=${stdString})` : ``);
              } else {
                values[rowIndex].push(averageString, stdString);
              }
            }
            dataTable[dim]['values'] = dataTable[dim]['values'].concat(values);
          }
        } else if (Charts.CROSSTABCHARTS.includes(chartType) && data['domain'][1]) {
          const dim = 0;
          dataTable[dim] = { values: [], headers: [''] };

          for (const ch in data['domain'][1]['keys']) {
            const choice = data['domain'][1]['keys'][ch];

            const title =
              (data.comparison ? '' : data.domain[1] && data.domain[1].title + ': ') + data.domain[1]['labels'][choice];

            dataTable[dim]['headers'].push(title);
          }

          for (let ch = 0, len = data['distributions'][0].length; ch < len; ch++) {
            const choice = data['distributions'][0][ch]['key'];
            const children = data['distributions'][0][ch]['children'];
            const arr: string[] = [];
            const title =
              (data.comparison ? '' : data.domain[0] && data.domain[0].title + ': ') + data.domain[0]['labels'][choice];

            arr.push(title);

            for (const child of children) {
              arr.push(child.value);
            }
            dataTable[dim]['values'].push(arr);
          }
        } else if (
          chartType === Charts.SUMMARYCHOICE &&
          data.comparison &&
          data.comparison.index != null &&
          data['domain'][data.comparison.index]
        ) {
          const ci = data.comparison.index;
          const compDomain = data['domain'][ci];

          for (const dim in data.details) {
            const details = data.details[dim];
            const domain = data.domain[dim];
            const distribution = data.distributions[dim];

            if (domain.key !== compDomain.key) {
              dataTable[dim] = { values: [], headers: [domain && domain.title] };

              for (const ch in compDomain['keys']) {
                const compKey = compDomain['keys'][ch];
                const compTitle = compDomain['labels'][compKey];

                if (details && domain && distribution) {
                  dataTable[dim]['headers'].push(compTitle);
                }
              }

              for (const val of distribution) {
                const key = domain.labels[val.key];
                const arr: string[] = [];

                arr.push(key);

                for (const child of val?.children || []) {
                  arr.push(child.value);
                }

                dataTable[dim]['values'].push(arr);
              }
            }
          }
        } else if (
          chartType === Charts.TEXTEMOTIONS &&
          data?.comparison?.values?.length &&
          timeIndex < 0 &&
          !anonymityTreshold
        ) {
          dataTable[0] = { values: [], headers: [''] };
          const emotionsIndex = data.domain.findIndex((dom) => dom.key.indexOf(':emotions') >= 0);
          const comparisonIndex = data.comparison
            ? data.domain.findIndex((dom) => dom.key === data.comparison.key)
            : -1;

          for (const ch in data?.['domain']?.[comparisonIndex]?.['keys']) {
            const choice = data['domain'][comparisonIndex]['keys'][ch];

            const title = data.domain[comparisonIndex]['labels'][choice];

            dataTable[0]['headers'].push(title);
          }

          for (let ch = 0, len = data?.['distributions']?.[emotionsIndex]?.length; ch < len; ch++) {
            const choice = data['distributions'][emotionsIndex][ch]['key'];
            const children = data['distributions'][emotionsIndex][ch]['children'];
            const arr: string[] = [];
            const title = data.domain[emotionsIndex]['labels'][choice];

            arr.push(title);

            for (const child of children) {
              arr.push(child.value);
            }
            dataTable[0]['values'].push(arr);
          }
        } else if (chartType === Charts.HEATMAP && data.details.length === 3) {
          const catIndex = data.domain.findIndex((d) => d.scale === 'categorical');
          const linearIndex: string[] = [];

          for (const dom in data.domain) {
            if (data.domain[dom].scale === 'linear') {
              linearIndex.push(dom);
            }
          }

          for (const choice in data.distributions[catIndex]) {
            dataTable[choice] = {
              values: [],
              headers: [data.domain[catIndex]['labels'][data.distributions[catIndex][choice].key]],
            };
            const arrX: any[] = [];

            for (let i = 0, len = data['distributions'][catIndex][choice]['children'].length; i < len; i++) {
              const headerKey = data['distributions'][catIndex][choice]['children'][i]['key'];
              const header = data.domain[linearIndex[0]]['labels'][headerKey];
              arrX[i] = [header];
            }

            for (let i = 0, len = data['distributions'][catIndex][choice]['children'].length; i < len; i++) {
              const item = data['distributions'][catIndex][choice]['children'][i]['key'];
              const children = data['distributions'][catIndex][choice]['children'][i]['children'];

              dataTable[choice]['headers'].push(data.domain[linearIndex[1]]['labels'][item]);

              for (const child of children) {
                arrX[i].push(child.value);
              }
            }

            dataTable[choice]['values'] = arrX;
          }
        } else if (chartType === Charts.NPS) {
          const catIndex = data.domain.findIndex((d) => d.scale === 'categorical' && !anonymityTreshold);
          const linearIndex = data.domain.findIndex((d) => d.scale === 'linear');
          const linearLabels = data.domain[linearIndex]['labels'];

          const NPSdata = this.calculateNPS(
            data.distributions[catIndex >= 0 ? catIndex : linearIndex],
            data.domain[catIndex],
          );
          for (let n = 0, lenn = Math.ceil(NPSdata.length / 3); n < lenn; n++) {
            dataTable[n] = {
              values: [],
              headers: [(data['domain'][linearIndex] && data['domain'][linearIndex]['title']) || ''],
            };
          }

          for (let d = 0, lend = NPSdata.length; d < lend; d++) {
            const tableIndex: number = Math.floor(d / 3);

            dataTable[tableIndex]['headers'][(d % 3) + 1] =
              lend > 1 ? NPSdata[d]['title'] || '' : $localize`:@@zef-i18n-00299:Respondents`;
            for (let i = 0, len = NPSdata[d]['originalDistribution'].length; i < len; i++) {
              const item = NPSdata[d]['originalDistribution'][i];

              const title: string = linearLabels[item.key];
              const npsLabel =
                NPSdata[d]['distribution'] &&
                NPSdata[d]['distribution'][0] &&
                NPSdata[d]['distribution'][0]['originalValues'] &&
                NPSdata[d]['distribution'][0]['originalValues'].indexOf(item.key) >= 0
                  ? NPSdata[d]['distribution'][0]['label']
                  : NPSdata[d]['distribution'] &&
                      NPSdata[d]['distribution'][1] &&
                      NPSdata[d]['distribution'][1]['originalValues'] &&
                      NPSdata[d]['distribution'][1]['originalValues'].indexOf(item.key) >= 0
                    ? NPSdata[d]['distribution'][1]['label']
                    : NPSdata[d]['distribution'] &&
                        NPSdata[d]['distribution'][2] &&
                        NPSdata[d]['distribution'][2]['originalValues'] &&
                        NPSdata[d]['distribution'][2]['originalValues'].indexOf(item.key) >= 0
                      ? NPSdata[d]['distribution'][2]['label']
                      : '';

              const value: number = item.value;
              const percentage: string = Math.round(item.percentage * 1000) / 10 + '%';

              if (!dataTable[tableIndex]['values'][i]) {
                dataTable[tableIndex]['values'][i] = [title + (npsLabel ? ' (' + npsLabel + ')' : '')];
              }

              dataTable[tableIndex]['values'][i][(d % 3) + 1] = `${value} (${percentage})`;
            }
          }
        } else if (chartType === Charts.TIMELINECHART) {
          const dim = 0;
          dataTable[dim] = { values: [], headers: [''] };
          if (data['domain'][timeIndex] && data['distributions'][timeIndex]) {
            if (data['domain'][timeIndex] && data['domain'][timeIndex]['keysY'].length > 0) {
              for (const ch in data['domain'][timeIndex]['keysY']) {
                const choice = data['domain'][timeIndex]['keysY'][ch];

                const title = data.domain[timeIndex]['labelsY'][choice];

                dataTable[dim]['headers'].push(title);
              }
            } else {
              dataTable[dim]['headers'].push($localize`:@@zef-i18n-00299:Respondents`);
            }

            dataTable[dim]['values'] = [
              [$localize`:@@zef-i18n-00334:Today`],
              [$localize`:@@zef-i18n-00335:Yesterday`],
              [$localize`:@@zef-i18n-00336:Last 7 days`],
              [$localize`:@@zef-i18n-00337:Last 30 days`],
              [$localize`:@@zef-i18n-00338:Last 365 days`],
            ];

            for (let ch = 0, len = data['distributions'][timeIndex].length; ch < len; ch++) {
              const distribution =
                data['distributions'][timeIndex][ch] &&
                data['distributions'][timeIndex][ch]['children'] &&
                data['distributions'][timeIndex][ch]['children'].length > 0
                  ? data['distributions'][timeIndex][ch]['children']
                  : [data['distributions'][timeIndex][ch]];

              for (let d = 0, lend = distribution.length; d < lend; d++) {
                if (!dataTable[dim]['values'][0][d + 1]) {
                  dataTable[dim]['values'][0][d + 1] = 0;
                }
                if (!dataTable[dim]['values'][1][d + 1]) {
                  dataTable[dim]['values'][1][d + 1] = 0;
                }
                if (!dataTable[dim]['values'][2][d + 1]) {
                  dataTable[dim]['values'][2][d + 1] = 0;
                }
                if (!dataTable[dim]['values'][3][d + 1]) {
                  dataTable[dim]['values'][3][d + 1] = 0;
                }
                if (!dataTable[dim]['values'][4][d + 1]) {
                  dataTable[dim]['values'][4][d + 1] = 0;
                }

                const date = new Date(Number(data['distributions'][timeIndex][ch].key));

                if (isToday(date)) {
                  dataTable[dim]['values'][0][d + 1] += distribution[d]['value'];
                }
                if (isYesterday(date)) {
                  dataTable[dim]['values'][1][d + 1] += distribution[d]['value'];
                }
                if (inLast7Days(date)) {
                  dataTable[dim]['values'][2][d + 1] += distribution[d]['value'];
                }
                if (inLast30Days(date)) {
                  dataTable[dim]['values'][3][d + 1] += distribution[d]['value'];
                }
                if (inLastYear(date)) {
                  dataTable[dim]['values'][4][d + 1] += distribution[d]['value'];
                }
              }
            }
          }
        } else if (
          (chartType === Charts.TRENDCATEGORICALCHART || (chartType === Charts.TEXTEMOTIONS && !anonymityTreshold)) &&
          timeIndex > -1
        ) {
          const catIndexes = data.domain
            .filter(
              (dom) =>
                dom.scale === 'categorical' &&
                !(data.comparison && dom.key === data.comparison.key) &&
                !dom.key.endsWith(':words'),
            )
            .map((dom) => data.domain.findIndex((domain) => domain.key === dom.key));
          const comparisonIndex = data.comparison
            ? data.domain.findIndex((dom) => dom.key === data.comparison.key)
            : -1;

          if (data.comparison) {
            const cdi = data.comparison.index;
            for (let s = 0, lent = (data.distributions[timeIndex] || []).length; s < lent; s++) {
              const time = new Date(Number(data.distributions[timeIndex][s]['key'])).toLocaleDateString();
              dataTable[s] = { values: [], headers: [time, $localize`:@@zef-i18n-00299:Respondents`, '%'] };
              for (let cidx = 0, lencidx = data.domain[comparisonIndex].keys.length; cidx < lencidx; cidx++) {
                for (let cat = 0, lencat = catIndexes.length; cat < lencat; cat++) {
                  const catIndex = catIndexes[cat];

                  for (let ch = 0, lench = data.domain[catIndex].keys.length; ch < lench; ch++) {
                    let value;
                    let percentage = null;
                    const text =
                      data.domain[catIndex].labels[data.domain[catIndex].keys[ch]] +
                      ` (${data.domain[comparisonIndex].labels[data.domain[comparisonIndex].keys[cidx]]})`;

                    if (
                      data.distributions &&
                      data.distributions[cdi] &&
                      data.distributions[cdi][cidx] &&
                      data.distributions[cdi][cidx][c] &&
                      data.distributions[cdi][cidx][c][s] &&
                      data.distributions[cdi][cidx][c][s][c] &&
                      data.distributions[cdi][cidx][c][s][c][ch] &&
                      data.distributions[cdi][cidx][c][s][c][ch]
                    ) {
                      value = data.distributions[cdi][cidx][c][s][c][ch]['value'];
                      percentage = data.distributions[cdi][cidx][c][s][c][ch]['percentage'];
                    }

                    dataTable[s]['values'].push([
                      text,
                      value != null ? value : '-',
                      percentage != null ? Math.round(percentage * 1000) / 10 + '%' : '-',
                    ]);
                  }
                }
              }
            }
          } else {
            for (let s = 0, lent = (data.distributions[timeIndex] || []).length; s < lent; s++) {
              const time = new Date(Number(data.distributions[timeIndex][s]['key'])).toLocaleDateString();
              dataTable[s] = { values: [], headers: [time, $localize`:@@zef-i18n-00299:Respondents`, '%'] };
              for (let cat = 0, lencat = catIndexes.length; cat < lencat; cat++) {
                const catIndex = catIndexes[cat];
                for (let ch = 0, lench = data.domain[catIndex].keys.length; ch < lench; ch++) {
                  let value;
                  let percentage = null;
                  const text = data.domain[catIndex].labels[data.domain[catIndex].keys[ch]];

                  if (
                    data.distributions &&
                    data.distributions[timeIndex] &&
                    data.distributions[timeIndex] &&
                    data.distributions[timeIndex][s] &&
                    data.distributions[timeIndex][s][c] &&
                    data.distributions[timeIndex][s][c][ch]
                  ) {
                    value = data.distributions[timeIndex][s][c][ch]['value'];
                    percentage = data.distributions[timeIndex][s][c][ch]['percentage'];
                  }
                  dataTable[s]['values'].push([
                    text,
                    value != null ? value : '-',
                    percentage != null ? Math.round(percentage * 1000) / 10 + '%' : '-',
                  ]);
                }
              }
            }
          }
        } else if (chartType === Charts.TRENDNPSCHART && timeIndex > -1) {
          const linearIndexes = data.domain
            .filter((dom) => dom.scale === 'linear')
            .map((dom) => data.domain.findIndex((domain) => domain.key === dom.key));
          const comparisonIndex = data.comparison
            ? data.domain.findIndex((dom) => dom.key === data.comparison.key)
            : -1;

          if (data.comparison) {
            const cdi = data.comparison.index;
            for (let s = 0, lent = (data.distributions[timeIndex] || []).length; s < lent; s++) {
              const time = new Date(Number(data.distributions[timeIndex][s]['key'])).toLocaleDateString();
              dataTable[s] = {
                values: [
                  [$localize`:@@zef-i18n-00296:Detractor`],
                  [$localize`:@@zef-i18n-00297:Passive`],
                  [$localize`:@@zef-i18n-00298:Promoter`],
                ],
                headers: [time],
              };
              for (let cidx = 0, lencidx = data.domain[comparisonIndex].keys.length; cidx < lencidx; cidx++) {
                const cText = data.domain[comparisonIndex].labels[data.domain[comparisonIndex].keys[cidx]];
                dataTable[s]['headers'].push(cText);

                for (let lin = 0, lenlin = linearIndexes.length; lin < lenlin; lin++) {
                  const linIndex = linearIndexes[lin];
                  const npsValues = [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ];

                  for (let ch = 0, lench = data.domain[linIndex].keys.length; ch < lench; ch++) {
                    let npsIndex;
                    if (lench === 11) {
                      if (Number(ch) < 7) {
                        npsIndex = 0;
                      } else if (Number(ch) >= 7 && Number(ch) < 9) {
                        npsIndex = 1;
                      } else if (Number(ch) >= 9 && Number(ch) < 11) {
                        npsIndex = 2;
                      } else {
                      }
                    } else {
                      const max = lench - 1;
                      if (Number(ch) / max <= 0.6) {
                        npsIndex = 0;
                      } else if (Number(ch) / max > 0.6 && Number(ch) / max <= 0.8) {
                        npsIndex = 1;
                      } else if (Number(ch) / max > 0.8) {
                        npsIndex = 2;
                      } else {
                      }
                    }

                    let value;
                    let percentage = null;

                    if (
                      data.distributions &&
                      data.distributions[cdi] &&
                      data.distributions[cdi][cidx] &&
                      data.distributions[cdi][cidx][c] &&
                      data.distributions[cdi][cidx][c][s] &&
                      data.distributions[cdi][cidx][c][s][c] &&
                      data.distributions[cdi][cidx][c][s][c][ch] &&
                      data.distributions[cdi][cidx][c][s][c][ch]
                    ) {
                      value = data.distributions[cdi][cidx][c][s][c][ch]['value'];
                      percentage = data.distributions[cdi][cidx][c][s][c][ch]['percentage'];
                    }

                    npsValues[npsIndex][0] += value || 0;
                    npsValues[npsIndex][1] += percentage || 0;
                  }

                  for (let i = 0, len = dataTable[s]['values'].length; i < len; i++) {
                    const npsValsText = `${npsValues[i][0]} (${Math.round(npsValues[i][1] * 1000) / 10}%)`;
                    dataTable[s]['values'][i].push(npsValsText);
                  }
                }
              }
            }
          } else {
            for (let s = 0, lent = (data.distributions[timeIndex] || []).length; s < lent; s++) {
              const time = new Date(Number(data.distributions[timeIndex][s]['key'])).toLocaleDateString();
              dataTable[s] = { values: [], headers: [time, $localize`:@@zef-i18n-00299:Respondents`, '%'] };
              for (let lin = 0, lenlin = linearIndexes.length; lin < lenlin; lin++) {
                const linIndex = linearIndexes[lin];
                const npsValues = [
                  [$localize`:@@zef-i18n-00296:Detractor`, 0, 0],
                  [$localize`:@@zef-i18n-00297:Passive`, 0, 0],
                  [$localize`:@@zef-i18n-00298:Promoter`, 0, 0],
                ];
                for (let ch = 0, lench = data.domain[linIndex].keys.length; ch < lench; ch++) {
                  let npsIndex;
                  if (lench === 11) {
                    if (Number(ch) < 7) {
                      npsIndex = 0;
                    } else if (Number(ch) >= 7 && Number(ch) < 9) {
                      npsIndex = 1;
                    } else if (Number(ch) >= 9 && Number(ch) < 11) {
                      npsIndex = 2;
                    } else {
                    }
                  } else {
                    const max = lench - 1;
                    if (Number(ch) / max <= 0.6) {
                      npsIndex = 0;
                    } else if (Number(ch) / max > 0.6 && Number(ch) / max <= 0.8) {
                      npsIndex = 1;
                    } else if (Number(ch) / max > 0.8) {
                      npsIndex = 2;
                    } else {
                    }
                  }

                  let value;
                  let percentage = null;

                  if (
                    data.distributions &&
                    data.distributions[timeIndex] &&
                    data.distributions[timeIndex] &&
                    data.distributions[timeIndex][s] &&
                    data.distributions[timeIndex][s][c] &&
                    data.distributions[timeIndex][s][c][ch]
                  ) {
                    value = data.distributions[timeIndex][s][c][ch]['value'];
                    percentage = data.distributions[timeIndex][s][c][ch]['percentage'];
                  }

                  npsValues[npsIndex][1] += value || 0;
                  npsValues[npsIndex][2] += percentage || 0;
                }
                dataTable[s]['values'] = dataTable[s]['values'].concat(
                  (dataTable[s]['values'] = npsValues.map((item) => {
                    item[2] = Math.round(Number(item[2]) * 1000) / 10 + '%';
                    return item;
                  })),
                );
              }
            }
          }
        } else if (
          (chartType === Charts.TRENDLINEAR1DCHART || chartType === Charts.TRENDLINEAR2DCHART) &&
          timeIndex > -1
        ) {
          const linearIndexes = data.domain
            .filter((dom) => dom.scale === 'linear')
            .map((dom) => data.domain.findIndex((domain) => domain.key === dom.key));
          const timeStatIndex = data.domain[timeIndex]['index'];
          const groupSeparator: string = '\u001D';

          if (data.comparison) {
            const cdi = data.comparison.index;
            for (let s = 0, lent = (data.stats[timeIndex][c] || []).length; s < lent; s++) {
              if (data.stats[timeIndex][c][s]) {
                const time = data.stats[timeIndex][c][s]['key'];
                const timeText = new Date(Number(data.stats[timeIndex][c][s]['key'])).toLocaleDateString();
                const axisAverages = {};
                const values = data.domain
                  .filter((dom) => dom['key'] !== data.comparison.key && dom['scale'] === 'linear')
                  .map((dom) => [questionTitle(dom.key)]);

                dataTable[s] = {
                  values: [],
                  headers: [timeText],
                };
                for (let cid = 0, lencid = data.comparison.values.length; cid < lencid; cid++) {
                  const cidx = data.comparison.values[cid];
                  const cidxKey =
                    data.stats && data.stats[cdi] && data.stats[cdi][c] && data.stats[cdi][c][cidx]
                      ? data.stats[cdi][c][cidx]['key']
                      : '';
                  const chTitle = choiceLabel(data.comparison.key, cidxKey);
                  dataTable[s]['headers'].push(chTitle);

                  const idx =
                    data.stats &&
                    data.stats[cdi] &&
                    data.stats[cdi][c] &&
                    data.stats[cdi][c][cidx] &&
                    data.stats[cdi][c][cidx][c] &&
                    data.stats[cdi][c][cidx][c][timeStatIndex] &&
                    data.stats[cdi][c][cidx][c][timeStatIndex][c]
                      ? data.stats[cdi][c][cidx][c][timeStatIndex][c].findIndex((item) => item && item.key === time)
                      : -1;
                  for (let lin = 0, lenlin = linearIndexes.length; lin < lenlin; lin++) {
                    const linIndex = linearIndexes[lin];
                    const linDomIndex = data.domain[linIndex]['index'];
                    let avg;
                    let std;
                    let count = null;
                    const text = questionTitle(data.domain[linIndex]['key']);
                    const currentRow = values.findIndex((row) => row[0] === text);

                    if (
                      data.stats &&
                      data.stats[cdi] &&
                      data.stats[cdi][c] &&
                      data.stats[cdi][c][cidx] &&
                      data.stats[cdi][c][cidx][c] &&
                      data.stats[cdi][c][cidx][c][timeStatIndex] &&
                      data.stats[cdi][c][cidx][c][timeStatIndex][c] &&
                      data.stats[cdi][c][cidx][c][timeStatIndex][c][idx] &&
                      data.stats[cdi][c][cidx][c][timeStatIndex][c][idx][c] &&
                      data.stats[cdi][c][cidx][c][timeStatIndex][c][idx][c][linDomIndex]
                    ) {
                      avg = data.stats[cdi][c][cidx][c][timeStatIndex][c][idx][c][linDomIndex]['average'];
                      std = data.stats[cdi][c][cidx][c][timeStatIndex][c][idx][c][linDomIndex]['std'];
                      count = data.stats[cdi][c][cidx][c][timeStatIndex][c][idx][c][linDomIndex]['count'];
                    }
                    const avgString = avg != null ? avg.toFixed(1) : '-';
                    const sdString = std != null ? std.toFixed(1) : '-';

                    values[currentRow].push(`${avgString} (σ=${sdString}) (n=${count})`);

                    // Axis averages
                    const axis: string = axisLabel(data.domain[linIndex]['key']) + groupSeparator + cidxKey;

                    if (!axisAverages[axis]) {
                      axisAverages[axis] = {};
                    }

                    axisAverages[axis][data.domain[linIndex]['key']] = {
                      count,
                      average: avg,
                      std: std,
                    };
                  }
                }
                dataTable[s]['values'] = dataTable[s]['values'].concat(values);
                if (Object.keys(axisAverages).length > 0) {
                  const values = Array.from(
                    new Set(
                      Object.keys(axisAverages).map((axis) => $localize`Total` + ': ' + axis.split(groupSeparator)[0]),
                    ),
                  ).map((axis) => [axis]);
                  for (const axis in axisAverages) {
                    let weightedSum: number = 0;
                    let varNumerator: number = 0;
                    let count: number = 0;

                    for (const item in axisAverages[axis]) {
                      if (axisAverages[axis][item]['average'] != null && axisAverages[axis][item]['count'] > 0) {
                        weightedSum += axisAverages[axis][item]['average'] * axisAverages[axis][item]['count'];
                        count += axisAverages[axis][item]['count'];
                      }
                    }

                    if (count > 0) {
                    }
                    const average = count > 0 ? weightedSum / count : null;

                    if (average != null) {
                      for (const item in axisAverages[axis]) {
                        if (axisAverages[axis][item]['average'] != null && axisAverages[axis][item]['count'] > 0) {
                          varNumerator +=
                            axisAverages[axis][item]['count'] * Math.pow(axisAverages[axis][item]['std'], 2) + 0;
                          varNumerator +=
                            axisAverages[axis][item]['count'] *
                              Math.pow(axisAverages[axis][item]['average'] - average, 2) +
                            0;
                        }
                      }
                    }

                    const std = count > 0 ? Math.sqrt(varNumerator / count) : null;
                    const averageString = average != null ? average.toFixed(1) : '-';
                    const stdString = std != null ? std.toFixed(1) : '-';
                    const rowIndex = values.findIndex(
                      (row) => row[0].replace($localize`Total` + ': ', '') === axis.split(groupSeparator)[0],
                    );

                    if (data.comparison && data.comparison.index != null) {
                      values[rowIndex].push(`${averageString} (σ=${stdString}) (n=${count})`);
                    } else {
                      values[rowIndex].push(count.toString(), averageString, stdString);
                    }
                  }

                  dataTable[s]['values'] = dataTable[s]['values'].concat(values);
                }
              }
            }
          } else {
            for (let s = 0, lent = (data.stats[timeIndex][c] || []).length; s < lent; s++) {
              if (data.stats[timeIndex][c][s]) {
                const timeText = new Date(Number(data.stats[timeIndex][c][s]['key'])).toLocaleDateString();
                const axisAverages = {};

                dataTable[s] = {
                  values: [],
                  headers: [
                    timeText,
                    $localize`:@@zef-i18n-00299:Respondents`,
                    $localize`:@@zef-i18n-00294:Average`,
                    $localize`:@@zef-i18n-00295:Standard Deviation`,
                  ],
                };

                for (let lin = 0, lenlin = linearIndexes.length; lin < lenlin; lin++) {
                  const linIndex = linearIndexes[lin];
                  const linDomIndex = data.domain[linIndex]['index'];
                  let avg;
                  let sd;
                  let count = null;
                  const text = questionTitle(data.domain[linIndex]['key']);

                  if (
                    data.stats &&
                    data.stats[timeIndex] &&
                    data.stats[timeIndex][c] &&
                    data.stats[timeIndex][c][s] &&
                    data.stats[timeIndex][c][s][c] &&
                    data.stats[timeIndex][c][s][c][linDomIndex]
                  ) {
                    avg = data.stats[timeIndex][c][s][c][linDomIndex]['average'];
                    sd = data.stats[timeIndex][c][s][c][linDomIndex]['std'];
                    count = data.stats[timeIndex][c][s][c][linDomIndex]['count'];
                  }
                  dataTable[s]['values'].push([
                    text,
                    count > 0 ? count : '-',
                    avg != null ? Math.round(avg * 10) / 10 : '-',
                    sd != null ? Math.round(sd * 10) / 10 : '-',
                  ]);

                  // Axis averages
                  const axis: string = axisLabel(data.domain[linIndex]['key']);

                  if (!axisAverages[axis]) {
                    axisAverages[axis] = {};
                  }

                  axisAverages[axis][data.domain[linIndex]['key']] = {
                    count,
                    average: avg,
                    std: sd,
                  };
                }
                if (Object.keys(axisAverages).length > 0) {
                  for (const axis in axisAverages) {
                    let weightedSum: number = 0;
                    let varNumerator: number = 0;
                    let count: number = 0;

                    for (const item in axisAverages[axis]) {
                      if (axisAverages[axis][item]['average'] != null && axisAverages[axis][item]['count'] > 0) {
                        weightedSum += axisAverages[axis][item]['average'] * axisAverages[axis][item]['count'];
                        count += axisAverages[axis][item]['count'];
                      }
                    }

                    if (count > 0) {
                    }
                    const average = count > 0 ? weightedSum / count : null;

                    if (average != null) {
                      for (const item in axisAverages[axis]) {
                        if (axisAverages[axis][item]['average'] != null && axisAverages[axis][item]['count'] > 0) {
                          varNumerator +=
                            axisAverages[axis][item]['count'] * Math.pow(axisAverages[axis][item]['std'], 2) + 0;
                          varNumerator +=
                            axisAverages[axis][item]['count'] *
                              Math.pow(axisAverages[axis][item]['average'] - average, 2) +
                            0;
                        }
                      }
                    }

                    const std = count > 0 ? Math.sqrt(varNumerator / count) : null;

                    dataTable[s]['values'].push([
                      `${$localize`Total`}: ${axis}`,
                      count > 0 ? count : '-',
                      average != null ? average.toFixed(1) : '-',
                      std != null ? std.toFixed(1) : '-',
                    ]);
                  }
                }
              }
            }
          }
        } else if (chartType === Charts.TEXTEMOTIONS) {
          const dim = data.details.findIndex((det) => det.originalTypeSpecifier === 'text-emotions');
          const domain = data.domain[dim];
          const distribution = data.distributions[dim];
          const totalAnswers = data.totalAnswers && data.totalAnswers[dim];

          dataTable[0] = { values: [], headers: [domain && domain.title] };
          dataTable[0]['headers'].push($localize`:@@zef-i18n-00299:Respondents`);
          dataTable[0]['headers'].push('%');

          for (const val of distribution) {
            const key = domain.labels[val.key];
            const value = val.value;

            dataTable[0]['values'].push([key, value, ((value / totalAnswers) * 100).toFixed(1) + '%']);
          }
        } else {
          for (const dim in data.details) {
            const details = data.details[dim];
            const domain = data.domain[dim];
            const distribution = data.distributions[dim];
            const totalAnswers = data.totalAnswers && data.totalAnswers[dim];

            dataTable[dim] = { values: [], headers: [domain && domain.title] };

            if (details && domain && distribution) {
              if (details.scale === 'linear') {
                const labels = this.setLinearLabels(
                  details.valueScaleLinear,
                  this.setLinearClasses(details.valueScaleLinear),
                  details.values,
                );
                dataTable[dim]['headers'][0] =
                  dataTable[dim]['headers'][0] + (domain.labelsLinear.axis ? ` (${domain.labelsLinear.axis})` : '');
                dataTable[dim]['headers'].push($localize`:@@zef-i18n-00299:Respondents`);
                dataTable[dim]['headers'].push('%');
                for (let v = 0, len = distribution.length; v < len; v++) {
                  const val = distribution[v];
                  const key = labels[val.key];
                  const value = val.value;

                  dataTable[dim]['values'].push([key, value, ((value / totalAnswers) * 100).toFixed(1) + '%']);
                }
              } else if (details.scale === 'categorical') {
                dataTable[dim]['headers'].push($localize`:@@zef-i18n-00299:Respondents`);
                dataTable[dim]['headers'].push('%');

                for (const val of distribution) {
                  const key = domain.labels[val.key];
                  const value = val.value;

                  dataTable[dim]['values'].push([key, value, ((value / totalAnswers) * 100).toFixed(1) + '%']);
                }
              } else if (details.scale === 'time') {
                dataTable[dim]['headers'].push($localize`:@@zef-i18n-00299:Respondents`);
                dataTable[dim]['headers'].push('%');
                dataTable[dim]['values'] = [
                  [$localize`:@@zef-i18n-00334:Today`, 0],
                  [$localize`:@@zef-i18n-00335:Yesterday`, 0],
                  [$localize`:@@zef-i18n-00336:Last 7 days`, 0],
                  [$localize`:@@zef-i18n-00337:Last 30 days`, 0],
                  [$localize`:@@zef-i18n-00338:Last 365 days`, 0],
                ];

                for (const i in distribution) {
                  const date = new Date(Number(distribution[i].key));

                  if (isToday(date)) {
                    dataTable[dim]['values'][0][1] += distribution[i]['value'];
                  }
                  if (isYesterday(date)) {
                    dataTable[dim]['values'][1][1] += distribution[i]['value'];
                  }
                  if (inLast7Days(date)) {
                    dataTable[dim]['values'][2][1] += distribution[i]['value'];
                  }
                  if (inLast30Days(date)) {
                    dataTable[dim]['values'][3][1] += distribution[i]['value'];
                  }
                  if (inLastYear(date)) {
                    dataTable[dim]['values'][4][1] += distribution[i]['value'];
                  }
                }

                for (const value of dataTable[dim]['values']) {
                  value[2] = ((value[1] / totalAnswers) * 100).toFixed(1) + '%';
                }
              } else {
                // dataTable[dim] = {'values': [], 'content': { 'title': details.title } };
              }
            }
          }
        }
        observer.next({ data: dataTable });
        observer.complete();
      } else if (chartType === Charts.RESPONSERATES && dataService) {
        dataTable[0] = {
          values: [],
          headers: [$localize`Share`, $localize`Total`, $localize`Clicked`, $localize`Answered`],
        };

        dataService
          .getResponseRates()
          ?.pipe(first())
          ?.subscribe((responseRates) => {
            const totalNumbers = { n: 0, clicked: 0, answered: 0 };
            const statGenFunc = (val, total) => `${val} (${Math.round((val / (total || 1)) * 1000) / 10}%)`;
            responseRates
              ?.filter((rate) => rate.n)
              ?.forEach((rate) => {
                const index = data.domain.findIndex((dom) => dom.key === 'responseRates');
                const title = data.domain?.[index]?.labels?.[rate.key];
                const total = rate.n || 0;
                const clickedNumber = rate.clicked || 0;
                const answeredNumber = rate.answered || 0;
                const clicked = statGenFunc(clickedNumber, total || 1);
                const answered = statGenFunc(answeredNumber, total || 1);

                dataTable[0]['values'].push([title, total, clicked, answered]);
                totalNumbers.n += total;
                totalNumbers.clicked += clickedNumber;
                totalNumbers.answered += answeredNumber;
              });

            dataTable[0]['values'].push([
              $localize`Total`,
              totalNumbers.n,
              statGenFunc(totalNumbers.clicked, totalNumbers.n),
              statGenFunc(totalNumbers.answered, totalNumbers.n),
            ]);

            observer.next({ data: dataTable });
            observer.complete();
          });
      } else {
        observer.next({ data: dataTable });
        observer.complete();
      }
    });
  }

  public calculateNPS(distribution: ChartDistribution[], domain: ChartDomain | null = null): NPSData[] {
    const npsDistributions: any[] = [];
    const originalDistributions: any[] = [];
    const valueTotals: number[] = [];
    const titles: string[] = [];
    const colors: number[] = [];
    const keys: string[] = [];

    const npsDistr = (data) => {
      let valueTotal: number = 0;
      const npsDistribution: NPSDistribution[] = [
        {
          key: 'detractor',
          label: $localize`:@@zef-i18n-00296:Detractor`,
          originalValues: [] as string[],
          value: 0,
          cumulativeValue: 0,
          percentage: 0,
          cumulativePercentage: 0,
        },
        {
          key: 'passive',
          label: $localize`:@@zef-i18n-00297:Passive`,
          originalValues: [] as string[],
          value: 0,
          cumulativeValue: 0,
          percentage: 0,
          cumulativePercentage: 0,
        },
        {
          key: 'promoter',
          label: $localize`:@@zef-i18n-00298:Promoter`,
          originalValues: [] as string[],
          value: 0,
          cumulativeValue: 0,
          percentage: 0,
          cumulativePercentage: 0,
        },
      ];

      const len: number = data.length;

      if (len === 11) {
        for (let d = 0; d < len; d++) {
          if (d < 7) {
            npsDistribution[0]['value'] += data[d]['value'];
            npsDistribution[0]['originalValues'].push(d.toString());
          } else if (d >= 7 && d < 9) {
            npsDistribution[1]['value'] += data[d]['value'];
            npsDistribution[1]['originalValues'].push(d.toString());
          } else if (d >= 9 && d < 11) {
            npsDistribution[2]['value'] += data[d]['value'];
            npsDistribution[2]['originalValues'].push(d.toString());
          } else {
          }
          valueTotal += data[d]['value'];
        }
      } else {
        const max: number = len - 1;
        for (let d = 0; d < len; d++) {
          if (d / max <= 0.6) {
            npsDistribution[0]['value'] += data[d]['value'];
            npsDistribution[0]['originalValues'].push(d.toString());
          } else if (d / max > 0.6 && d / max <= 0.8) {
            npsDistribution[1]['value'] += data[d]['value'];
            npsDistribution[1]['originalValues'].push(d.toString());
          } else if (d / max > 0.8) {
            npsDistribution[2]['value'] += data[d]['value'];
            npsDistribution[2]['originalValues'].push(d.toString());
          } else {
          }
          valueTotal += data[d]['value'];
        }
      }

      return { distribution: npsDistribution, valueTotal };
    };

    if (domain != null) {
      for (const item of domain.keys) {
        const distrItem: ChartDistribution = distribution.find((d) => d.key === item);
        const distr: DistributionData = distrItem ? distrItem.children : [];
        const npsCalc: { distribution: NPSDistribution[]; valueTotal: number } = npsDistr(distr);
        const title: string = domain['labels'][item];
        const color: number = domain['colors'][item];

        originalDistributions.push(distr);
        npsDistributions.push(npsCalc.distribution);
        valueTotals.push(npsCalc.valueTotal);
        titles.push(title);
        colors.push(color);
        keys.push(item);
      }
    } else {
      const npsCalc: { distribution: NPSDistribution[]; valueTotal: number } = npsDistr(distribution);

      originalDistributions.push(distribution);
      npsDistributions.push(npsCalc.distribution);
      valueTotals.push(npsCalc.valueTotal);
      titles.push('');
    }

    const NPSdata: NPSData[] = [];

    for (let i = 0; i < npsDistributions.length; i++) {
      for (let item = 0, len = npsDistributions[i].length; item < len; item++) {
        npsDistributions[i][item].percentage =
          valueTotals[i] > 0 ? npsDistributions[i][item].value / valueTotals[i] : null;
        npsDistributions[i][item].cumulativeValue = npsDistributions[i].reduce(function (sum, val, index) {
          if (index < item) {
            sum += val.value;
          }
          return sum;
        }, 0);
        npsDistributions[i][item].cumulativePercentage = npsDistributions[i].reduce(function (sum, val, index) {
          if (index < item) {
            sum += val.percentage;
          }
          return sum;
        }, 0);
      }
      NPSdata[i] = {
        title: titles[i],
        distribution: npsDistributions[i],
        originalDistribution: originalDistributions[i],
        npsScore:
          npsDistributions[i][2]['percentage'] != null && npsDistributions[i][2]['percentage'] != null
            ? Math.round(npsDistributions[i][2]['percentage'] * 100 - npsDistributions[i][0]['percentage'] * 100)
            : NaN,
        color: colors[i],
        colorScale: colors[i] != null ? [Colors.getComparisonColor(colors[i])] : Colors.NPS,
        count: valueTotals[i],
        index: i,
        key: keys[i],
      };
    }

    return NPSdata;
  }
}

function isToday(date: Date): boolean {
  const now = new Date();

  return (
    now.getFullYear() + '-' + now.getMonth() + '-' + now.getDate() ===
    date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate()
  );
}

function isYesterday(date: Date): boolean {
  const yesterday = new Date(+new Date() - 1000 * 60 * 60 * 24);

  return (
    yesterday.getFullYear() + '-' + yesterday.getMonth() + '-' + yesterday.getDate() ===
    date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate()
  );
}

function inLast7Days(date: Date): boolean {
  const sevendaysago = new Date(+new Date(new Date().toDateString()) - 1000 * 60 * 60 * 24 * 7);

  return date > sevendaysago;
}

function inLast30Days(date: Date): boolean {
  const yearago = new Date(+new Date(new Date().toDateString()) - 1000 * 60 * 60 * 24 * 30);

  return date > yearago;
}

function inLastYear(date: Date): boolean {
  const yearago = new Date(+new Date(new Date().toDateString()) - 1000 * 60 * 60 * 24 * 365);

  return date > yearago;
}
