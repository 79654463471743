<div class="z-fx-col z-fx-gap-16 container">
  <div class="z-fx-none z-fx z-fx-center-center mode-selector">
    <div class="zef-tabs-icon">
      <button
        mat-icon-button
        [class.active]="chartMode === charts.TEXTANSWERTABLE"
        (click)="changeChartMode(charts.TEXTANSWERTABLE)"
        [matTooltip]="charts.ChartNames[charts.TEXTANSWERTABLE]"
      >
        <mat-icon>{{ charts.ChartIcons[charts.TEXTANSWERTABLE] }}</mat-icon>
      </button>
      <button
        *ngIf="hasLangTech"
        mat-icon-button
        [class.active]="chartMode === charts.WORDANALYSIS"
        (click)="changeChartMode(charts.WORDANALYSIS)"
        [matTooltip]="charts.ChartNames[charts.WORDANALYSIS]"
      >
        <mat-icon>{{ charts.ChartIcons[charts.WORDANALYSIS] }}</mat-icon>
      </button>
      <button
        *ngIf="hasLangTech"
        mat-icon-button
        [class.active]="chartMode === charts.TEXTSENTIMENTANALYSIS"
        (click)="changeChartMode(charts.TEXTSENTIMENTANALYSIS)"
        [matTooltip]="charts.ChartNames[charts.TEXTSENTIMENTANALYSIS]"
      >
        <mat-icon>{{ charts.ChartIcons[charts.TEXTSENTIMENTANALYSIS] }}</mat-icon>
      </button>
      <button
        *ngIf="hasEmotions"
        mat-icon-button
        [class.active]="chartMode === charts.TEXTEMOTIONS"
        (click)="changeChartMode(charts.TEXTEMOTIONS)"
        [matTooltip]="charts.ChartNames[charts.TEXTEMOTIONS]"
      >
        <mat-icon>{{ charts.ChartIcons[charts.TEXTEMOTIONS] }}</mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="details.length; else nothing" class="z-fx-auto chart" [ngSwitch]="chartMode">
    <!-- Text sentiment analysis -->
    <text-sentiment-analysis
      *ngSwitchCase="charts.TEXTSENTIMENTANALYSIS"
      class="text-sentiment-analysis"
      [style.height]="size"
      [details]="content?.details ? content?.details : []"
      [domain]="content?.domain"
      [distributions]="content?.distributions"
      [stats]="content?.stats"
      [filterInput]="content?.filters ? content?.filters : []"
      [crossfilter]="crossfilter"
      [filtering]="filtering"
      [anonymityLock]="anonymityStatus?.atRisk"
      [size]="size"
      [isSharedReport]="isSharedReport"
      [comparison]="content.comparison"
      [trend]="content.trend"
      [trendHoverInput]="trendHoverInput"
      [update]="update?.valueOf()"
      [transitionDuration]="transition"
      [totalAnswers]="content.totalAnswers"
      [selectionExists]="selectionExists"
      [filtersDemo]="filtersDemo"
      [touchDevice]="touchDevice"
      [chartSettings]="settings"
      [anonymityTreshold]="anonymityTreshold"
      (settingsChange)="settingsChange.emit($event)"
      (hover)="trendHoverOutput.emit($event)"
    ></text-sentiment-analysis>

    <!-- Text emotions -->
    <text-emotions
      *ngSwitchCase="charts.TEXTEMOTIONS"
      class="text-emotions"
      [style.height]="size"
      [details]="content?.details"
      [domain]="content?.domain"
      [distributions]="content?.distributions"
      [stats]="content?.stats"
      [filterInput]="content?.filters"
      [crossfilter]="crossfilter"
      [filtering]="filtering"
      [anonymityLock]="anonymityStatus?.atRisk"
      [size]="size"
      [isSharedReport]="isSharedReport"
      [comparison]="content?.comparison"
      [trend]="content.trend"
      [trendHoverInput]="trendHoverInput"
      [update]="update?.valueOf()"
      [transitionDuration]="transition"
      [totalAnswers]="content.totalAnswers"
      [selectionExists]="selectionExists"
      [filtersDemo]="filtersDemo"
      [touchDevice]="touchDevice"
      [chartSettings]="settings"
      [anonymityTreshold]="anonymityTreshold"
      (settingsChange)="settingsChange.emit($event)"
      (hover)="trendHoverOutput.emit($event)"
    ></text-emotions>

    <!-- Text answer table -->
    <text-answer-table
      *ngSwitchCase="charts.TEXTANSWERTABLE"
      class="z-fx-col z-fx-gap-8 text-answer-table"
      [style.height]="size"
      [details]="details"
      [domain]="domain"
      [filterInput]="filters"
      [crossfilter]="crossfilter"
      [filtering]="filtering"
      [anonymityLock]="anonymityStatus?.atRisk"
      [size]="size"
      [isSharedReport]="isSharedReport"
      [comparison]="content.comparison"
      [update]="update?.valueOf()"
      [chartSettings]="settings"
      [anonymityTreshold]="anonymityTreshold"
      (settingsChange)="settingsChange.emit($event)"
    ></text-answer-table>

    <!-- Word analysis -->
    <ng-container *ngSwitchCase="charts.WORDANALYSIS">
      <word-analysis
        *ngIf="!trend; else notSupported"
        class="word-analysis"
        [style.height]="size"
        [details]="content?.details ? content?.details : []"
        [domain]="content?.domain"
        [distributions]="content?.distributions"
        [stats]="content?.stats"
        [filterInput]="content?.filters ? content?.filters : []"
        [crossfilter]="crossfilter"
        [filtering]="filtering"
        [anonymityLock]="anonymityStatus?.atRisk"
        [size]="size"
        [isSharedReport]="isSharedReport"
        [comparison]="content.comparison"
        [trend]="content.trend"
        [trendHoverInput]="trendHoverInput"
        [update]="update?.valueOf()"
        [transitionDuration]="transition"
        [totalAnswers]="content.totalAnswers"
        [selectionExists]="selectionExists"
        [filtersDemo]="filtersDemo"
        [touchDevice]="touchDevice"
        [chartSettings]="settings"
        [anonymityTreshold]="anonymityTreshold"
        (settingsChange)="settingsChange.emit($event)"
        (hover)="trendHoverOutput.emit($event)"
      ></word-analysis>
    </ng-container>
  </div>
</div>

<ng-template #nothing>
  <div class="z-fx-col nothing">
    <div class="z-fx-col z-fx-auto z-fx-start-center">
      <div class="z-fx-col z-fx-end-center image">
        <img src="assets/decos/art-nothing.png" height="48px" width="48px" class="art-nothing" alt="nothing" />
      </div>
      <div class="description" i18n>Looks like there is no data to show.</div>
    </div>
  </div>
</ng-template>

<ng-template #notSupported>
  <div class="z-fx-col nothing">
    <div class="z-fx-col z-fx-auto z-fx-start-center">
      <div class="z-fx-col z-fx-end-center image">
        <img src="assets/decos/art-nothing.png" height="48px" width="48px" class="art-nothing" alt="nothing" />
      </div>
      <div class="zef-text-align-center description" i18n>Word Cloud do not support Trend analysis</div>
    </div>
  </div>
</ng-template>
