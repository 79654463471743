/**
 * Analysis chart types.
 *
 * @unstable
 */

export class Charts {
  // Question categories

  static readonly BARCHARTH: string = 'BarChartH';
  static readonly BARCHARTV: string = 'BarChartV';

  static readonly BARCHARTHPLUSTEXT: string = 'BarChartHPlusText';

  static readonly AREACHART: string = 'AreaChart';
  static readonly LINECHART: string = 'LineChart';

  static readonly DONUTCHART: string = 'DonutChart';
  static readonly PIECHART: string = 'PieChart';
  static readonly MULTIDONUTCHART: string = 'MultiDonutChart';

  static readonly WORDCLOUD: string = 'WordCloud';

  static readonly HEATMAP: string = 'heatmap';

  static readonly NPS: string = 'nps';

  static readonly RADARCHART: string = 'RadarChart';

  static readonly MULTIBARCHARTH: string = 'MultiBarChartH';
  static readonly MULTIBARCHARTV: string = 'MultiBarChartV';

  static readonly MULTILINECHART: string = 'MultiLineChart';

  static readonly STACKEDPIECHART: string = 'StackedPieChart';
  static readonly STACKEDBARCHARTH: string = 'StackedBarChartH';
  static readonly STACKEDBARCHARTV: string = 'StackedBarChartV';

  static readonly SUMMARY1D: string = 'summary1d';
  static readonly SUMMARY2D: string = 'summary2d';
  static readonly SUMMARYCHOICE: string = 'summaryChoice';

  static readonly INTERVIEWERSUMMARY: string = 'interviewer-summary';

  static readonly WHYFINDERSUMMARY: string = 'why-finder-summary';

  static readonly RESPONSERATES: string = 'response-rates';

  static readonly TEXTANSWERTABLE: string = 'text-answer-table';
  static readonly SMARTTEXTCHART: string = 'smart-text-chart';
  static readonly TEXTSENTIMENTANALYSIS: string = 'text-sentiment-analysis';
  static readonly TEXTEMOTIONS: string = 'text-emotions';
  static readonly WORDANALYSIS: string = 'word-analysis';

  static readonly TIMELINECHART: string = 'timelineChart';

  static readonly TRENDCATEGORICALCHART: string = 'trendCategoricalChart';
  static readonly TRENDNPSCHART: string = 'trendNPSChart';
  static readonly TRENDLINEAR1DCHART: string = 'trendLinear1dChart';
  static readonly TRENDLINEAR2DCHART: string = 'trendLinear2dChart';

  static readonly CHARTTYPES: string[] = [
    Charts.BARCHARTH,
    Charts.BARCHARTV,
    Charts.BARCHARTHPLUSTEXT,
    Charts.AREACHART,
    Charts.LINECHART,
    Charts.DONUTCHART,
    Charts.PIECHART,
    Charts.MULTIDONUTCHART,
    Charts.WORDCLOUD,
    Charts.HEATMAP,
    Charts.MULTILINECHART,
    Charts.MULTIBARCHARTH,
    Charts.MULTIBARCHARTV,
    Charts.STACKEDPIECHART,
    Charts.STACKEDBARCHARTH,
    Charts.STACKEDBARCHARTV,
    Charts.SUMMARY1D,
    Charts.SUMMARY2D,
    Charts.SUMMARYCHOICE,
    Charts.SMARTTEXTCHART,
    Charts.TEXTSENTIMENTANALYSIS,
    Charts.TEXTANSWERTABLE,
    Charts.TEXTEMOTIONS,
    Charts.WORDANALYSIS,
    Charts.INTERVIEWERSUMMARY,
    Charts.WHYFINDERSUMMARY,
    Charts.NPS,
    Charts.RADARCHART,
    Charts.RESPONSERATES,
    Charts.TIMELINECHART,
    Charts.TRENDCATEGORICALCHART,
    Charts.TRENDNPSCHART,
    Charts.TRENDLINEAR1DCHART,
    Charts.TRENDLINEAR2DCHART,
  ];

  static readonly DISTRIBUTIONCHARTS: string[] = [
    Charts.BARCHARTH,
    Charts.BARCHARTV,
    Charts.AREACHART,
    Charts.LINECHART,
    Charts.DONUTCHART,
    Charts.PIECHART,
    Charts.MULTIDONUTCHART,
    Charts.WORDCLOUD,
  ];

  static readonly COMPARISONCHARTS: string[] = [
    Charts.BARCHARTHPLUSTEXT,
    Charts.HEATMAP,
    Charts.MULTILINECHART,
    Charts.MULTIBARCHARTH,
    Charts.MULTIBARCHARTV,
    Charts.STACKEDPIECHART,
    Charts.STACKEDBARCHARTH,
    Charts.STACKEDBARCHARTV,
    Charts.SUMMARY1D,
    Charts.SUMMARY2D,
    Charts.SUMMARYCHOICE,
    Charts.WORDANALYSIS,
    Charts.TEXTSENTIMENTANALYSIS,
    Charts.TEXTANSWERTABLE,
    Charts.TEXTEMOTIONS,
    Charts.INTERVIEWERSUMMARY,
    Charts.NPS,
    Charts.RADARCHART,
    Charts.RESPONSERATES,
  ];

  // Charts that aren't used on default
  static readonly SUMMARYCHARTS: string[] = [Charts.SUMMARY1D, Charts.SUMMARY2D];

  // Charts that aren't used on default
  static readonly TRENDCHARTS: string[] = [
    Charts.TRENDCATEGORICALCHART,
    Charts.TRENDNPSCHART,
    Charts.TRENDLINEAR1DCHART,
    Charts.TRENDLINEAR2DCHART,
  ];

  // Charts that aren't used on default
  static readonly CUSTOMCHARTS: string[] = [Charts.NPS, Charts.RADARCHART];

  // Charts used for crosstabulation
  static readonly CROSSTABCHARTS: string[] = [
    Charts.MULTIBARCHARTH,
    Charts.MULTIBARCHARTV,
    Charts.STACKEDPIECHART,
    Charts.STACKEDBARCHARTH,
    Charts.STACKEDBARCHARTV,
    Charts.MULTILINECHART,
  ];

  // Charts that can be locked
  static readonly LOCKABLECHARTS: string[] = [
    Charts.BARCHARTV,
    Charts.AREACHART,
    Charts.LINECHART,
    Charts.DONUTCHART,
    Charts.PIECHART,
    Charts.MULTIDONUTCHART,
    Charts.WORDCLOUD,
    Charts.HEATMAP,
    Charts.MULTILINECHART,
    Charts.MULTIBARCHARTV,
    Charts.STACKEDPIECHART,
    Charts.STACKEDBARCHARTV,
    Charts.TIMELINECHART,
    Charts.TRENDCATEGORICALCHART,
    Charts.TRENDNPSCHART,
  ];

  // Human readable chart names
  static readonly ChartNames: Object = {
    'BarChartH': $localize`:@@zef-i18n-00350:Horizontal Bar Chart`,
    'BarChartV': $localize`:@@zef-i18n-00351:Vertical Bar Chart`,
    'BarChartHPlusText': $localize`Horizontal Bar Chart + Text`,
    'AreaChart': $localize`:@@zef-i18n-00352:Area Chart`,
    'LineChart': $localize`:@@zef-i18n-00353:Line Chart`,
    'heatmap': $localize`:@@zef-i18n-00354:Heatmap Chart`,
    'DonutChart': $localize`:@@zef-i18n-00355:Donut Chart`,
    'MultiDonutChart': $localize`:@@zef-i18n-00356:Multiple Donuts Chart`,
    'WordCloud': $localize`:@@zef-i18n-00357:Word Cloud`,
    'PieChart': $localize`:@@zef-i18n-00358:Pie Chart`,
    'MultiBarChartH': $localize`:@@zef-i18n-00359:Multiple Horizontal Bars Chart`,
    'MultiBarChartV': $localize`:@@zef-i18n-00360:Multiple Vertical Bars Chart`,
    'MultiLineChart': $localize`:@@zef-i18n-00361:Multiple Lines Chart`,
    'StackedPieChart': $localize`:@@zef-i18n-00362:Stacked Pie Chart`,
    'StackedBarChartH': $localize`:@@zef-i18n-00363:Horizontal Stacked Bar Chart`,
    'StackedBarChartV': $localize`:@@zef-i18n-00364:Vertical Stacked Bar Chart`,
    'summary1d': $localize`:@@zef-i18n-00365:Summary Chart`,
    'summary2d': $localize`:@@zef-i18n-00366:Two Dimensional Summary Chart`,
    'summaryChoice': $localize`:@@zef-i18n-00540:Choice Summary Chart`,
    'smart-text-chart': $localize`Smart Text Chart`,
    'text-sentiment-analysis': $localize`:@@zef-i18n-00367b:Text Sentiment Analysis`,
    'text-answer-table': $localize`:@@zef-i18n-00367:Text Answers Table`,
    'word-analysis': $localize`:@@zef-i18n-00367c:Word Analysis`,
    'nps': $localize`:@@zef-i18n-00368:Net Promoter Score`,
    'RadarChart': $localize`:@@zef-i18n-00369:Radar Chart`,
    'trendCategoricalChart': $localize`:@@zef-i18n-00370:Trend Chart`,
    'trendNPSChart': $localize`:@@zef-i18n-00371:NPS Trend Chart`,
    'trendLinear1dChart': $localize`:@@zef-i18n-00372:Linear Trend Chart`,
    'trendLinear2dChart': $localize`:@@zef-i18n-00373:Two Dimensional Linear Trend Chart`,
    'timelineChart': $localize`:@@zef-i18n-00374:Timeline Chart`,
    'text-emotions': $localize`Text Emotions Chart`,
    'interviewer-summary': $localize`Interviewer Summary`,
    'why-finder-summary': $localize`AI Interview Summary`,
    'response-rates': $localize`Response Rates`,
  };

  // Icons for the question types
  static readonly ChartIcons: Object = {
    'BarChartH': 'chart_bar_horizontal',
    'BarChartV': 'chart_bar_vertical',
    'BarChartHPlusText': 'chart_bar_horizontal',
    'PieChart': 'chart_pie',
    'DonutChart': 'chart_donut',
    'MultiDonutChart': 'chart_donuts',
    'LineChart': 'chart_line',
    'heatmap': 'chart_heatmap',
    'StackedBarChartH': 'chart_stacked_horizontal',
    'StackedBarChartV': 'chart_stacked_vertical',
    'summary1d': 'chart_summary_1d',
    'summary2d': 'chart_summary_2d',
    'summaryChoice': 'chart_stacked_horizontal',
    'absolute': 'sum',
    'percentage': 'percentage',
    'WordCloud': 'chart_wordcloud',
    'MultiBarChartH': 'chart_multibar_horizontal',
    'MultiBarChartV': 'chart_multibar_vertical',
    'MultiLineChart': 'chart_multiline',
    'StackedPieChart': 'chart_pie',
    'text-sentiment-analysis': 'chart_sentiment',
    'text-emotions': 'chart_emotion',
    'smart-text-chart': 'ai',
    'text-answer-table': 'chart_table',
    'word-analysis': 'chart_wordcloud',
    'nps': 'chart_nps',
    'RadarChart': 'chart_radar',
    'trendCategoricalChart': 'chart_trend_categorical',
    'trendNPSChart': 'chart_trend_nps',
    'trendLinear1dChart': 'chart_trend_1d',
    'trendLinear2dChart': 'chart_trend_2d',
    'timelineChart': 'chart_timeline',
    'interviewer-summary': 'chart_interviewer_summary',
    'why-finder-summary': 'chart_interviewer_summary',
    'response-rates': 'funnel',
  };
}
