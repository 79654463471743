<div class="z-fx-col z-fx-auto container" [class.safe-report-on]="this.comparisonOn && !!safeReportBannedList?.length">
  <div class="z-fx-auto user-segments">
    <div class="z-fx z-fx-between-center mat-subheading-2 header">
      <div class="z-fx z-fx-start-center">
        <div i18n>Compare respondents</div>

        <zef-help-icon [subject]="subjects.AnalyzeCompareRespondents"></zef-help-icon>
      </div>
      <mat-slide-toggle
        [disabled]="!userSegments?.details[0]?.values?.length"
        [checked]="comparisonOn"
        (change)="comparisonSwitch($event)"
        color="primary"
        i18n
      ></mat-slide-toggle>
    </div>
    <div
      *ngIf="!userSegments || userSegments?.details[0]?.values?.length === 0"
      class="z-fx-col z-fx-center-center button-container"
    >
      <button mat-raised-button color="secondary" class="comparison-cta" (click)="setFiltersDemoOn()" i18n>
        Compare Respondents...
      </button>
      <p class="comparison-help" i18n>Create groups of respondents, and compare them against each other.</p>
    </div>
    <div cdkDropList (cdkDropListDropped)="updateComparisonListOrder($event)">
      <div
        *ngFor="let item of userSegments?.details?.[0]?.customValuesOrder; let i = index"
        class="z-fx z-fx-start-center user-segment-button"
        [class.edit]="editModeOn === item"
        [class.safe-report-lock]="(this.comparisonOn && safeReportBannedList?.includes(item)) || !isAnonymitySafe(item)"
        cdkDrag
        [cdkDragDisabled]="editModeOn === item"
        [clickOutsideActive]="clickOutsideActive(item)"
        (clickOutside)="clickOutside()"
      >
        <div class="z-fx z-fx-none z-fx-between-center icons" (click)="toggleComparison(item)">
          <mat-icon
            *ngIf="editModeOn !== item && comparisonList.includes(item)"
            title="Comparison"
            class="comparison-icon-bullet"
            [style.color]="getComparisonColor(item)"
          >
            bullet_check
          </mat-icon>
          <mat-icon
            *ngIf="editModeOn !== item && !comparisonList.includes(item)"
            title="Comparison"
            class="comparison-icon-group"
          >
            {{ isAnonymitySafe(item) ? 'group' : 'anonymous_on' }}
          </mat-icon>
          <div *ngIf="editModeOn !== item && !comparisonList.includes(item)" class="checkbox"></div>

          <div *ngIf="editModeOn === item" class="menu-open" [mat-menu-trigger-for]="colorsMenu">
            <div class="z-fx color-box" [style.background]="getComparisonColor(item)">
              <mat-icon fontSet="md" class="select-icon" [style.color]="'white'">expand_more</mat-icon>
            </div>

            <mat-menu #colorsMenu class="menu-dropdown" x-position="before" y-position="below">
              <button
                *ngFor="let color of comparisonColor; let idx = index"
                mat-menu-item
                (click)="changeColor(item, idx)"
              >
                <div class="z-fx color-box menu" [style.background]="color">
                  <mat-icon *ngIf="getComparisonColor(item) === color" class="selected-icon" [style.color]="'white'">
                    check
                  </mat-icon>
                </div>
              </button>
            </mat-menu>
          </div>
        </div>

        <div class="z-fx z-fx-auto z-fx-between-center texts" (click)="toggleComparison(item)">
          <mat-form-field *ngIf="editModeOn === item">
            <input
              #nameInput
              matInput
              autoFocus
              [value]="userSegments?.details[0].labelsCategorical[item]"
              placeholder="Segment's name..."
              i18n-placeholder="placeholder|analyzer segments search"
              (input)="rename(item, $event.target.value)"
              (keyup.enter)="clickOutside()"
            />
          </mat-form-field>
          <div
            *ngIf="editModeOn !== item && userSegments?.details[0].labelsCategorical[item]"
            class="z-fx-auto zef-text-h4 segment-name"
          >
            {{ userSegments?.details[0].labelsCategorical[item] }}
          </div>
          <div *ngIf="editModeOn !== item" class="z-fx-none values">
            <mat-icon class="icon">contact</mat-icon>
            <span
              numberTransition
              class="number"
              [data]="userSegments?.distributions?.[0]?.[userSegments?.details?.[0].values.indexOf(item)].value"
              [duration]="750"
            ></span>
            <ng-container
              *ngIf="
                comparisonList.includes(item) &&
                userSegments['stats'] &&
                userSegments['stats']['userSegments'] &&
                getConsensus(item)
              "
            >
              <mat-icon class="icon">consensus</mat-icon>
              <span numberTransition class="number" [data]="getConsensus(item)" [duration]="750"></span>
              <span>%</span>
            </ng-container>
          </div>
        </div>

        <div *ngIf="editModeOn !== item" class="z-fx-none actions">
          <button
            mat-icon-button
            i18n-matTooltip="tooltip|edit in comparison list"
            matTooltip="Edit"
            class="edit-icon"
            (click)="edit(item)"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            i18n-matTooltip="tooltip|remove in comparison list"
            matTooltip="Remove"
            class="delete-icon"
            (click)="remove(item)"
          >
            <mat-icon>action_delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="safeReportLock || (this.comparisonOn && !!safeReportBannedList?.length)"
  class="z-fx z-fx-gap-16 anonymity-info"
>
  <div class="z-fx-col z-fx-gap-8">
    <div class="zef-text-h3 title" i18n>Select {{ anonymityTreshold || 5 }} or more</div>
    <div class="zef-text-p2 description" i18n>Precise filtering is disabled to protect respondents’ identity.</div>
  </div>
  <mat-icon class="anonymity-icon">anonymous_on</mat-icon>
</div>
