import * as d3 from 'd3';

import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';

import {
  CanvasContext,
  ChartDistribution,
  ChartDomain,
  DimensionDataItem,
  SummaryChartDataChild,
  SummaryChartDataGroup,
} from '@shared/models/report.model';

import { Crossfilter } from '@report/shared/services/crossfilter.service';

import { Colors } from '@report/shared/enums/colors.enum';
import { drawRoundRect, shortenText } from '@shared/utilities/canvas.utilities';

/**
 * This is a line chart showing category trends.
 */
@Directive({
  selector: '[trendCategoricalChart]',
})
export class TrendCategoricalChart implements OnChanges {
  @Input() data: ChartDistribution[][] = [];
  @Input() domain: ChartDomain[] = [];
  @Input() details: DimensionDataItem[] = [];
  @Input() stats: any;
  @Input() scale: string = 'percentage';
  @Input() zvalues: boolean = false;
  @Input() comparison: any;
  @Input() comparisonMode: any;
  @Input() filterInput: any;
  @Input() transitionDuration: number = 0;
  @Input() showNumbers: boolean = false;
  @Input() update: Date = new Date();
  @Input() filtering: boolean = false;
  @Input() anonymityLock: boolean = false;
  @Input() totalAnswers: number = 0;
  @Input() touchDevice: boolean = false;
  @Input() trendHoverInput: string = '';
  @Input() selectionExists: boolean = false;
  @Input() filtersDemo: boolean = false;
  @Output() hover: EventEmitter<string> = new EventEmitter<string>();

  private base: any;

  private context: CanvasContext = {} as CanvasContext;
  private canvas: any;
  private chart: any;

  private timeIndex: number = 0;
  private chartData: any[] = [];
  private stack: any[] = [];

  private axisGroups: any;

  private scaleY: any[];

  private brush: any;
  private brushArea: any;
  private brushing: boolean = false;

  private legends: any[] = [];

  private tooltip: any;
  private tooltipTexts: any;

  private width: any;
  private height: any;
  private margin: any;
  private fontSize: number = 0;
  private unit: number = 0;
  private marginBottomUnits: number[] = [];

  private filter: any;

  private timePos: number | null = null;
  private hoveredLines: string = '';
  private selections: any = [new Set(), new Set()];

  private max: number[] = [];
  private previousMax: number[] = [];

  private timePeriod: string = 'day';
  private timelineFormat: any;
  private locale: string = 'en';

  @HostListener('window:resize') resize() {
    this.updateChart(null);
  }

  constructor(
    private _element: ElementRef,
    private cf: Crossfilter,
  ) {
    this.constructBody();
  }

  constructBody() {
    this.base = d3.select(this._element.nativeElement).append('div').attr('class', 'trend-categorical-chart-v');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.data ||
      changes.domain ||
      changes.scale ||
      changes.filterInput ||
      changes.showNumbers ||
      changes.update ||
      changes.filtering ||
      changes.anonymityLock ||
      changes.title ||
      changes.zvalues ||
      changes.comparisonMode ||
      changes.comparison ||
      changes.details ||
      (changes.trendHoverInput && !this.hoveredLines) ||
      changes.filtersDemo ||
      changes.selectionExists
    ) {
      this.updateChart(changes.data);
    }
  }

  updateChart(dataChanges: SimpleChange | null) {
    this.setQuestions();
    this.setData();
    this.setEnvironment();
    this.setScales();
    this.setChart();
    this.setCanvas(dataChanges);
    this.setBrush();
  }

  setQuestions() {
    this.axisGroups = {};

    for (let i = 0; i < this.domain.length; i++) {
      const item = i.toString();
      const question = [item];
      if (
        this.domain[item]['scale'] === 'categorical' &&
        (!this.comparison || this.domain[item]['key'] !== this.comparison.key) &&
        !this.domain[item]['key'].includes(':rootCauseWords') &&
        !this.domain[item]['key'].includes(':words')
      ) {
        const details = this.details.find((det) => det && det.key === this.domain[item]['key']);
        const group = details ? details.group : '';
        const groupId = this.domain[item]['key'];

        if (!this.axisGroups[groupId]) {
          this.axisGroups[groupId] = { groups: new Set(), questions: [] };
        }

        question.push(group, groupId);

        this.axisGroups[groupId]['groups'].add(group);
        this.axisGroups[groupId]['questions'].push(question);
      }
    }
  }

  setData() {
    const setData = (axisGroup, comparisonGroup = '', comparisonItem = '') => {
      const c = 'children';
      const children: any[] = [];
      const questions = new Set();

      let id;
      let group;
      const comparisonDomain = this.domain.find((dom) => dom.key === comparisonGroup);
      const cdi = this.domain.findIndex((dom) => dom.key === comparisonGroup);
      const cidx = this.domain[cdi] && this.domain[cdi].keys.findIndex((key) => key === comparisonItem);

      let number = 0;

      for (const q of axisGroup['questions']) {
        const x = q[0];

        for (const ch in this.domain[x].keys) {
          const choiceKey = this.domain[x].keys[ch];
          const choiceLabel = this.domain[x]['labels'][choiceKey];
          const groupId = (comparisonItem ? `${comparisonItem}: ` : ``) + `${q[2]}`;

          const key = this.domain[x].key;
          const domIndex = this.domain[x].index;
          const title = choiceLabel;

          const lines = [];
          const timeIndex = this.domain.findIndex((statItem) => statItem.key === 'time');

          if (comparisonDomain && timeIndex >= 0) {
            for (let s = 0, len = this.domain[timeIndex].keys.length; s < len; s++) {
              const time = this.domain[timeIndex].keys[s];

              let value = null;
              let percentage = null;

              if (
                this.data &&
                this.data[cdi] &&
                this.data[cdi][cidx] &&
                this.data[cdi][cidx][c] &&
                this.data[cdi][cidx][c][s] &&
                this.data[cdi][cidx][c][s][c] &&
                this.data[cdi][cidx][c][s][c][ch] &&
                this.data[cdi][cidx][c][s][c][ch]
              ) {
                value = this.data[cdi][cidx][c][s][c][ch]['value'];
                percentage = this.data[cdi][cidx][c][s][c][ch]['percentage'];
              }
              lines.push({ time, value, percentage });
            }
          } else if (timeIndex >= 0) {
            for (let s = 0, len = this.domain[timeIndex].keys.length; s < len; s++) {
              const time = this.domain[timeIndex].keys[s];

              let value = null;
              let percentage = null;

              if (
                this.data &&
                this.data[timeIndex] &&
                this.data[timeIndex][s] &&
                this.data[timeIndex][s][c] &&
                this.data[timeIndex][s][c][ch]
              ) {
                value = this.data[timeIndex][s][c][ch]['value'];
                percentage = this.data[timeIndex][s][c][ch]['percentage'];
              }

              lines.push({ time, value, percentage });
            }
          }

          const n = comparisonDomain
            ? this.stats[cdi] &&
              this.stats[cdi][c] &&
              this.stats[cdi][c][cidx] &&
              this.stats[cdi][c][cidx][c] &&
              this.stats[cdi][c][cidx][c][domIndex] &&
              this.stats[cdi][c][cidx][c][domIndex]['count'] != null
              ? this.stats[cdi][c][cidx][c][domIndex]['count']
              : null
            : this.stats[x] && this.stats[x]['count'];

          if (!id) {
            id = groupId;
          }
          if (!group) {
            group = {
              key: comparisonItem ? comparisonItem : 'aggregate',
              title:
                comparisonItem && this.comparisonMode !== 'joined'
                  ? `${comparisonDomain ? comparisonDomain['labels'][comparisonItem] : ''}`
                  : ``,
            };
          }

          number += 1;

          if (n >= 0) {
            const child: any = {
              lines,
              key: key + choiceKey,
              choiceKey,
              domainIndex: x,
              title: `${title}${
                comparisonItem && this.comparisonMode === 'joined'
                  ? comparisonDomain
                    ? ' (' + comparisonDomain['labels'][comparisonItem] + ')'
                    : ''
                  : ''
              }`,
              n,
              number,
              group: comparisonItem ? comparisonItem : 'aggregate',
              groupLabel: comparisonDomain ? comparisonDomain['labels'][comparisonItem] : '',
              colorIndex:
                comparisonDomain && comparisonDomain['colors'] ? comparisonDomain['colors'][comparisonItem] : null,
            };
            children.push(child);
            questions.add(number + '. ' + title);
          }
        }
      }
      return {
        children,
        id,
        group,
        questions: Array.from(questions) as string[],
        colorIndex: comparisonDomain && comparisonDomain['colors'] ? comparisonDomain['colors'][comparisonItem] : null,
      };
    };

    const chartData: any[] = [];

    for (const group in this.axisGroups) {
      const axisGroup = this.axisGroups[group];

      if (
        this.comparison &&
        this.comparison.values.length > 0 &&
        this.details.find((details) => details.key === this.comparison.key) // ensuring details' existence
      ) {
        const comparisonDetails = this.details.find((details) => details.key === this.comparison.key);
        const joinedData: any = {
          children: [] as SummaryChartDataChild[],
          id: '',
          group: {} as SummaryChartDataGroup,
          questions: [] as string[],
          colorIndex: null,
        };

        if (comparisonDetails) {
          for (let c = 0, len = this.comparison.values.length; c < len; c++) {
            if (comparisonDetails['values'][this.comparison.values[c]]) {
              const comparisonItem = comparisonDetails['values'][this.comparison.values[c]].toString();
              const comparisonData: any = setData(axisGroup, this.comparison.key, comparisonItem);

              if (this.comparisonMode && this.comparisonMode === 'joined') {
                joinedData.children = joinedData.children.concat(comparisonData.children);
                joinedData.id = comparisonData.id;
                joinedData.colorIndex = comparisonData.colorIndex;
                joinedData.group = comparisonData.group;
                joinedData.questions = comparisonData.questions;
              } else {
                chartData.push(comparisonData);
              }
            }
          }

          if (this.comparisonMode && this.comparisonMode === 'joined') {
            chartData.push(joinedData);
          }
        }
      } else {
        chartData.push(setData(axisGroup));
      }
    }

    if (this.chartData.length === 0) {
      this.chartData = chartData;
    } else {
      const orderChanges = () => {
        const oldArr = this.chartData.slice().map((item) => item.id);
        const newArr = chartData.slice().map((item) => item.id);

        if (oldArr.length === newArr.length) {
          for (let o = 0; o < oldArr.length; o++) {
            if (oldArr[o] !== newArr[o]) {
              return true;
            }
          }
          return false;
        } else {
          return true;
        }
      };

      if (orderChanges()) {
        this.chartData = chartData;
      } else {
        for (const item in chartData) {
          const index = this.chartData.findIndex((test) => test.id === chartData[item].id);

          if (index > -1) {
            this.chartData[index]['group'] = chartData[item]['group'];
            this.chartData[index]['id'] = chartData[item]['id'];
            this.chartData[index]['children'] = chartData[item]['children'];
            this.chartData[index]['questions'] = chartData[item]['questions'];
            this.chartData[index]['colorIndex'] = chartData[item]['colorIndex'];
          } else {
            this.chartData.push(chartData[item]);
          }
        }
        // removing old items
        let i = this.chartData.length;
        while (i--) {
          const index = chartData.findIndex((test) => test.id === this.chartData[i].id);

          if (index === -1) {
            this.chartData.splice(i, 1);
          }
        }
      }
    }
  }

  setEnvironment() {
    const marginBottomUnit = Math.max(d3.max(this.marginBottomUnits, (d: any) => d) || 0, 9);
    this.fontSize = parseFloat(window.getComputedStyle(this._element.nativeElement).fontSize);
    this.unit = (10 / 14) * this.fontSize;

    this.margin = {
      top: 3.4 * this.unit,
      right: 3 * this.unit,
      bottom: marginBottomUnit * this.unit,
      left: 3 * this.unit,
    };

    const width = this._element.nativeElement.clientWidth - this.margin.left - this.margin.right;
    const height =
      this._element.nativeElement.clientHeight / this.chartData.length - this.margin.top - this.margin.bottom;

    this.width = width > 0 ? width : 0;
    this.height = height > 0 ? height : 0;
  }

  setScales() {
    this.timeIndex =
      this.domain.findIndex((item) => item && item.key === 'time') > -1
        ? this.domain.findIndex((item) => item && item.key === 'time')
        : 0;

    this.scaleY = [];
    this.previousMax = this.max;
    this.max = [];
    this.stack = [];

    for (let i = 0, len = this.chartData.length; i < len; i++) {
      const sums = [];
      const stacks = [];

      for (let c = 0, lenc = this.chartData[i]['children'].length; c < lenc; c++) {
        const key = this.chartData[i]['children'][c]['key'];

        for (let l = 0, lenl = this.chartData[i]['children'][c]['lines'].length; l < lenl; l++) {
          const value = this.chartData[i]['children'][c]['lines'][l]['value'];
          const percentage = this.chartData[i]['children'][c]['lines'][l]['percentage'];

          if (sums[l] == null) {
            sums[l] = 0;
          }

          if (!stacks[l]) {
            stacks[l] = {};
          }

          stacks[l][key] = this.scale === 'percentage' ? percentage : value;
          sums[l] = sums[l] + value;
        }
      }

      this.stack[i] = d3.stack().keys(this.chartData[i]['children'].map((item) => item.key))(stacks);
      this.max[i] = (this.scale === 'percentage' ? 1 : d3.max(sums, (d1: any) => d1)) * 1.2;

      this.scaleY[i] = d3
        .scaleLinear()
        .range([this.height, 0])
        .domain([0, this.max[i] || 1]);
    }

    this.timePeriod = this.cf.getTimePeriod();
  }

  colorScale(data, lineIndex) {
    let colorScale;
    const defaultColor = this.filterInput.find((item) => item)
      ? Colors.FILTER
      : this.selectionExists
        ? Colors.SELECTED
        : this.filtersDemo
          ? Colors.UNSELECTED
          : Colors.DEFAULT;

    if (data.children.length > 1) {
      const cols = data.children.map((item, index) => {
        const color = d3.hsl(item.colorIndex != null ? Colors.getComparisonColor(item.colorIndex) : defaultColor);
        color.s = color.s - (color.s - 0.2) * (index / data.children.length);

        if (index % 2) {
          color.l = color.l + (color.l > 0.5 ? -0.15 : 0.1);
        }

        return color;
      });

      colorScale = d3
        .scaleLinear<any, any>()
        .domain(data.children.map((item, index) => Number(index)))
        .range(cols);
    }

    return colorScale
      ? colorScale(lineIndex)
      : data.children[lineIndex] && data.children[lineIndex].colorIndex != null
        ? Colors.getComparisonColor(data.children[lineIndex].colorIndex)
        : defaultColor;
  }

  setChart() {
    this.chart = this.base.selectAll('.trend-categorical-content').data(this.chartData);

    this.chart.exit().remove();

    this.chart.attr('data-index', (d, i) => i);

    this.chart
      .enter()
      .append('div')
      .attr('class', 'trend-categorical-content')
      .style('position', 'relative')
      .attr('data-index', (d, i) => i);
  }

  setCanvas(dataChanges: SimpleChange | null) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this;
    this.legends = [];

    const drawContent = function (d) {
      if (this.parentElement) {
        const i = this.parentElement.attributes['data-index'].value;
        const context = d3.select(this).node().getContext('2d');

        __this.setLegends(context, d, Number(i));

        if (dataChanges && !dataChanges.firstChange && __this.transitionDuration > 0) {
          const dataObj = __this.context[i] && __this.context[i].data ? __this.context[i].data : {};

          const interpolateObject: any = (__a, __b) => {
            const a = __a.children;
            const b = __b.children;
            const nb: number = b ? b.length : 0;
            const na: number = a ? Math.min(nb, a.length) : 0;
            const x: any[] = new Array(na);
            const c: any[] = new Array(...b);

            for (let n = 0; n < na; ++n) {
              x[n] = {
                lines: [],
              };
              for (let child = 0, lenc = c[n]['lines'].length; child < lenc; child++) {
                const aVal: number =
                  a[n] && a[n]['lines'] && a[n]['lines'][child] ? a[n]['lines'][child]['value'] : null;
                const bVal: number =
                  b[n] && b[n]['lines'] && b[n]['lines'][child] ? b[n]['lines'][child]['value'] : null;
                const aPer: number =
                  a[n] && a[n]['lines'] && a[n]['lines'][child] ? a[n]['lines'][child]['percentage'] : null;
                const bPer: number =
                  b[n] && b[n]['lines'] && b[n]['lines'][child] ? b[n]['lines'][child]['percentage'] : null;

                x[n]['lines'][child] = {
                  value: bVal != null ? d3.interpolateNumber(aVal, bVal) : () => null,
                  percentage: bPer != null ? d3.interpolateNumber(aPer, bPer) : () => null,
                };
              }
            }

            return function (t) {
              for (let n = 0; n < na; ++n) {
                c[n]['key'] = b[n]['key'];
                c[n]['title'] = b[n]['title'];
                c[n]['group'] = b[n]['group'];
                c[n]['groupLabel'] = b[n]['groupLabel'];
                c[n]['colorIndex'] = b[n]['colorIndex'];
                c[n]['domainIndex'] = b[n]['domainIndex'];
                c[n]['choiceKey'] = b[n]['choiceKey'];

                for (let child = 0, lenc = c[n]['lines'].length; child < lenc; child++) {
                  c[n]['lines'][child]['time'] = b[n]['lines'][child]['time'];
                  c[n]['lines'][child]['value'] = x[n]['lines'][child]['value'](t);
                  c[n]['lines'][child]['percentage'] = x[n]['lines'][child]['percentage'](t);
                }
              }
              return {
                children: c,
                sliderDetails: __b.sliderDetails,
                colorIndex: __b.colorIndex,
                id: __b.id,
                group: __b.group,
              };
            };
          };
          const interpolator = interpolateObject(dataObj, d);
          const interpolateMax = d3.interpolateNumber(__this.previousMax[i] || __this.max[i] || 0, __this.max[i] || 0);
          const ease = d3.easeCubic;

          const timer = d3.timer((elapsed) => {
            const step = elapsed / __this.transitionDuration;
            let data;
            let scaleY;

            if (step >= 1) {
              data = interpolator(ease(1));
              scaleY = d3
                .scaleLinear()
                .range([__this.height, 0])
                .domain([0, __this.max[i] || 1]);

              timer.stop();
            } else {
              data = interpolator(ease(step));
              scaleY = d3
                .scaleLinear()
                .range([__this.height, 0])
                .domain([0, interpolateMax(ease(step)) || 1]);
            }

            __this.setLines(context, data, scaleY, i, [], null);
          });
        } else {
          __this.setLines(context, d, __this.scaleY[i], i, [], null);
        }

        __this.context[i] = { context, data: Object.assign({}, d) };
      }
    };

    this.canvas = this.base
      .selectAll('.trend-categorical-content')
      .selectAll('.trend-categorical-chart-canvas')
      .data((d) => [d]);

    this.canvas.exit().remove();

    this.canvas
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);

    this.canvas
      .enter()
      .append('canvas')
      .attr('class', 'trend-categorical-chart-canvas')
      .style('position', 'relative')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);
  }

  setLegends(context, data, dataIndex) {
    const y = this.margin.top + this.height + 7 * this.unit;
    const width = this.width + this.margin.left + this.margin.right;
    const height = 2 * this.unit;
    let usedSpace = 0;
    let arr: any[] = [];
    let currentX = 0;
    let currentY = 0;
    let margin;
    const legends = [];

    context.clearRect(0, y - 1, width, this.margin.bottom);

    context.globalAlpha = 1;
    context.textBaseline = 'top';
    context.textAlign = 'left';
    context.font = 10 / 14 + 'em Inter';

    for (let i = 0, length = data.children.length; i < length; i++) {
      const key = data.children[i]['key'];
      const id = data.id;
      const text = shortenText(context, data.children[i]['title'], width, 3 * this.unit);
      const elW = context.measureText(text).width;
      const lines = data.children[i]['lines'];
      const domainIndex = data.children[i]['domainIndex'];
      const choiceKey = data.children[i]['choiceKey'];

      if (usedSpace + elW + 2 * this.unit <= width) {
        usedSpace += elW + 2 * this.unit;
        arr.push([key, i, text, lines, id, domainIndex, choiceKey]);
      } else {
        margin = (width - usedSpace) / 2;
        currentX = margin;

        for (let a = 0, len = arr.length; a < len; a++) {
          const itemText = arr[a][2];
          const itemColor = arr[a][1];
          const itemKey = arr[a][0];
          const itemLines = arr[a][3];
          const groupId = arr[a][4];
          const itemDomainIndex = arr[a][5];
          const itemChoiceKey = arr[a][6];
          currentX += this.unit;
          context.globalAlpha = 1;

          if (this.filterInput && this.filterInput[itemDomainIndex] && this.filterInput[itemDomainIndex].length > 0) {
            if (this.filterInput[itemDomainIndex].indexOf(itemChoiceKey) > -1) {
              context.globalAlpha = 1;
            } else {
              context.globalAlpha = 0.2;
            }
          }

          context.fillStyle = Colors.TEXT;
          context.fillText(itemText, currentX, currentY + y);

          context.beginPath();
          context.arc(currentX - 0.8 * this.unit, currentY + y + 0.6 * this.unit, 0.5 * this.unit, 0, 2 * Math.PI);
          context.closePath();
          context.fillStyle = this.colorScale(data, itemColor);
          context.fill();
          legends.push({
            key: itemKey,
            groupId,
            lines: itemLines,
            index: itemColor,
            x: currentX,
            y: currentY + y,
            width: context.measureText(itemText).width,
            height,
            title: itemText,
            domainIndex: itemDomainIndex,
            choiceKey: itemChoiceKey,
          });
          currentX += context.measureText(itemText).width + this.unit;
          context.globalAlpha = 1;
        }

        currentY += height;
        usedSpace = elW + 2 * this.unit;

        arr = [[key, i, text, lines, id, domainIndex, choiceKey]];
      }
    }

    margin = (width - usedSpace) / 2;
    currentX = margin;

    for (let a = 0, len = arr.length; a < len; a++) {
      const itemText = arr[a][2];
      const itemColor = arr[a][1];
      const itemKey = arr[a][0];
      const itemLines = arr[a][3];
      const groupId = arr[a][4];
      const itemDomainIndex = arr[a][5];
      const itemChoiceKey = arr[a][6];

      currentX += this.unit;
      context.globalAlpha = 1;

      if (this.filterInput && this.filterInput[itemDomainIndex] && this.filterInput[itemDomainIndex].length > 0) {
        if (this.filterInput[itemDomainIndex].indexOf(itemChoiceKey) > -1) {
          context.globalAlpha = 1;
        } else {
          context.globalAlpha = 0.2;
        }
      }

      context.fillStyle = Colors.TEXT;
      context.fillText(itemText, currentX, currentY + y);

      context.beginPath();
      context.arc(currentX - 0.8 * this.unit, currentY + y + 0.6 * this.unit, 0.5 * this.unit, 0, 2 * Math.PI);
      context.closePath();
      context.fillStyle = this.colorScale(data, itemColor);
      context.fill();
      legends.push({
        key: itemKey,
        groupId,
        lines: itemLines,
        index: itemColor,
        x: currentX,
        y: currentY + y,
        width: context.measureText(itemText).width,
        height,
        title: itemText,
        domainIndex: itemDomainIndex,
        choiceKey: itemChoiceKey,
      });
      currentX += context.measureText(itemText).width + this.unit;
      context.globalAlpha = 1;
    }

    context.globalAlpha = 1;

    const spaceNeeded = currentY / this.unit + 9;

    if (!this.marginBottomUnits[dataIndex]) {
      this.marginBottomUnits[dataIndex] = 9;
    }

    if (
      !isNaN(spaceNeeded) &&
      this.marginBottomUnits[dataIndex] !== spaceNeeded &&
      this.marginBottomUnits[dataIndex] * this.unit > 0
    ) {
      this.marginBottomUnits[dataIndex] += spaceNeeded - this.marginBottomUnits[dataIndex];
      this.setEnvironment();
      this.setScales();
      this.setLegends(context, data, dataIndex);
    } else {
      for (let l = 0, len = legends.length; l < len; l++) {
        this.legends.push(legends[l]);
      }
    }
  }

  setLines(
    context,
    data: any = {},
    scaleY: any = () => 0,
    dataIndex,
    filter: any[] | null = [],
    highlight: any[] | null = [],
    legends: any[] = [],
  ) {
    context.clearRect(this.margin.left, this.margin.top, this.width + this.margin.right, this.height + 5);
    this.setTexts(context, legends, data);
    this.setYAxis(context, scaleY, dataIndex);
    this.selections = this.domain.map(
      (item, index) => new Set(index === this.timeIndex ? null : this.filterInput[index]),
    );

    const lines: any[] = [];
    const legendIndex = legends.length > 0 ? legends[0].index : -1;

    for (let x = 0, lenx = (data.children || []).length; x < lenx; x++) {
      const dataItem = data.children[x];
      const keyY = dataItem['key'];

      if (!lines[x]) {
        lines[x] = [];
      }

      for (let y = 0, leny = dataItem.lines.length; y < leny; y++) {
        const keyX = dataItem.lines[y]['time'];
        const value = dataItem.lines[y]['value'];
        const percentage = dataItem.lines[y]['percentage'];

        const yPos = scaleY(this.scale === 'percentage' ? percentage : value) + this.margin.top;
        const xPos = this.scaleXPos(y, leny);

        lines[x].push({
          keyX,
          xPos,
          keyY,
          yPos,
          value,
          percentage,
        });
      }
    }

    if (highlight && highlight.length > 0) {
      for (let h = 0, lenh = highlight.length; h < lenh; h++) {
        const d = (lines && lines[0] && lines[0].length > 0 ? lines[0] : []).findIndex(
          (item) => item.keyX === highlight[h]['time'],
        );
        const len = lines && lines[0] && lines[0].length > 0 ? lines[0].length : 1;
        const xPos = this.scaleXPos(d, len);

        if (!isNaN(xPos)) {
          context.strokeStyle = Colors.TEXT;
          context.lineWidth = 1;
          context.beginPath();
          context.moveTo(xPos, this.margin.top);
          context.lineTo(xPos, this.margin.top + this.height);
          context.stroke();
        }
      }
    } else if (this.trendHoverInput) {
      const d = (lines && lines[0] && lines[0].length > 0 ? lines[0] : []).findIndex(
        (item) => item.keyX === this.trendHoverInput,
      );
      const len = lines && lines[0] && lines[0].length > 0 ? lines[0].length : 1;
      const xPos = this.scaleXPos(d, len);

      if (!isNaN(xPos)) {
        context.strokeStyle = Colors.TEXT;
        context.lineWidth = 1;
        context.beginPath();
        context.moveTo(xPos, this.margin.top);
        context.lineTo(xPos, this.margin.top + this.height);
        context.stroke();
      }
    }

    context.globalAlpha = 1;

    for (let l = 0, len = lines.length; l < len; l++) {
      const color = this.colorScale(data, l);
      const legendDim = legendIndex === -1 ? false : legendIndex !== l;

      if (!legendDim) {
        for (let d = 0, lend = lines[l].length; d < lend; d++) {
          context.fillStyle = color;
          context.strokeStyle = color;
          context.globalAlpha = 1;

          let width = this.width / lines[l].length;
          width = width > 8 ? width - 2 : width;
          const keyX = lines[l][d]['keyX'];
          const xPos = this.scaleXPos(d, lend) - width / 2;
          const value = lines[l][d]['value'];
          const percentage = lines[l][d]['percentage'];
          const start = this.stack?.[dataIndex]?.[l]?.[d]?.[0];
          const end = this.stack?.[dataIndex]?.[l]?.[d]?.[1];
          const valuey = end - start;

          const height = this.height - scaleY(valuey);
          const yPos = this.margin.top + scaleY(legendIndex === -1 ? end : valuey);

          if (value != null) {
            if (filter != null && filter.length === 2) {
              const parent =
                filter &&
                filter[0] <= this.scaleXPos(d, lend) - this.margin.left &&
                filter[1] >= this.scaleXPos(d, lend) - this.margin.left;

              if (!parent) {
                context.globalAlpha = 0.2;
              } else if (parent) {
                this.selections[this.timeIndex].add(keyX);
              }
            } else if (
              this.filterInput &&
              this.filterInput[this.timeIndex] &&
              this.filterInput[this.timeIndex].length > 0
            ) {
              const parent = this.filterInput[this.timeIndex] && this.filterInput[this.timeIndex].indexOf(keyX) > -1;

              if (!parent) {
                context.globalAlpha = 0.2;
              } else {
                if (filter != null && parent) {
                  this.selections[this.timeIndex].add(keyX);
                }
              }
            }

            const isHighlighted =
              (highlight &&
                highlight.length > 0 &&
                highlight.find((item) => (item.parentIndex === l || item.dataId !== data.id) && item.time === keyX)) ||
              ((!highlight || (highlight && highlight.length == null)) &&
                this.trendHoverInput &&
                this.trendHoverInput === keyX);
            context.strokeStyle = isHighlighted ? Colors.TEXT : 'transparent';

            drawRoundRect(
              context,
              xPos,
              yPos,
              width,
              height,
              l + 1 === len ||
                lines
                  .slice(l + 1, len)
                  .map((item) => item.filter((it, idx) => idx === d)[0])
                  .map((item) => item.value)
                  .reduce((a, b) => a + b) === 0
                ? { tl: 5, tr: 5, bl: 0, br: 0 }
                : 0,
              context.fillStyle,
              true,
            );

            if ((this.showNumbers || isHighlighted) && width > 32) {
              context.font = 10 / 14 + 'em Inter';
              context.textBaseline = 'middle';
              context.textAlign = 'center';
              context.fillStyle = Colors.BACKGROUND;
              context.fillText(
                value > 0
                  ? this.scale === 'percentage'
                    ? (percentage * 100).toFixed(1) + '%'
                    : Math.round(value)
                  : '',
                xPos + width / 2,
                yPos + height / 2,
              );
            }

            context.globalAlpha = 1;
          }
        }
      }
    }

    context.clearRect(this.margin.left, this.margin.top + this.height, this.width + this.margin.right, 8);
  }

  setTexts(context, legends, data) {
    const height = 6 * this.unit;
    context.clearRect(0, this.height + this.margin.top, this.width + this.margin.right + this.margin.left, height + 2);
    context.clearRect(0, 0, this.width + this.margin.right + this.margin.left, this.margin.top);

    context.fillStyle = Colors.TEXT;
    context.textAlign = 'left';
    context.textBaseline = 'middle';

    let title = data && data.group && data.group.title ? data.group.title : '';

    if (legends && legends[0] && legends[0]['title']) {
      title += data && data.group && data.group.title ? ' (' : '';
      title += legends[0]['title'];
      title += data && data.group && data.group.title ? ')' : '';
    }

    if (title) {
      context.font = 12 / 14 + 'em Inter';
      const h = this.margin.top / 2;
      const wrappedTitle = shortenText(context, title, this.width, 20);
      const wTitle = wrappedTitle ? context.measureText(wrappedTitle).width + 8 : 0;
      const startPoint = this.margin.left + this.width / 2 - wTitle / 2;
      context.fillText(wrappedTitle, startPoint, h);
    }

    context.beginPath();
    context.moveTo(this.margin.left, this.height + this.margin.top + 1 * this.unit);
    context.lineTo(this.margin.left + this.width, this.height + this.margin.top + 1 * this.unit);
    context.lineWidth = 1;
    context.strokeStyle = Colors.HELPERLINE;
    context.stroke();

    context.font = 10 / 14 + 'em Inter';
    context.fillStyle = Colors.TEXT;
    context.textAlign = 'center';
    context.textBaseline = 'top';

    const keys = this.domain[this.timeIndex].keys;
    this.setTimelineTicks(context, keys, this.width, this.margin.top + this.height);
  }

  getTimelineFormat(times) {
    this.locale =
      this.cf.getActiveLocale().length > 5 || !this.cf.getActiveLocale()
        ? navigator.language
        : this.cf.getActiveLocale();
    const years =
      new Date(Number(times[0])).getFullYear() !== new Date(Number(times[times.length - 1])).getFullYear() ||
      new Date(Number(times[0])).getFullYear() !== new Date().getFullYear();

    if (years) {
      if (this.timePeriod === 'year') {
        return new Intl.DateTimeFormat(this.locale, { year: 'numeric' });
      } else if (this.timePeriod === 'month') {
        return new Intl.DateTimeFormat(this.locale, { month: 'short', year: 'numeric' });
      } else {
        return new Intl.DateTimeFormat(this.locale, { day: 'numeric', month: 'numeric', year: 'numeric' });
      }
    } else if (this.timePeriod === 'year') {
      return new Intl.DateTimeFormat(this.locale, { year: 'numeric' });
    } else if (this.timePeriod === 'month') {
      return new Intl.DateTimeFormat(this.locale, { month: 'long' });
    } else {
      return new Intl.DateTimeFormat(this.locale, { day: 'numeric', month: 'short' });
    }
  }

  setTimelineTicks(context, times, width, y) {
    this.timelineFormat = this.getTimelineFormat(times);
    context.font = 10 / 14 + 'em Inter';
    let lastItem = times.length - 1;
    const tickW =
      context.measureText(this.timelineFormat.format(new Date(2019, this.locale !== 'fi' ? 11 : 10, 24))).width + 16;
    const allTicks = Number(times.length);
    const tickPossible = Math.floor((width + tickW) / tickW);
    const possibleTicksBetween =
      tickPossible < allTicks
        ? tickPossible > 2
          ? tickPossible / (allTicks - 2) > 0.5
            ? Math.floor((allTicks - 2) / 2)
            : tickPossible - 2
          : 0
        : allTicks - 2;
    const availableExcessSpace = (width + tickW) / tickW - tickPossible;

    const calcTickReducer = (all, possible) => {
      const rounder = (number) => Math.round(number * Math.pow(10, 5)) / Math.pow(10, 5);

      return possible > 0 && (all - 1) / (possible + 1) >= 1 ? rounder((all - 1) / (possible + 1)) : all;
    };

    const arr = [
      allTicks,
      allTicks,
      allTicks,
      Number.isInteger(calcTickReducer(allTicks, possibleTicksBetween + availableExcessSpace > 0.7 ? 1 : 0))
        ? calcTickReducer(allTicks, possibleTicksBetween + availableExcessSpace > 0.7 ? 1 : 0)
        : allTicks,
    ];
    for (let i = possibleTicksBetween; i > 0; i--) {
      const reducer = calcTickReducer(allTicks, i);
      const reducer1 = calcTickReducer(allTicks - 1, i);
      const reducer2 = calcTickReducer(allTicks - 2, i);

      if (Number.isInteger(reducer) && reducer < arr[0]) {
        arr[0] = reducer;
      }
      if (Number.isInteger(reducer1) && reducer1 < arr[1]) {
        arr[1] = reducer1;
      }
      if (Number.isInteger(reducer2) && reducer2 < arr[2] && allTicks - 2 > possibleTicksBetween + 2) {
        arr[2] = reducer2;
      }
    }
    const reducerIndex = arr.indexOf(Math.min(...arr));

    const tickReducer = arr[reducerIndex];
    lastItem -= reducerIndex < 3 ? reducerIndex : 0;

    const w = width / times.length;
    times.forEach((tick, i) => {
      if (Number(i) === 0 || Number(i) % tickReducer === 0 || Number(i) === lastItem) {
        const posX = Number(i) * w + this.margin.left + w / 2;
        context.beginPath();
        context.moveTo(posX, y + 1 * this.unit);
        context.lineTo(posX, y + 1 * this.unit + 5);
        context.lineWidth = 1;
        context.strokeStyle = Colors.HELPERLINE;
        context.stroke();

        context.textBaseline = 'middle';
        context.textAlign = 'center';

        context.fillStyle = Colors.TEXT;
        context.fillText(this.timelineFormat.format(new Date(Number(tick) || 0)), posX, y + 2.5 * this.unit);
      }
    });
  }

  setYAxis(context, scaleY, i) {
    context.clearRect(0, this.margin.top, this.margin.left, this.height + this.unit);
    context.globalAlpha = 1;

    const tickCount = this.scale === 'percentage' ? 4 : this.max[i] > 3 ? 4 : this.max[i] > 2 ? 2 : 1;
    const tickPadding = 3;
    const ticks = scaleY.ticks(tickCount);
    const tickFormat = scaleY.tickFormat(tickCount, this.scale === 'percentage' ? ',%' : 'd');

    context.font = 10 / 14 + 'em Inter';
    context.fillStyle = Colors.TEXT;
    context.strokeStyle = Colors.HELPERLINE;
    context.lineWidth = 1;
    context.textAlign = 'right';
    context.textBaseline = 'middle';
    ticks.forEach((d) => {
      if (!(this.scale === 'percentage' && Number(tickFormat(d).replace(/\D/g, '')) > 100)) {
        const x = this.margin.left - tickPadding;
        const y = this.margin.top + scaleY(d);
        context.fillText(tickFormat(d), x, y);
        if (d > 0) {
          context.beginPath();
          context.moveTo(x + tickPadding + 8, y);
          context.lineTo(this.margin.left + this.width, y);
          context.stroke();
        }
      }
    });
  }

  setBrush() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this;
    const hoverFunction = function (event, d) {
      if (!__this.touchDevice || !__this.filtering || __this.anonymityLock) {
        const area = d3.pointer(event);
        const index =
          this.parentElement && this.parentElement.attributes && this.parentElement.attributes['data-index']
            ? this.parentElement.attributes['data-index'].value
            : -1;
        __this.selectForHover(area, d, index);
      }
    };
    const clickFunction = function (event, d) {
      const area = d3.pointer(event);
      const index =
        this.parentElement && this.parentElement.attributes && this.parentElement.attributes['data-index']
          ? this.parentElement.attributes['data-index'].value
          : -1;
      __this.selectFromLegends(area, d, index);
      __this.callFilter();
    };

    if (this.filtering && !this.anonymityLock) {
      this.brush = d3
        .brushX()
        .on('brush', function (event) {
          if (event.sourceEvent) {
            // learn more from https://github.com/d3/d3-selection/issues/122
            __this.brushing = true;

            const sel =
              this && d3.select(this) && d3.select(this).node() ? d3.brushSelection(d3.select(this).node()!) : null;
            const parentsParent =
              this.parentElement && this.parentElement.parentElement ? this.parentElement.parentElement : null;

            const index = parentsParent ? parentsParent.attributes['data-index'].value : -1;

            d3.select(parentsParent)
              .select('.trend-categorical-chart-canvas')
              .each(function () {
                __this.setLines(
                  __this.context[index].context,
                  __this.context[index].data,
                  __this.scaleY[index],
                  index,
                  sel,
                  null,
                );
              });
            __this.setTooltip(null);
          }
        })
        .on('end', function (event) {
          if (event.sourceEvent) {
            // learn more from https://github.com/d3/d3-selection/issues/122
            __this.brushing = false;
            const sel =
              this && d3.select(this) && d3.select(this).node() ? d3.brushSelection(d3.select(this).node()!) : null;
            const parentsParent =
              this.parentElement && this.parentElement.parentElement ? this.parentElement.parentElement : null;

            const index = parentsParent ? parentsParent.attributes['data-index'].value : -1;
            d3.select(parentsParent)
              .select('.trend-categorical-chart-canvas')
              .each(function () {
                __this.setLines(
                  __this.context[index].context,
                  __this.context[index].data,
                  __this.scaleY[index],
                  index,
                  sel,
                  null,
                );
              });

            __this.callFilter();
          }
        });

      const callBrush = function () {
        const currentBrush = d3.brushSelection(d3.select(this).node());
        if (__this.filterInput && __this.filterInput[0] && __this.filterInput[0].length > 0) {
          const checkIndex = (key) => __this.domain[__this.timeIndex].keys.indexOf(key);
          const len = __this.domain[__this.timeIndex].keys.length;
          let minX =
            d3.min(
              __this.filterInput[0].map((fi) => fi),
              (mn) => __this.scaleXPos(checkIndex(mn), len) - __this.margin.left,
            ) || 0;
          let maxX = d3.max(
            __this.filterInput[0].map((fi) => fi),
            (mx) => __this.scaleXPos(checkIndex(mx), len) - __this.margin.left,
          );

          minX -= __this.width / len / 4;
          maxX += __this.width / len / 4;

          const brushArea = [minX > 0 ? minX : 0, maxX > 0 ? maxX : 0];

          if (
            !currentBrush ||
            currentBrush.length !== 2 ||
            currentBrush[0] !== brushArea[0] ||
            currentBrush[1] !== brushArea[1]
          ) {
            d3.select(this).call(__this.brush).call(__this.brush.move, brushArea);
          }
        } else {
          if (__this.filterInput && !__this.filterInput[0] && currentBrush != null) {
            d3.select(this).call(__this.brush.move, null);
          } else {
            d3.select(this).call(__this.brush);
          }
        }
      };

      this.brushArea = this.base
        .selectAll('.trend-categorical-content')
        .selectAll('.svg-brush')
        .data((d) => [d]);

      this.brushArea.exit().remove();

      this.brushArea
        .attr('width', this.width + this.margin.left)
        .attr('height', this.height + this.margin.top + 60)
        .select('.brush')
        .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
        .each(callBrush);

      this.brushArea
        .enter()
        .append('svg')
        .attr('class', 'svg-brush')
        .attr('width', this.width + this.margin.left)
        .attr('height', this.height + this.margin.top + 60)
        .style('position', 'absolute')
        .style('top', 0)
        .style('left', 0)
        .on('mousemove', hoverFunction)
        .on('mouseout', function () {
          __this.timePos = null;
          __this.hoveredLines = '';
          __this.setTooltip(null);
        })
        .append('g')
        .attr('class', 'brush')
        .attr('stroke-width', 0)
        .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
        .each(callBrush);
    }

    this.base
      .selectAll('.trend-categorical-chart-canvas')
      .on('mousemove', hoverFunction)
      .on('mouseout', function () {
        __this.timePos = null;
        __this.hoveredLines = '';
        __this.setTooltip(null);
      })
      .on('click', clickFunction);
  }

  setTooltip(position: number | null, data: any[] = [], parentData: any = {}, legends: any = []) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const __this = this;
    const parents = parentData.children;
    const sortedData = new Array(...data).reverse();

    // adding hovering effect
    this.base.selectAll('.trend-categorical-chart-canvas').each(function (d) {
      if (this.parentElement) {
        const i = this.parentElement.attributes['data-index'].value;
        const hoveredLegends = legends.filter((item) => item.groupId === d.id);
        if (!__this.brushing) {
          __this.setLines(
            __this.context[i].context,
            __this.context[i].data,
            __this.scaleY[i],
            i,
            [],
            data || [],
            hoveredLegends,
          );
        }
      }
    });

    this.hover.emit(data && data[0] ? data[0]['time'] : '');

    const options = {
      backgroundColor: (d) => (parents ? this.colorScale(parentData, Number(d.parentIndex)) : ''),
      html: (d) => `<div class="question">${parents.length > 1 ? '<span class="circle">●</span>' : ''}
       <span class="text">${parents[d.parentIndex].title}</span> <span class="stats">
        <span class="icon">contact</span> <span class="number">${d.value != null ? d.value : '-'} (${
          d.percentage != null ? (d.percentage * 100).toFixed(1) : '-'
        }%)</span></span>`,
      time: (d) =>
        d && d[0] && d[0]['time']
          ? `<span class="date">${
              this.timelineFormat && Number(d[0]['time']) ? this.timelineFormat.format(Number(d[0]['time'])) : ''
            }</span>`
          : '',
    };

    this.base.selectAll('.trend-categorical-content').selectAll('.trend-chart-tooltip').remove();

    this.tooltip = this.base
      .selectAll('.trend-categorical-content')
      .filter((d) => d.id === parentData.id)
      .selectAll('.trend-chart-tooltip')
      .data(sortedData.length > 0 ? [sortedData] : []);

    this.tooltip.exit().remove();

    this.tooltip.html(options.time);

    this.tooltip.enter().append('div').attr('class', 'trend-chart-tooltip').html(options.time);

    this.tooltipTexts = this.base
      .selectAll('.trend-chart-tooltip')
      .selectAll('.trend-chart-tooltip-text')
      .data((d) => d);

    this.tooltipTexts.exit().remove();

    this.tooltipTexts.style('color', options.backgroundColor).html(options.html);

    this.tooltipTexts
      .enter()
      .append('div')
      .attr('class', 'trend-chart-tooltip-text')
      .style('color', options.backgroundColor)
      .html(options.html);

    this.base.selectAll('.trend-chart-tooltip').style('transform', function (d, i, g) {
      const w = __this.margin.left + __this.width + __this.margin.right;
      const tipW = this.getBoundingClientRect().width;
      const pos = position;
      let sumHeight = 15;
      for (let e = 0, len = g.length; e < len; e++) {
        if (e <= i) {
          sumHeight += g[e].getBoundingClientRect().height;
        }
      }

      return `translate(${
        tipW < w ? (pos + tipW / 2 > w ? w - tipW : pos - tipW / 2 < 0 ? 0 : pos - tipW / 2) : 0
      }px,${__this.margin.top - sumHeight}px)`;
    });

    if (legends.length > 0 && this.filtering && !this.anonymityLock) {
      this.base.selectAll('.trend-categorical-chart-canvas').style('cursor', 'pointer');
    } else {
      this.base.selectAll('.trend-categorical-chart-canvas').style('cursor', null);
    }
  }

  callFilter() {
    if (this.filtering && !this.anonymityLock) {
      this.filter = [];

      for (let i = 0, len = this.selections.length; i < len; i++) {
        const filterItem = {
          key: this.domain[i].key,
          values: i === this.timeIndex ? this.details[i].values : this.domain[i].keys,
          filter: Array.from(this.selections[i]),
        };
        this.filter.push(filterItem);
      }

      const filterInput = JSON.stringify(this.filterInput.slice(0, 1).map((item) => (item == null ? [] : item)));
      const filter = JSON.stringify(this.filter.map((item) => item.filter));

      if (filter !== filterInput) {
        this.cf.filter(this.filter);
      } else {
        const brushOn =
          this.brushArea.select('.brush').node() != null &&
          d3.brushSelection(this.brushArea.select('.brush').node()) != null;

        if (!this.filterInput[0] && brushOn) {
          this.brushArea.select('.brush').call(this.brush.move, null);
        }
      }
    }
  }

  // Helpers
  selectFromLegends(area, data, dataIndex) {
    const legends = this.itemsBelow(area, data, dataIndex).legends;

    for (let l = 0, len = (legends || []).length; l < len; l++) {
      if (this.selections[legends[l].domainIndex].has(legends[l].choiceKey)) {
        this.selections[legends[l].domainIndex].delete(legends[l].choiceKey);
      } else {
        this.selections[legends[l].domainIndex].add(legends[l].choiceKey);
      }
    }
  }

  selectForHover(area, data, dataIndex) {
    const itemsBelow = this.itemsBelow(area, data, dataIndex);
    const parents = itemsBelow.parents;
    const childs = itemsBelow.childs.length > 0 ? itemsBelow.childs : [];
    const legends = itemsBelow.legends;
    const timePos =
      childs && childs.length > 0
        ? this.scaleXPos(
            this.domain[this.timeIndex].keys.indexOf(childs[0]['time']),
            this.domain[this.timeIndex].keys.length,
          )
        : null;
    let hoveredLines = '';
    for (let c = 0, lenc = childs.length; c < lenc; c++) {
      hoveredLines += childs[c]['parentIndex'];
    }

    if (this.timePos !== timePos || this.hoveredLines !== hoveredLines) {
      if (parents.length > 0) {
        this.setTooltip(timePos, childs, data);
      } else {
        this.setTooltip(timePos);
      }
      this.timePos = timePos;
      this.hoveredLines = hoveredLines;
    } else if (legends.length > 0) {
      this.setTooltip(timePos, [], data, legends);
    }
  }

  itemsBelow(area, data, dataIndex) {
    const allChilds = [];
    const exactChilds = [];
    const parents = data.children || [];

    for (let p = 0, lenp = parents.length; p < lenp; p++) {
      const parentItem = parents[p];

      for (let l = 0, len = parentItem['lines'].length; l < len; l++) {
        const width = this.width / len;
        const item = Object.assign({}, parentItem['lines'][l]);
        const xPos1 = this.scaleXPos(l, len) - width / 2;
        const xPos2 = this.scaleXPos(l, len) + width / 2;
        const start = this.stack[dataIndex][p][l][0];
        const end = this.stack[dataIndex][p][l][1];
        const yPosStart = this.margin.top + this.scaleY[dataIndex](start);
        const yPosEnd = this.margin.top + this.scaleY[dataIndex](end);

        item['parentIndex'] = p;
        item['dataId'] = data.id;
        item['key'] = parentItem['key'];

        if (
          area[0] < xPos2 &&
          area[0] >= xPos1 &&
          area[1] < this.margin.top + this.height + 60 &&
          area[1] >= this.margin.top
        ) {
          allChilds.push(item);
        }
        if (area[0] < xPos2 && area[0] >= xPos1 && area[1] >= yPosEnd && area[1] < yPosStart) {
          exactChilds.push(item);
        }
      }
    }

    const legends = this.legends.filter(
      (item) =>
        data.id === item.groupId &&
        area[0] > item.x - 15 &&
        area[0] < item.x + item.width &&
        area[1] > item.y &&
        area[1] < item.y + item.height,
    );

    return { parents, childs: exactChilds.length > 0 ? exactChilds : allChilds, legends };
  }

  scaleXPos(index: number, arrLen: number) {
    return index * (this.width / arrLen) + this.margin.left + this.width / arrLen / 2;
  }

  /**
   * Parsing group ids from axis details.
   *
   * @param detailsX   Dimension details for axis x.
   * @param detailsY  Dimension details for axis x.
   * @returns        Parsed group id.
   */
  parseGroupId(domain: ChartDomain): string {
    return `${domain.labelsLinear?.axis || ''}: ${domain.labelsLinear?.min || ''} - ${
      domain.labelsLinear?.max || ''
    } (${domain.origin?.min}-${domain.origin?.max})`;
  }
}
