import { BehaviorSubject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { SnackbarService } from '@shared/services/snackbar.service';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';

import {
  ChartDistribution,
  ChartDomain,
  ChartSettings,
  DimensionDataItem,
  FilterData,
} from '@shared/models/report.model';

import { Colors } from '@report/shared/enums/colors.enum';

import { Crossfilter } from '@report/shared/services/crossfilter.service';
import { rotateAnimation } from '@shared/animations/rotate.anim';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

/**
 * This is a text sentiment analysis.
 */
@Component({
  selector: 'text-sentiment-analysis',
  templateUrl: './text-sentiment-analysis.component.html',
  styleUrls: ['./text-sentiment-analysis.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [rotateAnimation],
})
export class TextSentimentAnalysis implements OnInit, OnChanges {
  @Input() details: DimensionDataItem[] = [];
  @Input() domain: ChartDomain[] = [];
  @Input() distributions: ChartDistribution[][] = [];
  @Input() stats: any[] = [];
  @Input() filterInput: any[] = [];
  @Input() crossfilter: Crossfilter | null = null;
  @Input() comparison: any;
  @Input() trend: any;
  @Input() totalAnswers: any;

  @Input() trendHoverInput: string = '';
  @Input() update: Date = new Date();

  @Input() chartSettings: ChartSettings = {} as ChartSettings;
  @Input() size: string = '0px';
  @Input() transitionDuration: number = 0;
  @Input() isSharedReport: boolean = false;
  @Input() selectionExists: boolean = false;
  @Input() filtersDemo: boolean = false;
  @Input() filtering: boolean = false;
  @Input() anonymityLock: boolean = false;
  @Input() touchDevice: boolean = false;

  @Input() anonymityTreshold: number = null;

  @Output() settingsChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() hover: EventEmitter<string> = new EventEmitter<string>();

  // BehaviorSubjects
  public searchUpdate$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public languageFilter$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public sortKey$: BehaviorSubject<string> = new BehaviorSubject<string>('survey');
  public sortDirection$: BehaviorSubject<string> = new BehaviorSubject<string>('asc');
  public chartData$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public chartTable$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public comparisonTable$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  // public rowsVisible$: BehaviorSubject<number> = new BehaviorSubject<number>(3);

  // Variables used in HTML template
  public chartMode: string = 'gauge';
  public sizeUpdate: Date = new Date();
  public expandedLabels: boolean = false;
  public isPoweredBy: boolean = false;
  public vsHide: boolean = false;
  public responses: number = 0;
  public sentimentIndex: number = null;
  public textIndex: number = null;
  public timeIndex: number = null;
  public availableHeight: number = 0;
  public chartBarHeight: number = null;
  public hoveredElement: any = null;
  public languages: any[] = [];
  public langFilter: string = '';

  private dataService: Crossfilter | null = this.cf;
  private filter: FilterData[];
  private selections: any = {};

  @ViewChildren('labelContainer') labelContainers!: QueryList<ElementRef>;
  @ViewChildren('labelSpan') labelSpans!: QueryList<ElementRef>;
  @ViewChild(CdkVirtualScrollViewport) vsViewport: CdkVirtualScrollViewport;

  get Math() {
    return Math;
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    private cf: Crossfilter,
    readonly ns: SnackbarService,
    readonly hooks: LifecycleHooks,
  ) {}

  ngOnInit(): void {
    combineLatest([this.chartData$, this.searchUpdate$, this.languageFilter$, this.sortKey$, this.sortDirection$])
      .pipe(takeUntil(this.hooks.destroy))
      .subscribe(([chartData, searchTerm, , sortKey, sortDirection]) => {
        const rawArray = chartData.slice();
        const textArray = !this.langFilter
          ? rawArray
          : rawArray.filter((row) => row.find((col) => col.language === this.langFilter));
        const sortIndex = this.domain.findIndex((item) => item.key === sortKey) + 1;
        let filteredList: any[];

        if (!searchTerm) {
          filteredList = textArray;
        } else {
          const filteredResults = textArray.filter((col) =>
            col
              .map((c) => c.text)
              .join()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()),
          );
          filteredList = filteredResults;
        }

        const numberCheck: (val: string | number) => number = (val: string | number) =>
          !isNaN(Number(val)) ? Number(val) : -200;

        const sortedList =
          sortKey === 'survey'
            ? sortDirection === 'desc'
              ? filteredList.reverse()
              : filteredList
            : filteredList.sort((a, b) => {
                if (sortKey.indexOf(':sentiment') >= 0) {
                  if (a[sortIndex]['text'] && b[sortIndex]['text']) {
                    if (numberCheck(a[sortIndex]['text']) > numberCheck(b[sortIndex]['text'])) {
                      return sortDirection === 'asc' ? 1 : -1;
                    }
                    if (numberCheck(a[sortIndex]['text']) < numberCheck(b[sortIndex]['text'])) {
                      return sortDirection === 'asc' ? -1 : 1;
                    }
                  } else {
                    return a[sortIndex]['text'] ? -1 : 1;
                  }
                } else {
                  if (a[sortIndex]['text'] > b[sortIndex]['text']) {
                    return sortDirection === 'asc' ? 1 : -1;
                  }
                  if (a[sortIndex]['text'] < b[sortIndex]['text']) {
                    return sortDirection === 'asc' ? -1 : 1;
                  }
                }

                return 0;
              });

        if (sortedList.length === 0) {
          this.vsHide = true;
        } else {
          this.vsHide = false;
        }

        this.chartTable$.next(sortedList);
        this.cdRef.markForCheck();
        this.cdRef.detectChanges();

        if (this.vsViewport) {
          this.vsViewport.scrollTo({ top: 0 });
          this.cdRef.markForCheck();
          this.cdRef.detectChanges();

          this.vsViewport.setRenderedRange({ start: 0, end: this.vsViewport.getRenderedRange().end + 4 });
          this.cdRef.markForCheck();
          this.cdRef.detectChanges();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!(Object.keys(changes).length === 1 && changes.trendHoverInput && !this.trend)) {
      this.sentimentIndex = this.details.findIndex((item) => item.originalTypeSpecifier === 'text-sentiment');
      this.textIndex = this.details.findIndex((item) => item.scale === 'text');
      this.timeIndex = this.details.findIndex((item) => item.scale === 'time');

      this.updateFilters();

      const languages = [];
      if (
        this.details[this.textIndex] &&
        this.details[this.textIndex]['valueGroupKeys'] &&
        this.details[this.textIndex]['valueGroupKeys'].length
      ) {
        for (let i = 0, len = this.details[this.textIndex]['valueGroupKeys'].length; i < len; i++) {
          if (this.details[this.textIndex]['valueGroupTypes'][i] === 'language') {
            const key = this.details[this.textIndex]['valueGroupKeys'][i];
            languages.push(key);
          }
        }
      }
      this.languages = languages;

      if (this.languages.indexOf(this.langFilter) < 0) {
        this.langFilter = '';
      }

      if (changes.size) {
        const roughUpdateNeeded: boolean = !changes.size.isFirstChange();

        if (this.size) {
          this.availableHeight = parseFloat(this.size);

          this.sizeUpdate = new Date();
          if (roughUpdateNeeded) {
            this.forceVSUpdate();
          }
        }
      }

      if (changes.chartSettings && changes.chartSettings.firstChange) {
        this.sortKey$.next(this.chartSettings.sortKey || 'survey');
        this.sortDirection$.next(this.chartSettings.sortDirection || 'asc');
        this.chartMode = this.chartSettings.sentimentChartMode || 'gauge';
      }

      this.responses =
        this.stats && this.stats[this.sentimentIndex] && this.stats[this.sentimentIndex]['responses'] != null
          ? this.stats[this.sentimentIndex]['responses']
          : this.totalAnswers;

      if (
        this.isNotFreezed() &&
        !this.trend &&
        this.comparison &&
        this.comparison.values &&
        this.comparison.values.length
      ) {
        this.setComparisonData();
        this.chartBarHeight = Math.max(
          (0.475 * this.availableHeight - (40 + 34 + 32)) / this.comparison.values.length,
          40,
        );
      }

      this.setData();
    }
  }

  private setData(): void {
    if (this.crossfilter) {
      this.dataService = this.crossfilter;
    } else {
      this.dataService = this.cf;
    }

    this.chartData$.next(
      this.details
        ? this.dataService.getTextAnswersFor(
            this.details,
            this.isSharedReport,
            false,
            true,
            !!(this.anonymityTreshold && (this.comparison || this.dataService.getTrendAnalysisStatus())),
          )
        : [],
    );
  }

  private setComparisonData(): void {
    const domain = this.domain[this.comparison.index];
    const sentimentDomIndex = this.domain[this.sentimentIndex].index;
    const allStats = this.stats[this.comparison.index];
    const allDistributions = this.distributions[this.comparison.index];
    const table = [];

    for (let i = 0, len = domain.keys.length; i < len; i++) {
      const key = domain.keys[i];
      const label = domain.labels[key];
      const color = domain.colors[key] != null ? [Colors.getComparisonColor(domain.colors[key])] : [];
      const statsBase = allStats && allStats.children && allStats.children.find((child) => child && child.key === key);
      const stats = statsBase && statsBase['children'] && statsBase['children'][sentimentDomIndex];
      const count = stats && stats.count;
      const average = stats && stats.average;
      const distributionsBase = allDistributions && allDistributions.find((child) => child && child.key === key);
      const distributions = distributionsBase && distributionsBase['children'];

      table.push({ key, color, label, count, average, stats, distributions });
    }

    this.comparisonTable$.next(table);
  }

  private forceVSUpdate(): void {
    this.vsHide = true;
    this.cdRef.detectChanges();
    setTimeout(() => {
      this.vsHide = false;
      this.cdRef.detectChanges();
    }, 0);
  }

  trackByTextItem(i: number, item: any): string {
    return item.text;
  }

  trackByKey(i: number, item: any): string {
    return item.key;
  }

  trackByRow(i: number, row: any[]): number {
    return (row && row[0] && row[0]['value']) || null;
  }

  private updateFilters() {
    for (let i = 0, len = this.filterInput.length; i < len; i++) {
      const filters = this.filterInput[i];
      const key = this.details[i].key;
      this.selections[key] = new Set();
      if (filters && filters.length > 0) {
        for (const filter of filters) {
          this.selections[key].add(filter);
        }
      }
    }
  }

  public filterData(value, i): void {
    if (value && !(this.dataService.getTextFreezingStatus() && this.isSharedReport)) {
      const dimKey = this.details[i].key;
      const scale = this.details[i].scale;
      let values: any[] = [];
      let filterValue: number[] = [];

      if (scale === 'text') {
        values = Array.from(Array(this.dataService.getTextAnswers()[dimKey].length).keys());
        filterValue = value[1];
      } else if (scale === 'contact-text') {
        values = Array.from(Array(this.dataService.getTextContacts()[dimKey].length).keys());
        filterValue = value[1];
      } else if (scale === 'linear') {
        values = this.details[i].values;
        filterValue = value[0];
      } else if (this.details[i].values && this.details[i].values.length > 0) {
        values = this.details[i].values;
        filterValue = value[0].map((item) => this.details[i]['values'][item]);
      }

      if (!this.selections[dimKey]) {
        this.selections[dimKey] = new Set();
      }

      for (const val of filterValue) {
        if (this.selections[dimKey].has(val)) {
          this.selections[dimKey].delete(val);
        } else {
          this.selections[dimKey].add(val);
        }
      }

      const newFilter: FilterData = {
        key: dimKey,
        values,
        filter: Array.from(this.selections[dimKey]),
        textFilter: this.details[i].scale === 'text' || this.details[i].scale === 'contact-text' ? true : false,
      };

      if (this.filtering && !this.anonymityTreshold) {
        this.filter = [];

        this.filter.push(newFilter);
        if (JSON.stringify(this.filter[0].filter) !== JSON.stringify(this.filterInput)) {
          this.dataService.filter(this.filter);
        }
      }
    }
  }

  public getSentimentColor(textItem): string {
    return textItem.text
      ? Number(textItem.text) === 0
        ? Colors.SENTIMENT[1]
        : Number(textItem.text) > 0
          ? Colors.SENTIMENT[2]
          : Number(textItem.text) < 0
            ? Colors.SENTIMENT[0]
            : 'transparent'
      : 'transparent';
  }

  public onSortColumn(column: string): void {
    if (column.indexOf(':sentiment') >= 0) {
      this.sortDirection$.next('desc');
    } else {
      this.sortDirection$.next('asc');
    }

    this.sortKey$.next(column);
    this.settingsChanged();
  }

  public onSortDir(): void {
    this.sortDirection$.next(this.sortDirection$.value === 'asc' ? 'desc' : 'asc');
    this.settingsChanged();
  }

  public changeChartMode(mode: string): void {
    this.chartMode = mode;
    this.cdRef.markForCheck();
    this.cdRef.detectChanges();
    this.settingsChanged();
  }

  public settingsChanged(): void {
    this.settingsChange.emit({
      sortKey: this.sortKey$.value,
      sortDirection: this.sortDirection$.value,
      sentimentChartMode: this.chartMode,
    });
  }

  public onHover(element: any | null): void {
    if (
      (element && element.key !== (this.hoveredElement && this.hoveredElement.key)) ||
      (!element && this.hoveredElement)
    ) {
      this.hoveredElement = element;
      this.cdRef.detectChanges();
    }
  }

  public showMoreButton(container, span): boolean {
    const containerHeight = container && container.offsetHeight;
    const spanHeight = span && span.scrollHeight;

    return spanHeight - containerHeight > 0;
  }

  public showLessButton(container, span): boolean {
    const spanSizes = Array.from(this.labelSpans || []).map((elem) => elem && elem.nativeElement.scrollHeight);
    const spanHeight = span && span.scrollHeight;
    const containerHeight = container && container.offsetHeight;

    return spanHeight === Math.max(...spanSizes) && containerHeight - spanHeight < 5;
  }

  public expandLabels(): void {
    const chartsAreaHeight = 0.475 * this.availableHeight - (40 + 34 + 32);
    const spanSizes = Array.from(this.labelSpans || []).map((elem) => elem && elem.nativeElement.scrollHeight);
    const containerSizes = Array.from(this.labelContainers || []).map(
      (elem) => elem && elem.nativeElement.offsetHeight,
    );
    const maxS = Math.max(...spanSizes);
    const maxC = Math.max(...containerSizes);

    this.chartBarHeight = Math.max(
      maxS - maxC > 0 ? maxS : null,
      chartsAreaHeight / (this.comparisonTable$.value.length || 1),
      40,
    );
    this.expandedLabels = true;
    this.sizeUpdate = new Date();
    this.cdRef.detectChanges();
  }

  public shrinkLabels(): void {
    const chartsAreaHeight = 0.475 * this.availableHeight - (40 + 34 + 32);
    this.chartBarHeight = Math.max(chartsAreaHeight / (this.comparisonTable$.value.length || 1), 40);
    setTimeout(() => {
      this.expandedLabels = false;
      this.sizeUpdate = new Date();
      this.cdRef.detectChanges();
    }, 0);
  }

  public isNotFreezed(): boolean {
    return !(this.dataService.getTextFreezingStatus() && this.isSharedReport) && !(this.anonymityTreshold > 1);
  }

  public isUnderAnonymityTreshold(): boolean {
    return this.anonymityTreshold && this.chartData$?.value?.length < this.anonymityTreshold;
  }

  public filterByLanguage(lang: string) {
    this.langFilter = lang;
    this.languageFilter$.next(lang);
  }
}
