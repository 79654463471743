import { ReplaySubject } from 'rxjs';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import {
  AnonymityStatus,
  ChartContent,
  ChartDomain,
  ChartSettings,
  DimensionDataItem,
} from '@shared/models/report.model';
import { Charts } from '@shared/enums/charts.enum';

import { Crossfilter } from '@report/shared/services/crossfilter.service';

/**
 * This is a Smart Text Chart chart.
 */
@Component({
  selector: 'smart-text-chart',
  templateUrl: './smart-text-chart.component.html',
  styleUrls: ['./smart-text-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartTextChart implements OnInit, OnChanges, OnDestroy {
  @Input() content: ChartContent | null = null;
  @Input() settings: ChartSettings = {} as ChartSettings;
  @Input() comparison: any;
  @Input() trend: any;
  @Input() filtering: boolean = true;
  @Input() crossfilter: Crossfilter | null = null;
  @Input() size: string = '0px';
  @Input() update: Date = new Date();

  @Input() anonymityTreshold: number = null;
  @Input() anonymityStatus: AnonymityStatus = { atRisk: false, hide: false, trend: false } as AnonymityStatus;
  @Input() filtersDemo: boolean = false;
  @Input() isSharedReport: boolean = false;
  @Input() selectionExists: boolean = false;
  @Input() touchDevice: boolean = false;
  @Input() transition: number = 0;
  @Input() trendHoverInput: string = '';

  @Output() settingsChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() trendHoverOutput: EventEmitter<string> = new EventEmitter<string>();

  readonly charts = Charts;

  public details: DimensionDataItem[] = [];
  public domain: ChartDomain[] = [];
  public filters: any[] = [];

  public chartMode: string = Charts.TEXTANSWERTABLE;

  public hasEmotions: boolean = false;
  public hasLangTech: boolean = false;

  private onChanges = new ReplaySubject<SimpleChanges>(1);

  constructor() {}

  ngOnInit(): void {
    if (this.settings?.smartTextChartMode) {
      this.chartMode = this.settings.smartTextChartMode;
    }

    this.onChanges.subscribe((changes: SimpleChanges) => {
      if (changes?.size) {
        this.size = parseFloat(this.size) - 50 + 'px';
      }
      if (changes?.crossfilter || changes?.comparison || changes?.trend) {
        this.setData();
      }
    });
  }

  private setData(): void {
    const dimensionSelector = (d, i) =>
      this.content.trend?.index === i ||
      this.content.comparison?.index === i ||
      (this.chartMode === Charts.TEXTANSWERTABLE
        ? this.content?.details?.[i].scale === 'text'
        : this.chartMode === Charts.WORDANALYSIS
          ? this.content?.details?.[i].originalTypeSpecifier === 'text-words'
          : this.chartMode === Charts.TEXTSENTIMENTANALYSIS
            ? this.content?.details?.[i].originalTypeSpecifier === 'text-sentiment' ||
              this.content?.details?.[i].scale === 'text'
            : this.chartMode === Charts.TEXTEMOTIONS
              ? this.content?.details?.[i].originalTypeSpecifier === 'text-emotions' ||
                this.content?.details?.[i].scale === 'text'
              : false);
    this.details = this.content?.details?.filter(dimensionSelector);
    this.domain = this.content?.domain?.filter(dimensionSelector);
    this.filters = this.content?.filters?.filter(dimensionSelector);

    this.hasEmotions = this.content?.details.some((d) => d.originalTypeSpecifier === 'text-emotions');
    this.hasLangTech = this.content?.details.some(
      (d) => d.originalTypeSpecifier === 'text-words' || d.originalTypeSpecifier === 'text-sentiment',
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.onChanges.next(changes);
  }

  ngOnDestroy() {
    this.onChanges.complete();
  }

  public changeChartMode(chartMode: string): void {
    this.chartMode = chartMode;
    this.setData();

    this.settingsChange.emit({
      smartTextChartMode: chartMode,
    });
  }
}
